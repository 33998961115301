import React, { useState ,useEffect} from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import { TENMINUTESINMILISECONDS, TENMINUTESINSECONDS } from '../../config/env';
import { CheckLoginOtp, UseloginUser } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import axios from "axios";
import {Buffer} from 'buffer';
   


const Otp = (props) =>{
   const[otp, setOtp] = useState(true)
   const [box1,setbox1] = useState("")
   const [box2,setbox2] = useState("")
   const [box3,setbox3] = useState("")
   const [box4,setbox4] = useState("")
   const [box5,setbox5] = useState("")
   const [box6,setbox6] = useState("")
   const [username,setusername] = useState(sessionStorage.getItem("username"))
   const [Enablebtn, setEnablebtn] = useState(false)
   const [counter, setCounter] = useState(TENMINUTESINSECONDS)
   const [Seconds, setseconds] = useState(0)
   const [Minutes, setminutes] = useState(0)
   const [ip, setIP] = useState('');
   const hidelogin = () => {
      document.getElementById("login").style.visibility = "hidden"
    }
  let tabChange = (val) => {
    let ele = document.querySelectorAll('input.otp');
    if (val == 4) {
      ele[val - 1].focus()
    } else {
      if (3 >= val === true) {
        if (ele[val - 1].value !== '') {
          ele[val].focus()
        } else if (ele[val - 1].value === '') {
          ele[val - 1].focus()
        }
      }
    }
  }
     useEffect(() => {
      GetIpAddress()
      if( counter == 0){
       
          setEnablebtn(true);
       
      }
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
     
      const seconds = String(counter % 60).padStart(2, 0);
      setseconds(seconds)
      const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
      setminutes(minutes)
      
      return () => 
      
           clearInterval(timer);
          

     
  }, [counter]);

     const GetIpAddress = async()=>{
      const res = await axios.get('https://geolocation-db.com/json/')
      
       setIP(res?.data?.IPv4)
  }
     const onOtpSubmit = async (e) => {
      

      const Loginhistory ={
       
        loginTime:Buffer.from(String(Date.now())).toString('base64') ,
        Browser:Buffer.from(window.navigator.userAgent).toString('base64'),
        ipaddress: Buffer.from(ip).toString('base64')

     }
     
      e.preventDefault();
    
      const UserOtpData = {
        // headers : {authorization: `Bearer ${accessToken}`},

        user_name: username,
        Otp: box1 + box2 + box3 + box4,
        Date:Date.now(),
        LoginHistory:Loginhistory
          
      };
     
      await CheckLoginOtp(UserOtpData)

  };
  const setFunction = (data)=> {
    console.log("setFunction_setFunction",data);
    setbox1(data[0]);setbox2(data[1]);setbox3(data[2]);setbox4(data[3]);
    tabChange(4)
  }
  const resendOtp = async () => {
    setCounter(60);
    setEnablebtn(false)
    const payload = {
      user_name: props.loginData.user_name,
      password: props.loginData.password,
      CreatedAt: Date.now() + TENMINUTESINMILISECONDS
    }
    const resend = await UseloginUser(payload)
  }
   return(
//     <div className='modal_back_image'>
// <Modal size="md" show={otp} centered scrollable  onHide={() => {props.onDismiss()}} className='modal_style thememodal loginmodal'>
<div className='modal_back_image'>
<Modal size="md" show={otp}  centered scrollable className='modal_style thememodal  '>
      <Modal.Header className='border-0 pb-0'>
      {/* <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button> */}

      </Modal.Header>
      <Modal.Body>
       
      <div className='mb-4'>
        <div className=''>
           <h3 className='headfont mb-4 text-center'>Login OTP</h3>
            
        </div>
        <div className='wizardinput w-full mb-3'>
        <p className='text-center'><label className='mt-0'>Enter Otp</label></p>
        </div>
        <form action="" className=" mb-4 confirm justify-content-center">
              <input className="otp" type="text"  value={box1} onChange={(e) => { e.target.value.length >= 4 ? setFunction(e.target.value) :  setbox1(e.target.value[0]) }} onKeyUp={() => tabChange(1)} maxlength={box1 ? "1": "4"} />
              <input className="otp" type="text"  value={box2} onChange={(e) => { e.target.value.length >= 4 ? setFunction(e.target.value) :  setbox2(e.target.value[0]) }} onKeyUp={() => tabChange(2)} maxlength={box2 ? "1": "4"} />
              <input className="otp" type="text"  value={box3} onChange={(e) => { e.target.value.length >= 4 ? setFunction(e.target.value) :  setbox3(e.target.value[0]) }} onKeyUp={() => tabChange(3)} maxlength={box3 ? "1": "4"} />
              <input className="otp" type="text"  value={box4} onChange={(e) => { e.target.value.length >= 4 ? setFunction(e.target.value) :  setbox4(e.target.value[0]) }} onKeyUp={() => tabChange(4)} maxlength={box4 ? "1": "4"} />
              
         </form>
         <div className="text-center">
      
          
            {Enablebtn && <button className="btn btn-theme me-2 btn_big_theme_border" onClick={()=> {resendOtp()}}>Resend</button>}
            {!Enablebtn && <button className="btn btn-theme btn_big_theme_border" onClick={onOtpSubmit}>Confirm</button>}
           </div>
           <div className=" mt-3 text-center">
            <p className="pr-4"> Otp will expires in <b>  <span>{Minutes}:{Seconds}</span></b></p></div> 
           
                
      </div>
      </Modal.Body>

    </Modal>
  </div>
   )
}

export default Otp
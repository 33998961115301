import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import prof from '../../Assets/Images/prof1.jpg'
import { TENMINUTESINMILISECONDS } from '../../config/env';
import { UseloginUser } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { sleep } from '../../Hooks/UseBackendAction/UseGetController';
import OtpModal from './OtpModal';
import TwoFAuth from './TwoFAuth';
 
const Login = (props) =>{
    const[login, setLogin] = useState(true)
    const [LoginUsername, setLoginUsername] = useState()
    const [LoginPassword, setLoginPassword] = useState()
    const[otp, setOtp] = useState(false)
    const [twofact,settwofact] = useState(false)
    const [passwordneweye, setpasswordneweye] = useState(false)
    const [isAccept, setisAccept] = useState(false)
    const label = <>I accept and agree to the <a target='_blank' href="https://docs.neonomad.finance/nni/tokenomics-fundamentals/neonomad-terms-of-use" className='aqua me-2'>Terms and Conditions</a></>

    const showNewPassword = (e) => {

      var e = document.getElementById("Loginpassword");
      if (e.type === "password") {
          setpasswordneweye(true)
          e.type = "text";
      } else {
          setpasswordneweye(false)
          e.type = "password";
      }
  };

    const userLogin = async(data) =>{

      await UseloginUser(data)
      await sleep(1000)
      if ((sessionStorage.getItem("username") != undefined) &&(sessionStorage.getItem("isauth") === "false" ) ) {
          document.getElementById("loginmodel").style.display = "none";
          setOtp(true)
      
    }else if ((sessionStorage.getItem("username") != undefined) && (sessionStorage.getItem("isauth") === "true" )){
        document.getElementById("loginmodel").style.display = "none";
        settwofact(true)

    }

  }

  const onchangeCheck=()=>{
    if(isAccept==false){
        setisAccept(true)
    }
    else{
      setisAccept(false)
    }
}

    const onLoginSubmit = async (e) => {

      e.preventDefault();
      const UserLoginData = {
          user_name:LoginUsername,
          password: LoginPassword,
          CreatedAt: Date.now() + TENMINUTESINMILISECONDS

      };
      await userLogin(UserLoginData)
       
      
  };
   return(
    <div className='modal_back_image'>
    <Modal id="loginmodel" show={login}  centered scrollable className='modal_style thememodal modal_big'>
    {otp && <OtpModal onDismiss={() => setOtp(false)} loginData={{user_name:LoginUsername, password: LoginPassword}}/>}
    {twofact && <TwoFAuth onDismiss={() => settwofact(false)}/>}
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={onLoginSubmit} className='mb-4 logins'>
        <h3 className='headfont mb-4 text-center'>Login</h3>
        <div className='row'>
          <div className='col-12 col-md-6'>
          <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Username</label>
                    <input placeholder='Enter Username' 
                    required
                    value={LoginUsername}
                    onChange={(e) => { setLoginUsername(e.target.value) }}
                    className='form-control'/>
            </div>
          </div>
          <div className='col-12 col-md-6'>
          <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Password</label>
                    <InputGroup className="mb-0">
        <Form.Control
        placeholder='Password' 
        required
        type="password"
        value={LoginPassword}
        id="Loginpassword"
        onChange={(e) => { setLoginPassword(e.target.value) }} className='form-control inpit_without_bor'/>
          {!passwordneweye ?
        <InputGroup.Text id="basic-addon2"><i className='fa fa-eye-slash' onClick={showNewPassword}></i></InputGroup.Text>:
        <InputGroup.Text id="basic-addon2"><i className='fa fa-eye' onClick={showNewPassword}></i></InputGroup.Text>}
      </InputGroup>
      <div className='text-right'>
      <Button variant='link'className='aqua ps-0' onClick={() =>{props.onDismiss() ; props.forget()}}>Forgot Password</Button>

      </div>
                   
            </div>
            </div>
        </div>
        
        <Form.Check className='mb-3'
            type="checkbox"
            onChange={()=>onchangeCheck()}
            label={label}
          />
           
        
        <div className='w-full mb-3'>
          {/* <button className='btn btn-theme w-full'>Sign in</button> */}
          {isAccept  && isAccept?
          <button className='btn btn-theme w-full'>Sign in</button> : 
          <button disabled className='btn btn-theme w-full'>Sign in</button>}
        </div>
        <div className='d-flex jc-center'>
            <Button variant='link' className='aqua ps-0'  onClick={() => {props.onDismiss() ; props.showreg(); }}>Create Account</Button>
      {/* <a variant='link'  className='aqua ps-0'  onClick={() => {props.onDismiss() ; props.showreg(); }}>Create Account</a> */}

                  
        </div>  


       
        
      </form>
    </Modal.Body>

</Modal>
  </div>
   )
}

export default Login
import BigNumber from "bignumber.js";
import { ZEROTH_ADDRESS } from "../../config/env";
import { v3Factory_abi, v3Factory_address, v3Pool_abi ,v3Factory_addresseth} from "../../config/factoryContract";
import { v3Router_abi, v3Router_address ,v3Router_Ethaddress} from "../../config/routerContract";
import { UseTokenInfo, UseTokenInfoEth } from "./useContract";
import { UseERC20, UseERC20Eth, UseWeb3, UseWeb3Eth } from "./useWeb";
const { toSignificant } = require('@thanpolas/crypto-utils');

// BSC -- Configuration


export const getLiquidity = async( _token0,_token1) => {
 
    try {
      var token0 = _token0
      var token1 = _token1
      const web3 = await UseWeb3();
      const factoryAddress = v3Factory_address;
      const factory = new web3.eth.Contract(v3Factory_abi,factoryAddress);
      var pair = ZEROTH_ADDRESS;
      var tiers = [ 0.01 * 10000 , 0.05 * 10000, 0.25  * 10000, 1 * 10000 ]
      var fee = 0;
      for(var i=0; pair == ZEROTH_ADDRESS; i++ ){
        pair = await factory.methods.getPool(token0, token1, tiers[i]).call();
        fee = tiers[i];
        
      }
      
      var balance0 = await (await UseERC20(token0)).methods.balanceOf(pair).call();
      var balance1 = await (await UseERC20(token1)).methods.balanceOf(pair).call();
   
      const pool = new web3.eth.Contract(v3Pool_abi, pair);
      const slot0 = await pool.methods.slot0().call();
      const price = slot0?.sqrtPriceX96 ** 2 / 2 ** 192;
      var token0info = await UseTokenInfo(token0);
      var token0infodecimal = parseInt(token0info.decimals);
      var token1info = await UseTokenInfo(token1);
      var token1infodecimal = parseInt(token1info.decimals);
      
      const liquidity = await pool.methods.liquidity().call() / 10 ** parseInt(token1infodecimal);
      return {balance0: balance0 / 10 ** token0infodecimal, balance1: balance1 / 10 ** token1infodecimal,liquidity: liquidity,price: price,fee : fee}

  } catch (error) {
    console.log(error, "this is the error for getPrice")
  }
}

export const getAmountsIn = async(_token0,_token1,amount) =>{


  try {
    const web3 = await UseWeb3();
    var token0 = _token0;
    var token1 = _token1;
    let getAmountsOut = 0;
    const factoryAddress = v3Factory_address;
    const factory = new web3.eth.Contract(v3Factory_abi,factoryAddress);
    var pair = ZEROTH_ADDRESS;
    var tiers = [ 0.01 * 10000 , 0.05 * 10000, 0.25  * 10000, 1 * 10000 ]
    for(var i=0; pair == ZEROTH_ADDRESS; i++ ){
      pair = await factory.methods.getPool(token0, token1, tiers[i]).call();
    }
    const pool = new web3.eth.Contract(v3Pool_abi, pair);
    var Token0 = await pool.methods.token0().call()
    var Token1 = await pool.methods.token1().call()
    const slot0 = await pool.methods.slot0().call();

    var token0info = await UseTokenInfo(_token0);
    var token0infodecimal = parseInt(token0info.decimals)
    var token1info = await UseTokenInfo(token1);
    var token1infodecimal = parseInt(token1info.decimals);
    const price = slot0?.sqrtPriceX96 ** 2 / 2 ** 192;
    const equivalentToken = await  GetequivalentToken(token0infodecimal,token1infodecimal,price);

    if(Token0 === token0){
      getAmountsOut =  amount * equivalentToken;
    }else{
      getAmountsOut =  amount / equivalentToken;
    }
    // console.log("getAmountsOut>>",(new BigNumber(getAmountsOut)).toSignificant(6),Token0,Token1);
  return {getAmountsOut: getAmountsOut}
} catch (error) {
  console.log(error, "this is the error for getPrice")
}
}



// ETH -- Configuration

export const getLiquidityEth = async( _token0,_token1) => {
  
 
  try {
    var token0 = _token0
    var token1 = _token1
    const web3 = await UseWeb3Eth();
    const factoryAddress = v3Factory_addresseth;
    const factory = new web3.eth.Contract(v3Factory_abi,factoryAddress);
   
    var pair = ZEROTH_ADDRESS;
    var tiers = [ 0.01 * 10000 , 0.05 * 10000, 0.25  * 10000, 1 * 10000 ]
    var fee = 0;
    for(var i=0; pair == ZEROTH_ADDRESS; i++ ){
      pair = await factory.methods.getPool(token0, token1, tiers[i]).call();
      fee = tiers[i];
      
    }
    var balance0 = await (await UseERC20Eth(token0)).methods.balanceOf(pair).call();
    var balance1 = await (await UseERC20Eth(token1)).methods.balanceOf(pair).call();
 
    const pool = new web3.eth.Contract(v3Pool_abi, pair);
    const slot0 = await pool.methods.slot0().call();
    const price = slot0?.sqrtPriceX96 ** 2 / 2 ** 192;
    var token0info = await UseTokenInfoEth(_token0);
    var token0infodecimal = parseInt(token0info.decimals)
    var token1info = await UseTokenInfoEth(token1);
    var token1infodecimal = parseInt(token1info.decimals);
    
    const liquidity = await pool.methods.liquidity().call() / 10 ** parseInt(token1infodecimal);
   
  return {balance0: balance0 / 10 ** token0infodecimal, balance1: balance1 / 10 ** token1infodecimal,liquidity: liquidity,price: price,fee : fee}
} catch (error) {
  console.log(error, "this is the error for getPrice")
}
}

export const getAmountsInEth = async(_token0,_token1,amount) =>{


  try {
    var token0 = _token0;
    var token1 = _token1;
    let getAmountsOut = 0;
    const web3 = await UseWeb3Eth();
    const factoryAddressEth = v3Factory_addresseth;
    const factory = new web3.eth.Contract(v3Factory_abi,factoryAddressEth);
    var pair = ZEROTH_ADDRESS;
    var tiers = [ 0.01 * 10000 , 0.05 * 10000, 0.25  * 10000, 1 * 10000 ]
    for(var i=0; pair == ZEROTH_ADDRESS; i++ ){
      pair = await factory.methods.getPool(token0, token1, tiers[i]).call();
    }
    const pool = new web3.eth.Contract(v3Pool_abi, pair);
   
    var Token0 = await pool.methods.token0().call()
    var Token1 = await pool.methods.token1().call()
    const slot0 = await pool.methods.slot0().call();

    var token0info = await UseTokenInfoEth(_token0);
    var token0infodecimal = parseInt(token0info.decimals)
    var token1info = await UseTokenInfoEth(token1);
    var token1infodecimal = parseInt(token1info.decimals);
    const price = slot0?.sqrtPriceX96 ** 2 / 2 ** 192;

   const equivalentToken = await  GetequivalentToken(token0infodecimal,token1infodecimal,price);
 
    if(Token0 === token0){
       getAmountsOut =  amount * equivalentToken;
     

    }else{
      getAmountsOut = amount / equivalentToken;
    }
    
  return {getAmountsOut: getAmountsOut}
} catch (error) {
  console.log(error, "this is the error for getPrice")
}
}


export const GetequivalentToken = async(_tokenIn,_tokenOut,_amount) => {
  
    return _tokenIn > _tokenOut ? _amount * (10** (_tokenIn - _tokenOut)) : _amount / (10**(_tokenIn - _tokenOut)) ;

}




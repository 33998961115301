import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Accordion, Tab, Row, Col, Nav } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import Header from './Header';
import Sidebar from './Sidebar';
import check from '../Assets/Images/check.png'
import prof from '../Assets/Images/dprof.png'
import coin1 from '../Assets/Images/coin1.png'
import WithdrawModal from './Modals/WithdrawModal';
import EditProfile from './Modals/EditProfile';
import Verification from './Modals/Verification';
import FiatWithdrawModal from './Modals/FiatWithdraw';
import AccountDetails from './Modals/AccountDetails';
import { UsergetInfobyname, UsetokeninfoBySymbol } from '../Hooks/UseBackendAction/UseGetController';
import { backurl } from "../config/env";
import { UseisAuth } from '../Hooks/UseBackendAction/UseCreateUser.js';
import { searchedBalanceValue } from '../Hooks/UseValidation';
import ProfileHint from './Modals/ProfileHint';
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast'

import aim from '../Assets/Images/Landing-page/aim.png'
import loaderimg from './../Assets/Images/loaderlogo.png'
import fiat from './../Assets/Images/fiat.png'



import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


// const options= {
//   responsive:{
//     0: {
//         items: 1,
//     },
//     450: {
//         items: 1,
//     },
//     600: {
//         items: 1,
//     },
//     1000: {
//         items: 1,
//     },
// },
// }

const optionsnew = {
  autoplay:true,
  autoplaySpeed:3000,
  autoplayHoverPause:true,
   loop:true, 
   margin:10,
   dots:false
}

const Withdraw = () => {
  const [withdraw, setWithdraw] = useState(false)
  const [profile, setProfile] = useState(false)
  const [verify, setVerify] = useState(false)
  const [fiatwithdraw, setFiatWithdraw] = useState(false)
  const [accountdetails, setAccountDetails] = useState(false)
  const [UserInfo, setUserInfo] = useState({})
  const [Userbalance, setUserbalance] = useState({})
  const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
  const [coindata, setcoindata] = useState([])
  const [fiatcoindata, setfiatcoindata] = useState([])
  const [fiatDepositdata, setfiatDepositdata] = useState([])
  const [userWalletAddress, setuserWalletAddress] = useState("")
  const [isauth, setisauth] = useState(null);
  const [isSearch, setisSearch] = useState(false)
  const [isfiatSearch, setisfiatSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData] = useState([])
  const [FiatSearchCoinData, SetFiatSearchCoinData] = useState([])
  const [Search, setSearch] = useState("")
  const [selectToken, setselectToken] = useState("")
  const [selectnetwork, setselectnetwork] = useState("")
  const [authcode, setauthcode] = useState("")
  const [key, setKey] = useState("")
  const [profilehint, setProfilehint] = useState(true)
  const [groupedToken,setgroup]=useState([])





  const navgate = useNavigate();

  const handledata = (datas) => {
    navgate("/deposit", { state: "deposit" })
  }


  const copyText = (a, b) => {
    toast.success("your private code is copied", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    })
}




  const handleChange = async (e) => {

    if ((e.target.value).length > 0) {
      const searchData = await searchedBalanceValue(coindata, e.target.value);


      SetSearchCoinData(searchData)

      setisSearch(true)
    } else {
      setisSearch(false)
    }

  }


  const FiathandleChange = async (e) => {

    if ((e.target.value).length > 0) {
      const searchData = await searchedBalanceValue(fiatcoindata, e.target.value);


      SetFiatSearchCoinData(searchData)

      setisfiatSearch(true)
    } else {
      setisfiatSearch(false)
    }

  }
  useEffect(() => {

  document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

    if (localStorage.getItem("profilepage") == 1) {
      setProfilehint(false)
    }

    Getdata();
   
    getUserBalance();
  }, [UserName]);

  const Getdata = async () => {

    var UserInfo = await UsergetInfobyname(UserName);
   
    setUserInfo(UserInfo.data)
    setUserbalance(UserInfo?.data?.Holding)
    setuserWalletAddress(UserInfo.data?.walletaddress)
    setisauth(UserInfo?.data?.isAuth)

    var key = UserInfo?.data?.authcode.replace(/\W/g, '').toLowerCase();
    setKey(key)
    var link = "otpauth://totp/{{NAME}}?secret={{KEY}}";
    var name = "ZARC"
    var codeToQr = link.replace(/{{NAME}}/g, name).replace(/{{KEY}}/g, key)

    setauthcode(codeToQr)
  }

  const getUserBalance = async () => {
    var UserInfo = await UsergetInfobyname(UserName);
    setfiatDepositdata(UserInfo?.data?.DepositProof)
    var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
      var dat = await UsetokeninfoBySymbol(item.symbol)

      dat['data']['Balance'] = item

      return dat['data']

    }))

    if (tokenSymbol.length > 0) {
      setcoindata(tokenSymbol)
      const groupByCategory = tokenSymbol.reduce((group, val) => {
        const { name } = val;
        group[name] = group[name] ?? [];
        group[name].push(val);
        return group;
      }, {});
      setgroup(groupByCategory)
      
      setfiatcoindata(tokenSymbol)
      document.getElementById("cryptobalance").classList.remove("blur")
      document.getElementById("fiatbalance").classList.remove("blur")
      document.getElementById("card").classList.remove("blur")
    }
  }

  const onchangeCheck = () => {
    if (isauth == true) {
      setisauth(false)
    }
    else {
      setisauth(true)
    }
  }

  const onisAuthSubmit = async () => {

    const isauthdata = { user_name: UserName, isAuth: isauth };
    await UseisAuth(isauthdata)


  };

  return (
    <>


{withdraw && <WithdrawModal selectToken={selectToken} selectNetwork={selectnetwork} onDismiss={() => setWithdraw(false)} />}
      {verify && <Verification onDismiss={() => setVerify(false)} />}
      {fiatwithdraw && <FiatWithdrawModal onDismiss={() => setFiatWithdraw(false)} />}
      {accountdetails && <AccountDetails onDismiss={() => setAccountDetails(false)} />}


      <div className='profile dashboard noshadow'>
        <div className='bggrad'></div>
        <div className='bggradleft'></div>
        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /> </div>
          <div className='content'>
          <div className='container-fluid container-theme p-4 position-relative'>
                          
          <div className='mb-4 deposit_topbtns'>
                                
                                <h2 className='headfont'>Withdraw</h2>

                            </div>
                            <div className='conts just_con_remve'>
                                <div className='row deposittabs'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 '>

                                    <Tab.Container id="left-tabs-example" defaultActiveKey={"crypto"}>
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="tab_stl_new">
            <Nav.Item>
              <Nav.Link eventKey="crypto">
                <div>Crypto</div>
                
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fiat">
                <div>
                Fiat
                </div>
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12} className='mt-5'>
          <Tab.Content>
            <Tab.Pane eventKey="crypto">
            <div id="cryptobalance" className='card_cutout h-100 card_ciu_hei'>
                      <div className='d-sm-flex jc-between mb-4  align-items-center'>

                        <div className='notecont py-0'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-6 border_right_note'>
                            <div className='py-4'>
                        <li className='mt-2'> <p className=''>
                            <img src={check} />
                            <span>Important: Deposit funds exclusively from a bank account registered in your name.</span></p></li>
                        <li className='mt-2'> 
                            <p className=''><img src={check} />
                            <span>
                               
                            	Do not deposit the same amount multiple times within a day.</span></p></li>
                                </div>
                        </div> 
                        <div className='col-12 col-md-6'>
                        <div className='py-4'>
                        <li className='ps-md-4 mt-2'><p><img src={check} /><span>	We accept Electronic Fund Transfers (EFTs) only; cash and cheques are not accepted.</span></p></li>
                        <li className='mt-2 ps-md-4'><p><img src={check} /><span>	For deposits and withdrawals, only South African bank accounts are permitted. </span></p></li> 
                     </div>
                        </div>

                    </div>
                   
                </div>


                        {/* <div className='d-sm-flex align-items-center'>
                         
                          <InputGroup className="br-10 input_search_bg_prof">
                            <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span> </InputGroup.Text>

                            <Form.Control placeholder="Find coin" onChange={(e) => { setSearch(e.target.value); handleChange(e) }} className='coininput' />
                          </InputGroup>
                        </div> */}

                      </div>

                      {console.log("groupedToken",groupedToken)}
                      {
                        coindata.length > 0 ?
                      
                      <div className='tablescroll themescroll pe-2'>
                       
                          <Accordion className='accordion_custom_token cryptfiatlist'>
                            {/* { console.log("data",coindata,Object.values(groupedToken)) } */}
                          {groupedToken && Object.values(groupedToken).map((val, index) => {
                            // item.network =='solana'?
                            // console.log(val,'mapped', Object.keys(groupedToken)[index])
                            
                            return (
                              // item.Balance.type == "token" && item.name == "ZARC" || item.name == "USDT" ?
                                <>
                                  <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                      <p>{Object.keys(groupedToken)[index]}</p>
                                      {/* <img src={backurl + `/Images/${item.logoURI}`} />
                                      {item.symbol == "ZARCSol" ? "ZARC(Solana)" : item.symbol == "ZARC" ? "ZARC(BSC)" : item.symbol == "ZARCEth" ? "ZARC(Eth)" : item.symbol}
                                      <span className='ps-3'>{(item.Balance.amount).toFixed(3)}</span> */}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {val.map((item,i)=>{

                                        
                                        return(
                                          <div className='d-sm-flex  align-items-center crypt jc-between mb-3'>

                                          <div className='d-flex align-items-center  gap-3 mb-2'>
                                            <p className='mb-0 f-14 d-flex align-items-center gap-2'><img src={backurl + `/Images/${item.logoURI}`} />
                                              {item.symbol == "ZARCSol" ? "Solana" : item.symbol == "ZARC" ? "BSC" : item.symbol == "ZARCEth" ? "Eth" : item.symbol == "USDT" ? "BSC" : item.symbol}</p>
                                            <p className='mb-0 f-14'>{(item.Balance.amount).toFixed(3)}</p>
                                          </div>
                                          <div className=' mb-2'>
                                            <button className='btn btn-theme-bordered me-2 btrn_hove_gradt' onClick={(data) => handledata(data)}>
                                              <span className="btn-theme-bordered-span span_spacin_btn">Deposit</span>
                                            </button>
                                            <button className='btn btn-theme-bordered btrn_hove_gradt' onClick={() => { setWithdraw(true); setselectToken(item.symbol);setselectnetwork(item.network) }}>
                                              <span className="btn-theme-bordered-span span_spacin_btn">Withdraw</span>
                                            </button>
                                          </div>
                                        </div> 
                                        )
                                      })}
                                      
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </>
                               
                            )
                          })}
                          </Accordion>
                         
                        


                      


                      </div> : <p className='text-center'>You don't have any balance for withdraw your balance</p> }
                    </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fiat">
            <div className='card card_cutout mt-3 card_ciu_hei'>

            <div className='notecont py-0'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-6 border_right_note'>
                            <div className='py-4'>
                        <li className='mt-2'> <p className=''>
                            <img src={check} />
                            <span>Important: Deposit funds exclusively from a bank account registered in your name.</span></p></li>
                        <li className='mt-2'> 
                            <p className=''><img src={check} />
                            <span>
                               
                            	Do not deposit the same amount multiple times within a day.</span></p></li>
                                </div>
                        </div> 
                        <div className='col-12 col-md-6'>
                        <div className='py-4'>
                        <li className='ps-md-4 mt-2'><p><img src={check} /><span>	We accept Electronic Fund Transfers (EFTs) only; cash and cheques are not accepted.</span></p></li>
                        <li className='mt-2 ps-md-4'><p><img src={check} /><span>	For deposits and withdrawals, only South African bank accounts are permitted. </span></p></li> 
                     </div>
                        </div>

                    </div>
                   
                </div>
            <div id="fiatbalance" className='crypt h-100 '>
                     
                      <div className='text-center'>
                        <img src={aim} className='img-fluid imng_full_wdh' />
                      </div>
                      <div className=' mb-2 text-center mt-3'>
                        <button className='btn btn-theme-bordered me-2 btrn_hove_gradt' onClick={() => navgate("/deposit")}>
                          <span className="btn-theme-bordered-span span_spacin_btn">Deposit</span>
                        </button>
                        <button className='btn btn-theme-bordered btrn_hove_gradt' onClick={() => setFiatWithdraw(true)}>
                          <span className="btn-theme-bordered-span span_spacin_btn">Withdraw</span>
                        </button>
                      </div>
                      </div>
    </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>


                                     
                                           
                                    </div>
                                </div>
                            </div>
                        </div>
           


          </div>
        </div>
      </div>
    </>
  )
}

export default Withdraw
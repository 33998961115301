// export const backurl = "http://localhost:8067" // local
//  export const backurl   =    "http://43.204.99.101:8067" // testnet
 export const backurl = "https://api.stablezarc.io" // mainnet
// export const Landingpage = "http://43.204.99.101:8084"// testnet
export const Landingpage = "https://beta-app.neonomad.io"// mainent
// export const Landingpage      =    "http://localhost:3000" // local
export const InnerLandingpage = "https://stablezarc.io"// mainent
export const frontendurl = window.location.origin;
export const TENMINUTESINMILISECONDS = 600000;
export const TENMINUTESINSECONDS = 300;
export const DEADLINE = 600; //epoch for 10 mins
export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000";
export const DEAD_ADDRESS = "0x000000000000000000000000000000000000dEaD";
export const MIN_SUBSCRIPTION_DAY = 2;
// export const CHAIN_ID = 97;
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const CHAIN_ID = 56;
export const  RPC_URL ="https://bsc-dataseed.binance.org/";
// export const  RPC_URL ="https://bsc-dataseed1.binance.org/"; // old
export const EthCHAIN_ID = 1 ;
// export const EthRPC_URL = "https://mainnet.infura.io/v3/" //old
export const EthRPC_URL = "https://mainnet.infura.io/v3/145646452dcd49b89f6059e153fd77f5"
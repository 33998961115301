import React, { useState,useEffect } from 'react';
import {Navbar,Dropdown,Nav,NavDropdown} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import logo from '../Assets/Images/logo.svg'
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from '../Hooks/UseToast'
import ContactUs from './Modals/ContactUsModal';
import prof from '../Assets/Images/prof.png'
import user_prof from '../Assets/Images/user_prof.png'
import logout from '../Assets/Images/logout_icon.png'
import contact_icon from '../Assets/Images/contact_icon.png'


import sz from '../Assets/Images/S-Z.svg'
import bz from '../Assets/Images/B-Z.svg'
import ez from '../Assets/Images/E-Z.svg'
import { logoutUser } from '../Hooks/UseBackendAction/UseCreateUser.js';
import { UsegetLimitNotification, UsergetInfobyname, UsetokeninfoBySymbol, getTokenPrice, UseUpdateLimitNotification } from '../Hooks/UseBackendAction/UseGetController';
import Marquee from "react-fast-marquee";


import * as config from "../config/env";
import axios from 'axios';

const Header = () =>{
  const[contact, setContact] = useState(false)
  const[sidebar, setSidebar] = useState(true)
  const navigate = useNavigate();
  const [username,setusername] = useState(sessionStorage.getItem("username"))
  const [UserProfile,setUserProfile] = useState("")
  const [UserInfo, setUserInfo] = useState({})
  const [Usernotification, setUserUsernotification] = useState([])
  const [coindata, setcoindata] = useState([]);
  const [zarcBSCPrice,setzarcBSCPrice] = useState(0);
  const [zarcSolanaPrice,setzarcSolanaPrice] = useState(0);
  const [active,setActive] = useState(false)
  const showsidebar = () =>{
    setSidebar(!sidebar)
  }

  // useEffect(() => {

  //     console.log("header", !sessionStorage.Token, sessionStorage.Token == null, sessionStorage.Token == undefined, sessionStorage.Token == " ");
  //     if (!sessionStorage.Token || sessionStorage.Token == null || sessionStorage.Token == undefined || sessionStorage.Token == " ") {
  //       toast.error("Session will be expired !", {
  //         position: position.position,
  //         style: style,
  //         iconTheme: iconTheme,
  //       })
  //       navigate("/")
  //     }
  // }, [])

   const onLogoutClick = async() => {
    await logoutUser();
    window.location.href =  config?.Landingpage;
  };

  useEffect(() => {
    
    getUserBalance();
     GetTokenPrice();
  
    }, [active]);


  const getUserBalance = async() =>{
    var UserInfo = await UsergetInfobyname(username);
    if(UserInfo?.success == false){
      toast.error(UserInfo?.data,{
        position:position.position,
        style:style,
        iconTheme: iconTheme,
      });
        await logoutUser();
          navigate("/login")

      // onLogoutClick()
    }
    setUserProfile(UserInfo?.data?.logoURI)
    var usernotification = await UsegetLimitNotification(username);
    if (usernotification?.success == true) {
      setUserUsernotification(usernotification?.data);
      if (usernotification && usernotification?.data?.filter((el) => el.Status == "unseen")?.length > 0) {
    console.log("usernotification", usernotification)
        setActive(true)
      }
    }
    

   
    var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
      var dat = await UsetokeninfoBySymbol(item.symbol)
  
      dat['data']['Balance'] = item
      
      return dat['data']
  
  }))
  
  if(tokenSymbol.length > 0){
    
    setcoindata(tokenSymbol)
   
    
  }
  }
  const updateNotificationStatus  = async () => {
    setActive(false)
    if(Usernotification.length > 0){
      var usernotification = await UseUpdateLimitNotification(username);
      console.log('updateNotificationStatus_fine',usernotification);
    }
  }



  const GetTokenPrice = async () =>{
    var tokenprice = await getTokenPrice();
  
    
    setzarcBSCPrice((1/ parseFloat(tokenprice?.data?.zarcBSC)).toFixed(5));
    setzarcSolanaPrice((1 / parseFloat(tokenprice?.data?.zarcSolana)).toFixed(5))
    
  
  }
  // var userImage =  UserProfile == null ? user_prof : UserProfile;
  const title = <>{username}</>
  
  const mobtitle = <span className='fa fa-user headicon'></span>
    return(
    <>
     <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" className='h-55'>
     {contact && <ContactUs onDismiss={()=> setContact(false)}/>}
        <Navbar.Brand className='logobg py-2' target="blank" href={config?.InnerLandingpage}><img src={logo} className='logo_resize' /></Navbar.Brand>
<div className='marque hidein767'>
<Marquee className='header_marque'>
 <div>
 <img src={bz} /> ZARC(BSC) <span className='text-success'>+ {zarcBSCPrice}</span>
 <img src={sz} /> ZARC(SOL) <span className='text-danger'>+ {zarcSolanaPrice}</span>
 <img src={ez} /> ZARC(ETH) <span className='text-success'>+ {zarcBSCPrice}</span>

 {/* <img src={bz} /> BSC-ZARC <span className='text-success'>+ 0.051076</span>
 <img src={sz} /> Sol-ZARC <span className='text-danger'>+ 0.052996</span>
 <img src={ez} /> ETH-ZARC <span className='text-success'>+ 0.051076</span> */}


 </div>

</Marquee>



</div>

        <div className='d-flex mobileicons'>

      {/* <div className='bal  headfont me-2' >
                <span className="upper">Balance:</span>  
                <span className=' ms-sm-2 ms-1 poppins'>ZARC 0 </span>  
                <span className='ms-sm-2 ms-1 poppins'> ZARC SOL 0</span>
            </div>
          */}

<div className='balancedrop mt-md-0 mx-3' >
            <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      <span className="upper">Balance</span> 
      </Dropdown.Toggle>

    
       <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"> { coindata && coindata?.length > 0 ? <> {coindata && coindata.map((item) =>(item?.Balance?.symbol === "ZARC" ? 
                <span className='poppins'>  {item?.Balance?.chainId == "56" && item?.Balance?.symbol === "ZARC" ? "ZARC(BSC)" : item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)}</span> 
                 : <></>  ) )}</> : 
                 <><span className='poppins'>ZARC(BSC) 0</span></> 
                  }
                  </Dropdown.Item>
        <Dropdown.Item href="#/action-2"> { coindata && coindata?.length > 0 ? <> {coindata && coindata.map((item) =>(item?.Balance?.symbol === "ZARCSol" ? 
                <span className='poppins'> {item?.Balance?.chainId == "9" && item?.Balance?.symbol === "ZARCSol" ? "ZARC(SOL)" : item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)}</span> 
                 : <></>  ) )}</> :
                  <><span className='poppins'>ZARC(SOL) 0</span></> 
                  }</Dropdown.Item>

<Dropdown.Item href="#/action-2"> { coindata && coindata?.length > 0 ? <> {coindata && coindata.map((item) =>(item?.Balance?.symbol === "ZARCEth" ? 
                <span className='poppins'> { item?.Balance?.chainId == "1" && item?.Balance?.symbol === "ZARCEth" ? "ZARC(ETH)" : item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)}</span> 
                 : <></>  ) )}</> :
                  <><span className='poppins'>ZARC(ETH) 0</span></> 
                  }</Dropdown.Item>
       
      </Dropdown.Menu>
    </Dropdown>
                
               
               
            </div>
       
            <button onClick={() => showsidebar()} className="showin767 togglebtn" >
             <span className='fa fa-bars pe-3'></span></button>
        </div>

        <Navbar.Collapse id="responsive-navbar-nav" className={sidebar ? 'pe-3 h-55' : 'd-block mobilemenu pt-md-0 h-55 pt-4 pe-3'}>
       
          <Nav className="ms-auto align-items-md-center">
            
            
            <div className='balancedrop mt-md-0 mt-3 balance_drop_corner' >
            <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      <span className="upper">Balance
      <i className='fa fa-chevron-down ps-1'></i>
      </span> 
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"> { coindata && coindata.length > 0 ? <> {coindata && coindata.map((item) =>(item.Balance.symbol === "ZARC" ? 
                               <span className='poppins'>  {item?.Balance?.symbol === "ZARC" ? "ZARC(BSC)" : item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)}</span> 

                 : <></>  ) )}</> : <><span className='poppins'>ZARC(BSC) 0</span></> 
                  }</Dropdown.Item>
        <Dropdown.Item href="#/action-2"> { coindata && coindata.length > 0 ? <> {coindata && coindata.map((item) =>(item.Balance.symbol === "ZARCSol" ? 
                <span className='poppins'> {item?.Balance?.symbol === "ZARCSol" ? "ZARC(SOL)" : item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)} </span> 
                 : <></>  ) )}</> : <><span className='poppins'>ZARC(SOL) 0</span></> 
                  }</Dropdown.Item>
                  <Dropdown.Item href="#/action-3"> { coindata && coindata.length > 0 ? <> {coindata && coindata.map((item) =>(item.Balance.symbol === "ZARCEth" ? 
                <span className='poppins'> {item?.Balance?.symbol === "ZARCEth" ? "ZARC(ETh)" : item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)} </span> 
                 : <></>  ) )}</> : <><span className='poppins'>ZARC(ETH) 0</span></> 
                  }</Dropdown.Item>
       
      </Dropdown.Menu>
      {/* <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"> { coindata && coindata.length > 0 ? <> {coindata && coindata.map((item) =>(item?.Balance?.symbol === "ZARC" ? 
                <span className='poppins'> {item?.Balance?.symbol} {(item.Balance.amount).toFixed(3)}</span> 
                 : <></>  ) )}</> : <><span className='poppins'>ZARC 0</span></> 
                  }</Dropdown.Item>
        <Dropdown.Item href="#/action-2"> { coindata && coindata.length > 0 ? <> {coindata && coindata.map((item) =>(item?.Balance?.symbol === "ZARC Sol" ? 
                <span className=' poppins'> {item?.Balance?.symbol} {(item?.Balance?.amount).toFixed(3)}</span> 
                 : <></>  ) )}</> : <><span className='poppins'>ZARC 0</span></> 
                  }</Dropdown.Item>
       
      </Dropdown.Menu> */}
    </Dropdown>
                
               
               
            </div>
            
          
            <div  className=' mx-3 notification hidein767'>
            <Dropdown className='notify noify_deoip_align' drop="down"  align="end">  
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span className='headicon fa fa-bell-o headicon_with_grad' onClick={()=> {updateNotificationStatus()}}>
                  {
                  Usernotification && Usernotification?.data?.filter((el)=> el.Status == "unseen").length > 0 || active &&
                  <span></span>
                  }
                  
                  </span> 
                </Dropdown.Toggle>

                <Dropdown.Menu className=''>
                  {/* <div className='themescroll'> */}
                  {Usernotification && Usernotification.length > 0 ? <> <Dropdown.Item href="#/action-1">
                    {Usernotification && Usernotification.map((item) =>
                    <div className='d-flex'>
                    <img src={prof} />
                    <div>
                      
                      <p className='fw-700 mb-0 text-light trunon200 f-14' >{item.message}</p>
                      <p className='text-muted f-12 mb-0'>{item?.updatedAt.substring(0,10)}</p>
                    </div>
                </div>
                    )}

                    
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  </> : 
                  <div className='text-center seeall'><p className='text-white f-12 mb-0 py-2'>No new notification</p></div>}
                  <div className='text-center seeall'>  <Link to="/notification" className='m-auto' >See all Notitfication</Link></div> 
                  {/* </div> */}

                </Dropdown.Menu>

               

              </Dropdown>
              </div>
          
            {/* <Nav.Link href="#deets">
              
             
            
             </Nav.Link> */}


            <NavDropdown className='dnone-767 headdrop' title={title} id="collasible-nav-dropdown" >
              <NavDropdown.Item onClick={()=>onLogoutClick()}> <img src={logout} className="me-2 logut_icon_img"/>Logout</NavDropdown.Item>
              {/* <NavDropdown.Item onClick={() => setContact(true)}> <img src={contact_icon} className="me-2 logut_icon_img"/>Contact</NavDropdown.Item> */}
              <NavDropdown.Item href='https://neonomadhelp.zendesk.com/hc/en-us' target='_blank'> <img src={contact_icon} className="me-2 logut_icon_img"/>Contact</NavDropdown.Item>

             
            </NavDropdown>
          
          </Nav>

          <div  className="showin767 mobilesidebar"><Sidebar/></div> 
        
       
        </Navbar.Collapse>
     
    </Navbar>
    </>
    )
}
export default Header
import React, { useState,useEffect } from 'react';
import {Modal, DropdownButton, Dropdown, Tabs, Tab, Row, Col, Nav} from 'react-bootstrap'
import { UseBankActive, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBankValue } from '../../Hooks/UseValidation';
import TransferedFunds from '../Modals/TransferedFunds';
import * as config from "../../config/env";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import withdrawfiat1 from '../../Assets/Images/hint/withdrawfiat1.png'
import withdrawfiat2 from '../../Assets/Images/hint/withdrawfiat2.png'
import withdrawcrypto1 from '../../Assets/Images/hint/withdrawcrypto1.png'
import withdrawcrypto2 from '../../Assets/Images/hint/withdrawcrypto2.png'
import bank from '../../Assets/Images/hint/bank.png'
import picwith from '../../Assets/Images/hint/with.png'
import with1 from '../../Assets/Images/hint/with1.png'

import profile from '../../Assets/Images/hint/profile.png'
import profile1 from '../../Assets/Images/hint/profile1.png'
import profile2 from '../../Assets/Images/hint/profile2.png'
import profile3 from '../../Assets/Images/hint/profile3.png'


const options= {
  responsive:{
    0: {
        items: 1,
    },
    450: {
        items: 1,
    },
    600: {
        items: 1,
    },
    1000: {
        items: 1,
    },
},
}

const ProfileHint = (props) =>{
   const[profilehint, setProfilehint] = useState(true)
 
   



// const closepopup = ()=>{
//      window.location.href = config?.frontendurl + '/deposit'
//     $('#deposithintopup').hide()
// }
  

const skip  = () =>{
  localStorage.setItem("profilepage",1)
}

   return(
    <>
    <Modal size="lg" show={profilehint}  id="profilehintopup" onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big_hint'>
    {/* {true && <TransferedFunds bankdata={SearchCoinData && SearchCoinData} refcode={props.refcode} onDismiss={() => setFunds(false)} />} */}
      <Modal.Header className='border-0 pb-0'>
     
           {/* <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only"></span></button> */}
      </Modal.Header>
      <Modal.Body className='px-sm-5 p-3 themescroll'>
       
      <div className='mb-4 content viewdetails'>
      
       
        
        <h3 className='headfont text-center'>Hint</h3>
  

        <Tab.Container id="uncontrolled-tab-example" defaultActiveKey="fiatwhint" className="mb-3 pt-4">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="tab_stl_new tab_stye_modal">
            <Nav.Item>
              <Nav.Link eventKey="fiatwhint">
              <div>
              Fiat
              </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="cryptowhint">
                <div>
                Crypto
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="profilehint">
                <div>
                Profile
                </div>
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="fiatwhint">
         <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <img src={withdrawfiat1} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>choose the network and enter the amount, enter the wallet address which would like to receive the amount and submit the withdraw request. </p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={bank} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>•	To withdraw funds from the NeoNomad app to your bank account, make sure your banking details are added to your profile. Without this information, you will not be able to withdraw to your bank account. 

</p>

<p className='desc_hint mt-3'>*Remember, only ZARC can be withdrawn to a fiat bank account.
</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={withdrawfiat2} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>Requested funds will automatically credited to your wallet</p>
    </div>
</div>

</OwlCarousel>
</div>

      
   
            </Tab.Pane>
            <Tab.Pane eventKey="cryptowhint">
            <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <img src={picwith} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>To withdraw your cryptocurrency or fiat to your self-custody wallet, simply choose the desired crypto from the dropdown menu.</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={with1} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>To withdraw your chosen cryptocurrency, simply tap the "Withdraw" button</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={withdrawcrypto1} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>To withdraw funds, enter the desired amount and provide your self-custody wallet address on the selected network. Click "submit" to send your crypto directly to your wallet.</p>
    </div>
</div>

<div class='item'>
  <div className='text-center'>
    <img src={withdrawcrypto2} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>After updating your banking details, tap on "Withdraw Fiat." Choose your bank account, enter the withdrawal amount, and select the preferred network. Finally, click the 'Withdraw' button to complete the process.</p>
    </div>
</div>


</OwlCarousel>
</div>
       
            </Tab.Pane>

            <Tab.Pane eventKey="profilehint">
            <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <div className='row'>
      <div className='col-6'>
      <img src={profile} className='img-fluid img_hint' />

      </div>
      <div className='col-6'>
    <img src={profile1} className='img-fluid img_hint' />
        
        </div>
    </div>

    <p className='desc_hint mt-3'>Easily update your Profile, contact details, and Password by clicking the edit icon on your avatar.</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={profile2} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>Easily access and manage your profile, receive wallet address, and monitor your current balances in one convenient place.</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={profile3} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>Enhance the security of your NeoNomad app by enabling 2FA: log out, log back in, scan the QR Code with Google Authenticator, enter the displayed code, and confirm!</p>
    </div>
</div>




</OwlCarousel>
</div>
       
            </Tab.Pane>
          </Tab.Content>
<div className='text-center mt-4'>
          <button className="btn btn-theme" onClick={() => {props.onDismiss();skip()}}>Skip Hints</button>
          </div>
        </Col>
      </Row>
    </Tab.Container>


        
  

      
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default ProfileHint
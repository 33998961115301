import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import prof from '../../Assets/Images/prof1.jpg'
import { UseRequestResetPassword } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { sleep } from '../../Hooks/UseBackendAction/UseGetController';
import { InnerLandingpage, Landingpage } from '../../config/env';

const ForgetPassword = (props) =>{
    const[password, setPassword] = useState(true)

    const [Email, setEmail] = useState("")
    const [errors, seterrors] = useState({})

    const userResetpassword = async(data) =>{
        await UseRequestResetPassword(data)
        await sleep(1000)
    
    if (localStorage.getItem("forgotEmail")) {
      setPassword(false)
        }
    }
    const onSubmit = async () => {
      
         const userData = {
            user_name: Email,
      
        };
        await userResetpassword(userData)
      }
   return(
    <div className='modal_back_image'>
    <Modal show={password}  centered scrollable className='modal_style thememodal'>
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body>
      <div className='mb-4 logins'>
        <h3 className='headfont mb-4 text-center'>Forgot Password</h3>
      <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Username</label>
                    <input placeholder='Enter username' required className='form-control' onChange={(e) => { setEmail(e.target.value) }}/>
                                <span className="text-danger f-12 mb-3 d-block text-left">{errors.email}</span>
            </div>
     
        

        <div className='w-full mb-3'>
          <button className='btn btn-theme w-full' onClick={()=>onSubmit()}>Submit</button>
        </div>
        <div className='d-flex jc-between align-items-center'>
            <Button variant='link' className='aqua ps-0' onClick={() => window.location.href = InnerLandingpage}>Home</Button>
            <Button variant='link' className='aqua ps-0'  onClick={() => {props.onDismiss() ; props.showlogin(); }}>Login</Button>
        </div>


       
        
      </div>
    </Modal.Body>

</Modal>
  </div>
   )
}

export default ForgetPassword
import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import prof from '../../Assets/Images/prof1.jpg'
import { NewContact } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { validateUserContact ,countryList } from '../../Hooks/UseValidation';
const ContactUs = (props) =>{
    const[contact, setContact] = useState(true)
    const [name,setname] = useState("")
    const [email,setemail] = useState("")
    const [phoneNo,setphoneNo] = useState("")
    const [country,setcountry] = useState("")
    const [comments,setcomments] = useState("")
    const [errors, seterrors] = useState({})
    const [CountryList,setcountryList] = useState(countryList)


    const onSubmit = async (e) => {

      e.preventDefault();
      const UserData = {
          name: name,
          email: email,
          phoneNo: phoneNo,
          comments: comments,
          // country: country,
          
          
      };
      
      const { errors, isValid } = await validateUserContact(UserData);
      if (!isValid) {
          seterrors(errors)
      }
      else {
         await NewContact(UserData)
         
      }

     

  };

   return(
    <>
    <Modal show={contact}  centered scrollable className='modal_style thememodal modal_big'>
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={onSubmit}className='mb-4 logins'>
        <h3 className='headfont mb-4 text-center'>Contact Us</h3>
        <div className='row'>
          <div className='col-12 col-md-6'>
          <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Name</label>
                    <input placeholder='Enter Name' 
                    required
                    value={name}
                    onChange={(e) => { setname(e.target.value)}} className='form-control'/>
                                           <span className="text-danger f-12 d-block text-left">{errors.name}</span>

            </div>
          </div>
          <div className='col-12 col-md-6'>
          <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Email</label>
                    <input  placeholder='Email'
                                          required
                                          value={email}
                                          onChange={(e) => { setemail(e.target.value)}} 
                                          className='form-control'/>
            <span className="text-danger f-12 d-block text-left">{errors.email}</span>
            </div>
          </div>
          {/* <div className='col-12 col-md-6'>
          <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Phone Number</label>
                    <input required
                                        value={phoneNo}
                                       
                                        pattern="[1-9]{1}[0-9]{9}"
                                        title="Enter 15 digit Mobile Number"
                                        maxLength="15"
                                        onChange={(e) => { setphoneNo(e.target.value)}}placeholder='Enter Phone Number' className='form-control'/>
                                         <span className="text-danger f-12 d-block text-left">{errors.phoneNo}</span>

            </div>
          </div> */}
          {/* <div className='col-12 col-md-6'>
          <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Country</label>
                    <select 
                     value={country}
                     onChange={(e) => { setcountry(e.target.value)}}
                     className='form-control'>
                       <option >Select Country</option>
                                           {CountryList && CountryList.map((item) => 
                            
                                <option value={item.name}>{item.name}</option>
                                          )} 
                                        
                    </select>
                    <span className="text-danger f-12 d-block text-left">{errors.country}</span>

            </div>
          </div> */}
        </div>
           
           

         
           
           
            <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Comments</label>
                   <textarea rows="5" value={comments}
                                            onChange={(e) => { setcomments(e.target.value)}} className='form-control'></textarea>
            </div>
            <span className="text-danger f-12 d-block text-left">{errors.comments}</span>

     
        

        <div className='w-full mb-3'>
          <div className='text-end btn_end_style'>
          <button className='btn btn-theme w-full btn_big_theme_border'>Submit</button>
          </div>
        </div>
       


       
        
      </form>
    </Modal.Body>

</Modal>
  </>
   )
}

export default ContactUs
import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Accordion } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import Header from './Header';
import Sidebar from './Sidebar';
import check from '../Assets/Images/check.png'
import prof from '../Assets/Images/dprof.png'
import coin1 from '../Assets/Images/coin1.png'
import WithdrawModal from './Modals/WithdrawModal';
import EditProfile from './Modals/EditProfile';
import Verification from './Modals/Verification';
import FiatWithdrawModal from './Modals/FiatWithdraw';
import AccountDetails from './Modals/AccountDetails';
import { UsergetInfobyname, UsetokeninfoBySymbol, UsetokeninfoBySymbolandchainid } from '../Hooks/UseBackendAction/UseGetController';
import { backurl } from "../config/env";
import { UseisAuth } from '../Hooks/UseBackendAction/UseCreateUser.js';
import { searchedBalanceValue } from '../Hooks/UseValidation';
import ProfileHint from './Modals/ProfileHint';
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast'

import aim from '../Assets/Images/Landing-page/aim.png'
import loaderimg from './../Assets/Images/loaderlogo.png'


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



// const options= {
//   responsive:{
//     0: {
//         items: 1,
//     },
//     450: {
//         items: 1,
//     },
//     600: {
//         items: 1,
//     },
//     1000: {
//         items: 1,
//     },
// },
// }

const optionsnew = {
  autoplay:true,
  autoplaySpeed:3000,
  autoplayHoverPause:true,
   loop:true, 
   margin:10,
   dots:false
}

const Profile = () => {
  const [withdraw, setWithdraw] = useState(false)
  const [profile, setProfile] = useState(false)
  const [verify, setVerify] = useState(false)
  const [fiatwithdraw, setFiatWithdraw] = useState(false)
  const [accountdetails, setAccountDetails] = useState(false)
  const [UserInfo, setUserInfo] = useState({})
  const [Userbalance, setUserbalance] = useState({})
  const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
  const [coindata, setcoindata] = useState([])
  const [fiatcoindata, setfiatcoindata] = useState([])
  const [fiatDepositdata, setfiatDepositdata] = useState([])
  const [userWalletAddress, setuserWalletAddress] = useState("")
  const [isauth, setisauth] = useState(null);
  const [isSearch, setisSearch] = useState(false)
  const [isfiatSearch, setisfiatSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData] = useState([])
  const [FiatSearchCoinData, SetFiatSearchCoinData] = useState([])
  const [Search, setSearch] = useState("")
  const [selectToken, setselectToken] = useState("")
  const [selectnetwork, setselectnetwork] = useState("")
  const [authcode, setauthcode] = useState("")
  const [key, setKey] = useState("")
  const [profilehint, setProfilehint] = useState(true)
  const [groupedToken,setgroup]=useState([])
  const [showloader,setShowloader] = useState(false)





  const navgate = useNavigate();

  const handledata = (datas) => {
    navgate("/deposit", { state: "deposit" })
  }


  const copyText = (a, b) => {
    toast.success("your private code is copied", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    })
}

const showLoad = ()=>
{
  document.getElementsByTagName("body")[0].classList.add("body_over_hidden");
  setShowloader(true);
}


const hideLoad = ()=>
{
  document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");
  setShowloader(false);

}

 





  
  useEffect(() => {

    document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

    showLoad();

    if (localStorage.getItem("profilepage") == 1) {
      setProfilehint(false)
    }

    Getdata();
    document.getElementById("cryptobalance").classList.add("blur")
    document.getElementById("fiatbalance").classList.add("blur")
    document.getElementById("card").classList.add("blur")
    getUserBalance();
  
  }, [UserName]);

  const Getdata = async () => {

    var UserInfo = await UsergetInfobyname(UserName);
   
    setUserInfo(UserInfo.data)
    setUserbalance(UserInfo?.data?.Holding)
    setuserWalletAddress(UserInfo.data?.walletaddress)
    setisauth(UserInfo?.data?.isAuth)

    var key = UserInfo?.data?.authcode.replace(/\W/g, '').toLowerCase();
    setKey(key)
    var link = "otpauth://totp/{{NAME}}?secret={{KEY}}";
    var name = "ZARC"
    var codeToQr = link.replace(/{{NAME}}/g, name).replace(/{{KEY}}/g, key)

    setauthcode(codeToQr)

    hideLoad()
  }

  const getUserBalance = async () => {
    var UserInfo = await UsergetInfobyname(UserName);
    setfiatDepositdata(UserInfo?.data?.DepositProof)
    var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
      var dat = await UsetokeninfoBySymbolandchainid(item.symbol,item.chainId)

      dat['data']['Balance'] = item

      return dat['data']

    }))

    if (tokenSymbol.length > 0) {
      setcoindata(tokenSymbol)
      const groupByCategory = tokenSymbol.reduce((group, val) => {
        const { name } = val;
        group[name] = group[name] ?? [];
        group[name].push(val);
        return group;
      }, {});
      setgroup(groupByCategory)
     
      setfiatcoindata(tokenSymbol)
      document.getElementById("cryptobalance").classList.remove("blur")
      document.getElementById("fiatbalance").classList.remove("blur")
      document.getElementById("card").classList.remove("blur")
    }

    hideLoad()
  }

  const onchangeCheck = () => {
    if (isauth == true) {
      setisauth(false)
    }
    else {
      setisauth(true)
    }
  }

  const onisAuthSubmit = async () => {

    const isauthdata = { user_name: UserName, isAuth: isauth };
    await UseisAuth(isauthdata)


  };

  return (
    <>
      {profile && <EditProfile record={UserInfo} onDismiss={() => setProfile(false)} />}
      {withdraw && <WithdrawModal selectToken={selectToken} selectNetwork={selectnetwork} onDismiss={() => setWithdraw(false)} />}
      {verify && <Verification onDismiss={() => setVerify(false)} />}
      {fiatwithdraw && <FiatWithdrawModal onDismiss={() => setFiatWithdraw(false)} />}
      {accountdetails && <AccountDetails onDismiss={() => setAccountDetails(false)} />}

      {profilehint && <ProfileHint onDismiss={() => setProfilehint(false)} />}




      <div className='profile dashboard noshadow'>
        <div className='bggrad'></div>
        <div className='bggradleft'></div>
        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /> </div>
          <div className='content'>
          {showloader?
            <div className='loader_overlay'>
            <div className='loader_div'>
              <img src={loaderimg} className='img-fluid loader_logo' />
            </div>
          </div>
          :
          <></>
            }
            <div className='container-fluid container-theme p-4 position-relative'>
            <div className='mb-4 deposit_topbtns'>
                                
                                <h2 className='headfont'>Profile</h2>
                                              <Button className="btn btn-theme deposit_btns" onClick={() => setProfilehint(true)} >Hint</Button>
              
                                          </div>
              <div className=''>
                <div className='row profdetails' >
                  <div className='col-xl-3 col-md-5 text-center mb-3'>
                    <div className='card_cutout h-100'>
                      <div className='position-relative profbox'>
                        {/* <img src={prof} className="profimg"/> */}
                        <img src={backurl + `/Images/${UserInfo.logoURI}`} onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = prof;
                        }} />
                        <button className='editprofbtn' onClick={() => setProfile(true)}> <span className='fa fa-pencil' ></span></button>
                      </div>
                      <h5 className='prof_name_new mb-0 mt-3'>{UserInfo.first_name} {UserInfo.last_name}</h5>
                      <p className='mb-0 text-muted prof_des_new'>{UserInfo.user_name}</p>
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-7 mb-3'>
                    <div className='card_cutout h-100'>
                      <div className='row'>
                        <div className='col-xl-6 col-sm-6 mb-3'>
                          <div>
                            <p className='text-muted mb-0'>Username</p>
                            <p className='headfont'>{UserInfo && UserInfo?.user_name}</p>
                          </div>
                        </div>
                        <div className='col-xl-6 col-sm-6 mb-3'>
                          <div>
                            <p className='text-muted mb-0'>Email</p>
                            <p className='headfont'>{UserInfo && UserInfo?.email}</p>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-xl-6 col-sm-6 mb-3'>
                          <div>
                            <p className='text-muted mb-0'>User ID</p>
                            <p className='headfont'>{UserInfo && UserInfo?.userId}</p>
                          </div>
                        </div>
                        <div className='col-xl-6 col-sm-6 mb-3'>

                          <div>
                            <p className='text-muted mb-0'>Address</p>
                            <p className='headfont'>{`${userWalletAddress && userWalletAddress.substring(0, 7)}...${userWalletAddress && userWalletAddress.substring(35, 42)}`} </p>
                          </div>
                        </div>
                      </div>
                      <div className='row'>

                        <div className='col-xl-6 col-sm-6 mb-3'>

                          <div>
                            <p className='text-muted mb-0'>First Name</p>
                            <p className='headfont'>{UserInfo && UserInfo?.first_name ? UserInfo?.first_name : ""} </p>
                          </div>
                          {/* <div>
                            <p className='text-muted mb-0'>Card No.</p>
                            <p className='headfont'>**** **** **** 3581</p>
                          </div> */}

                        </div>
                        <div className='col-xl-6 col-sm-6 mb-3'>

                          <div>
                            <p className='text-muted mb-0'>Last Name</p>
                            <p className='headfont'>{UserInfo && UserInfo?.last_name ? UserInfo?.last_name : ""} </p>
                          </div>


                          {/* <div>
                            <p className='text-muted mb-0'>Verified <span className='fa fa-check-circle text-success f-14'></span></p>
                            <p className='headfont'>Wallet 3 | <span className='fa fa-plus-circle themeclr f-14'></span></p>
                          </div> */}
                        </div>
                        {/* <div className='col-xl-6 pb-xxl-0 pb-4 '> */}
                        {/* <p className='text-muted f-14 pb-xxl-0 pb-3'>Monthly limits</p> */}
                        {/* <div className='d-flex gap-xxl-4 mt-3'>
                             <div className='ciclechart me-2'>
                              <p>60</p>
                              <span>No.of users</span>
                            </div>
                            <div className='ciclechart violet'>
                              <p>60</p>
                              <span>No.of users</span>
                            </div>
                          </div> */}
                        {/* <div className='d-flex justify-content-xl-between mt-3'>
                             <div className=' me-2 text-center f-14 text-muted' style={{width: "90px"}}>
                              
                               <span>No.of users</span>
                            </div>
                            <div className='text-center f-14 text-muted' style={{width: "90px"}}>
                            <p>No.of users</p>

                            </div>
                          </div> */}

                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <div id="card" className='col-xl-3 col-md-12 mb-3 card_who_div_pos'>


                    <div className='colorcards themescroll hidescroll'>

                    {/* {coindata && coindata.length > 0 ? <>{coindata && coindata.map((item) =>
                      (
                        item.Balance.type === "token" && (item.Balance.symbol == "ZARCSol" || item.Balance.symbol == "ZARC" || item.Balance.symbol == "ZARCEth") ?
                          <div className="aquacard">
                            <div className='flex_shoe_cent'>
                              <p className='f-12 mb-1 mt-4'>Main Balance</p>
                              <h3 className='h5_tex_coin'>{(item.Balance.amount).toFixed(6)} {item.Balance.symbol == "ZARCSol" ? "ZARC(Solana)" : item.Balance.symbol == "ZARC" ? "ZARC(BSC)" : item.Balance.symbol == "ZARCEth" ? "ZARC(Eth)" : item.Balance.symbol}</h3>
                            </div>
                            <div className='d-flex jc-between'>
                              <p> <span className='lightgreen'> </span></p>
                              <img src={backurl + `/Images/${item.logoURI}`} className="coinimg" />
                            </div>
                          </div>
                          : <></>))}</> :
                        <div className='yellowcard text-dark'>
                          <div className='flex_shoe_cent'>
                            <p className='f-12 mb-1 mt-4'>Main Balance</p>
                            <h3 className='h5_tex_coin'>$0</h3>
                          </div>
                          <div className='d-flex jc-between'>
                            <p> <span className='lightgreen'></span></p>
                            <img src={coin1} className="coinimg" />
                          </div>
                        </div>} */}

{coindata && coindata.length > 0 ?
                        <OwlCarousel className='owl-theme carouse_prof_card' {...optionsnew} items={1} >
                          {groupedToken && Object.values(groupedToken).map((val, index) => {
                            return(
                              <>
                              <div className="aquacard aquacard_heh">
                              <p className='f-12 mb-1'>Main Balance  {Object.keys(groupedToken)[index]}</p>
                              {val.map((item, i) => {
                                return(
                                  <>
                                  <div className='flex_img_tetx_card_casr mt-4'>
                                      <div className='flex_shoe_cent'>
                                        <h3 className='h5_tex_coin'>
                                        {item.symbol == "ZARCSol" || item.chainId === "9" ? "Solana" : 
                                              item.symbol == "ZARC" || item.chainId === "56" ? "BSC" : 
                                              item.symbol == "ZARCEth" || item.chainId === "1"? "ETH" : item.symbol
                                              }
                                          </h3> 
                                            {/* {item.symbol == "ZARCSol" ? "Solana" : item.symbol == "ZARC" ? "BSC" : item.symbol == "ZARCEth" ? "ETH" : item.symbol == "USDT" ? "BSC" : item.symbol}*/}
                                        <h3 className='h5_tex_coin'>{(item.Balance.amount).toFixed(3)} {item.Balance.symbol == "ZARCSol" ? "ZARC(SOL)" : item.Balance.symbol == "ZARC" ? "ZARC(BSC)" : item.Balance.symbol == "ZARCEth" ? "ZARC(ETH)" : item.Balance.symbol}</h3>
                                      </div>
                                      <div className='d-flex jc-between'>
                                        <p> <span className='lightgreen'> </span></p>
                                        <img src={backurl + `/Images/${item.logoURI}`} className="coinimg" />
                                      </div>
                                </div>
                                </>
                                )
                              }
                              )}
                              
                            </div>
                              </>
                            )
                            
                          })
                          }
                        </OwlCarousel>

:
<div className='yellowcard text-dark'>
<div className='flex_img_tetx_card_casr mt-4'>

<div className='flex_shoe_cent'>
<p className='f-12 mb-1'>Main Balance</p>
<h3 className='h5_tex_coin'>$0</h3>
</div>
<div className='d-flex jc-between'>
<p> <span className='lightgreen'></span></p>
<img src={coin1} className="coinimg" />
</div>
</div>
</div>
}

                  
                    </div>
                  </div>
                </div>
                <div className='row mt-3 kycdetails'>
                  <div className='col-lg-6 mb-3 col-12'>
                    <div className='card_cutout h-100'>
                      <div className='flex_titl_btn'>
                        <div>
                          <h4 className='font_new_profbig mb-2'>Update KYC</h4>

                          <p className='mb-0 font_new_profsmall'> Status : {(UserInfo && UserInfo?.isKYCVerify == null && UserInfo && UserInfo?.KYCFile?.length == 0) ? "NO KYC" : UserInfo && UserInfo?.isKYCVerify == null || (UserInfo && UserInfo?.isKYCVerify == "false" && UserInfo && UserInfo?.KYCFile?.length > 0) ? "Pending" : UserInfo && UserInfo?.isKYCVerify == "true" ? "Verified" : "Cancelled"}</p>
                        </div>
                        {/* <p className='mb-0'><span className='fa fa-times text-danger'></span> Transcation</p> */}
                        <div className='text-end'>

                          {UserInfo && UserInfo?.isKYCVerify == null ? <button className='btn btn-theme px-4 btn_big_theme_border' onClick={() => setVerify(true)}>Apply</button>
                            : UserInfo && UserInfo?.isKYCVerify == "true" ? <button className='btn btn-theme px-4 btn_big_theme_border'>Verified</button> :
                              //  (UserInfo && UserInfo?.isKYCVerify == "false" && UserInfo && UserInfo?.KYCFile?.length > 0) || (UserInfo?.isKYCVerify == null &&  UserInfo && UserInfo?.KYCFile?.length > 0 )? <button className='btn btn-theme px-4'>Pending</button> :
                              <button className='btn btn-theme px-4 btn_big_theme_border' onClick={() => setVerify(true)}>Reupload</button>}
                        </div>
                      </div>
                      <div className='jc-between d-flex mt-4'>
                        <h4 className='font_new_profbig mb-3'>2FA</h4>
                        <div className=''>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                            checked={isauth}
                            onChange={async () => { await onchangeCheck(); onisAuthSubmit() }}
                            className="text-muted mb-0"
                          />
                        </div>

                      </div>
                      <div className='qrcode mx-auto my-4'><QRCode size="110" value={authcode} /></div>

                      <div className='notecont_without_border'>
                      <p className=''><span><span>Private Key :</span> <span className='text-muted'>{UserInfo?.authcode}
                      <CopyToClipboard text={UserInfo?.authcode} onCopy={() => copyText('invite link', `${UserInfo?.authcode}`)}>
                      <button className='btn copy_btn_theme'>
                        <i className='fa fa-copy'></i>
                      </button>
                    </CopyToClipboard>
                      
                      </span></span></p>

                        <p className=''><span><span>Note :</span> <span className='text-muted'>To log into the NeoNomad app, simply scan the QR Code using the Google Authenticator app (available on the Google Play Store or Apple App Store), enter the code displayed in the app into the designated text field, and click the ’confirm’ button</span></span></p>
                      </div>
                    </div>

                  </div>
                  <div className='col-lg-6 mb-3 col-12 bank_deta_cards'>
                    <div className='card_cutout h-100'>
                      <h4 className='font_new_profbig mb-4'>Bank Details</h4>

                      {UserInfo && UserInfo?.BankAccount?.length > 0 ? <div className='banklist'>
                        {UserInfo && UserInfo?.BankAccount.map((item, i) =>
                          <div className='row'>
                            <div className='col-xl-6 mb-2'>
                              <div className='d-flex gap-2'>
                                <p className='mb-0 min_width_bank_column'>Bank : </p>
                                <span className='textaqua_blue ms-1'>{item?.bankname}</span>
                              </div>
                            </div>
                            <div className='col-xl-6 mb-2'>
                              <div className='d-flex gap-2'>
                                <p className='mb-0 min_width_accno_column'>Account No : </p>
                                <span className='textaqua_blue ms-1 min_width_accno_value'>{item?.Accountnumber}</span>
                              </div>
                            </div>

                          </div>

                        )}

                      </div> : <>
                        <p className='mb-0'><span className='fa fa-times text-danger'></span> No Bank Details Added</p></>}

                      <div className='text-center mt-3'>
                        <button className='btn btn-theme btn_big_theme_border' onClick={() => setAccountDetails(true)}>Add bank</button>

                      </div>


                    </div>
                  </div>

                </div>

                <div className='row mt-3 cryptfiatlist' id="withdraw_sec">
                  <div className='col-xl-6 col-lg-12 mb-3'>
                    <div id="cryptobalance" className='card_cutout h-100'>
                      <div className='d-sm-flex jc-between mb-4  align-items-center'>
                        <h4 className='font_new_profbig mb-2'>Crypto</h4>


                        {/* <div className='d-sm-flex align-items-center'>
                         
                          <InputGroup className="br-10 input_search_bg_prof">
                            <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span> </InputGroup.Text>

                            <Form.Control placeholder="Find coin" onChange={(e) => { setSearch(e.target.value); handleChange(e) }} className='coininput' />
                          </InputGroup>
                        </div> */}

                      </div>
                      <div className='tablescroll themescroll pe-2'>
                       
                          <Accordion className='accordion_custom_token'>
                            {/* { console.log("data",coindata,Object.values(groupedToken)) } */}
                          {groupedToken && Object.values(groupedToken).map((val, index) => {
                            // item.network =='solana'?
                            // console.log(val,'mapped', Object.keys(groupedToken)[index])
                            
                            return (
                              // item.Balance.type == "token" && item.name == "ZARC" || item.name == "USDT" ?
                                <>
                                  <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                      <p>{Object.keys(groupedToken)[index]}</p>
                                      {/* <img src={backurl + `/Images/${item.logoURI}`} />
                                      {item.symbol == "ZARCSol" ? "ZARC(Solana)" : item.symbol == "ZARC" ? "ZARC(BSC)" : item.symbol == "ZARCEth" ? "ZARC(Eth)" : item.symbol}
                                      <span className='ps-3'>{(item.Balance.amount).toFixed(3)}</span> */}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {val.map((item,i)=>{
                                        console.log("log",item);
                                        return(
                                          <div className='d-sm-flex  align-items-center crypt jc-between mb-3'>

                                          <div className='d-flex align-items-center  gap-3 mb-2'>
                                            <p className='mb-0 f-14 d-flex align-items-center gap-2'><img src={backurl + `/Images/${item.logoURI}`} />
                                              {item.symbol == "ZARCSol" || item.chainId === "9" ? "Solana" : 
                                              item.symbol == "ZARC" || item.chainId === "56" ? "BSC" : 
                                              item.symbol == "ZARCEth" || item.chainId === "1"? "ETH" : item.symbol
                                              }</p>
                                            <p className='mb-0 f-14'>{(item.Balance.amount).toFixed(3)}</p>
                                          </div>
                                          <div className=' mb-2'>
                                            {/* <button className='btn btn-theme-bordered me-2' onClick={(data) => handledata(data)}>
                                              <span className="btn-theme-bordered-span">Deposit</span>
                                            </button>
                                            <button className='btn btn-theme-bordered' onClick={() => { setWithdraw(true); setselectToken(item.symbol);setselectnetwork(item.network) }}>
                                              <span className="btn-theme-bordered-span">Withdraw</span>
                                            </button> */}
                                          </div>
                                        </div> 
                                        )
                                      })}
                                      
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </>
                               
                            )
                          })}
                          </Accordion>
                         
                        


                        {/* {!isSearch ? <>

                          {coindata && coindata.map((item) => (item.Balance.type == "token" ?
                            <div className='d-sm-flex  align-items-center crypt jc-between mb-3'>

                              <div className='d-flex align-items-center  gap-3 mb-2'>
                                <p className='mb-0 f-14 d-flex align-items-center gap-2'><img src={backurl + `/Images/${item.logoURI}`} />
                                  {item.symbol == "ZARCSol" ? "ZARC(Solana)" : item.symbol == "ZARC" ? "ZARC(BSC)" : item.symbol == "ZARCEth" ? "ZARC(Eth)" : item.symbol}</p>
                                <p className='mb-0 f-14'>{(item.Balance.amount).toFixed(3)}</p>
                              </div>
                              <div className=' mb-2'>
                                <button className='btn btn-theme-bordered me-2' onClick={(data) => handledata(data)}>
                                  <span className="btn-theme-bordered-span">Deposit</span>
                                </button>
                                <button className='btn btn-theme-bordered' onClick={() => { setWithdraw(true); setselectToken(item.symbol) }}>
                                  <span className="btn-theme-bordered-span">Withdraw</span>
                                </button>
                              </div>
                            </div> 
                            : <></>))}</> : <>
                          {SearchCoinData && SearchCoinData.map((item) => (item.Balance.type == "token" ?
                            <div className='d-sm-flex  align-items-center crypt jc-between mb-3'>

                              <div className='d-flex align-items-center  gap-3 mb-2'>
                                <p className='mb-0 f-14 d-flex align-items-center gap-2'><img src={backurl + `/Images/${item.logoURI}`} /> {item.symbol}</p>
                                <p className='mb-0 f-14'>{(item.Balance.amount).toFixed(3)}</p>
                              </div>
                              <div className=' mb-2'>
                                <button className='btn btn-theme-bordered me-2' onClick={(data) => handledata(data)}>
                                  <span className="btn-theme-bordered-span">Deposit</span>
                                </button>
                                <button className='btn btn-theme-bordered' onClick={() => { setWithdraw(true); setselectToken(item.symbol) }}>
                                  <span className="btn-theme-bordered-span">Withdraw</span>
                                </button>
                              </div>
                            </div> : <></>))}</>} */}


                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-12 mb-3'>
                    <div id="fiatbalance" className='card_cutout crypt h-100 '>
                      <div className='d-sm-flex jc-between  mb-0  align-items-center'>
                        <h4 className='font_new_profbig mb-2'>Fiat</h4>


                        {/* <div className='d-sm-flex align-items-center'>
                          <InputGroup className="br-10 input_search_bg_prof">
                          <InputGroup.Text id="basic-addon2"><span className='fa fa-search'></span> </InputGroup.Text>

                            <Form.Control placeholder="Find coin" onChange={(e)=>{setSearch(e.target.value);FiathandleChange(e)}}className='coininput' />
                          </InputGroup>
                      </div> */}

                      </div>
                      <div className='text-center'>
                        <img src={aim} className='img-fluid imng_full_wdh' />
                      </div>
                      <div className=' mb-2 text-center mt-3'>
                        {/* <button className='btn btn-theme-bordered me-2' onClick={() => navgate("/deposit")}>
                          <span className="btn-theme-bordered-span">Deposit</span>
                        </button>
                        <button className='btn btn-theme-bordered' onClick={() => setFiatWithdraw(true)}>
                          <span className="btn-theme-bordered-span">Withdraw</span>
                        </button> */}
                      </div>

                      {/* <div className='tablescroll themescroll pe-2'>
                  {!isfiatSearch ? <> {fiatcoindata && fiatcoindata.map((item) =>(item.Balance.type == "fiat" ? 
                        <div className='d-sm-flex align-items-center jc-between gap-3 mb-3'>
                            <div className='d-flex align-items-center gap-2 mb-2'>
                              <p className='mb-0 f-14 align-items-center d-flex gap-2'><img src={backurl + `/Images/${item.logoURI}`}  /> {item.Balance.symbol}</p>
                            <p className='mb-0 f-14'>{item.Balance.amount}</p>
                            </div> 
                              <div className=' mb-2'>
                                <button className='btn btn-theme-bordered me-2' onClick={() => navgate("/deposit")}>
                                <span className="btn-theme-bordered-span">Deposit</span>
                                </button>
                                <button className='btn btn-theme-bordered' onClick={() => setFiatWithdraw(true)}>
                                <span className="btn-theme-bordered-span">Withdraw</span>
                                </button>
                            </div>


                        </div>:<></>))}</>     : <>
                        {FiatSearchCoinData && FiatSearchCoinData.map((item) =>(item.Balance.type == "fiat" ? 
                        <div className='d-sm-flex align-items-center jc-between gap-3 mb-3'>
                            <div className='d-flex align-items-center gap-2 mb-2'>
                              <p className='mb-0 f-14 align-items-center d-flex gap-2'><img src={backurl + `/Images/${item.logoURI}`}  /> {item.Balance.symbol}</p>
                            <p className='mb-0 f-14'>{item.Balance.amount}</p>
                            </div> 
                              <div className=' mb-2'>
                                <button className='btn btn-theme-bordered me-2' onClick={() => navgate("/deposit")}>
                                <span className="btn-theme-bordered-span">Deposit</span>
                                </button>
                                <button className='btn btn-theme-bordered' onClick={() => setFiatWithdraw(true)}>
                                <span className="btn-theme-bordered-span">Withdraw</span>
                                </button>
                            </div>


                        </div>:<></>))} </>}   
                        
                      
                    </div> */}


                    </div>
                  </div>
                </div>

              </div>


            </div>


          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
export const  style = {
      
                        
    // minWidth: '300px',
    // minHeight: '55px',
    // border: '2px solid #001489',
    // padding: '16px',
    // color: '#001489'


    minWidth: '300px',
    minHeight: '45px',
    border: '2px solid #222020',
    padding: '16px',
    borderRadius: '15px',
    background: '#0d0d0d',
    color: '#B4B2B2',
    
}

export const iconTheme = {
    primary: '#B4B2B2',
    secondary: '#0d0d0d',
}

export const  position= {
    position: "bottom-center"
}
import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Button, DropdownButton, Dropdown } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import aquirelogo from './../Assets/Images/logo.svg'
import bank from './../Assets/Images/bank.svg'
import Success from './Modals/Success';
import DataTable from 'react-data-table-component';
import check from './../Assets/Images/check.png'
import loaderimg from './../Assets/Images/loaderlogo.png'


import imgtrans from '../Assets/Images/tranfer_icon_img.png'
import ExchangeHint from './Modals/ExchangeHint';
import Swapmodal from './Modals/Swapmodal';
import bridgeShow from "./Modals/Bridgemodal"

import { getAdminWallet, getTokeninfo, GetUseAmountsOutPerToken, sleep, UsergetInfobyname, UsetokeninfoBySymbol, UsetokeninfoBySymbolandchainid } from '../Hooks/UseBackendAction/UseGetController';
import { backurl } from '../config/env';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast'
import { logoutUser, UseApprove, UseSwap } from '../Hooks/UseBackendAction/UseCreateUser.js';
import { searchedBalanceValue, searchedBalanceValueExchange } from '../Hooks/UseValidation';
import {checkIsApprovedRouter, checkIsApprovedRouterETH, getBNBBlance, getETHBlance, toFixedNumber, UseTokenInfo, UseTokenInfoEth } from '../Hooks/UseSwap/useContract';

import { getAmountsIn, getAmountsInEth, getLiquidity, getLiquidityEth } from "../Hooks/UseSwap/UniswapV3"
import pikachu from '../Assets/audio/pikachu.m4a'
import Bridgemodal from './Modals/Bridgemodal';

const Exchange = () => {

  const columns = [
    {
      name: "Logo",
      sortable: true,
      selector: (vals) => {
        return (
          vals.type === "token" || vals.type === "crypto" ?
            <img
              src={backurl + `/Images/${vals.logoURI}`}
              width={30}
              alt='form' className="b-50"
            /> : <></>
        )
      }
    },
    {
      name: "Symbol",
      selector: (vals) => (
        vals.type === "crypto" || vals.type === "token" ? (vals.chainId === "9" && vals.symbol) == "ZARCSol" ? "ZARC(SOL)" :
        (vals.chainId === "56" && vals.symbol) == "ZARC" ? "ZARC(BSC)" : 
        vals.chainId === "1" &&    vals.symbol == "ZARCEth" ? "ZARC(ETH)" : 
        vals.chainId === "1" ? vals.symbol+"(ETH)":
        vals.chainId === "56" ? vals.symbol+"(BSC)":
                        vals.chainId === "9" ? vals.symbol+"(SOL)":
                        vals.symbol : ""),
      sortable: true
    },
    {
      name: "Balance",
      selector: (vals) => {

        return (
          vals.type === "token" || vals.type === "crypto"? 
          <p className=' lightgreen mb-0'>{(vals.Balance.amount).toFixed(3)}</p> : <></>
        )
      },
      sortable: true
    },
    // {
    //   name: "",
    //   selector: (vals) =>{ return(
    //     <p className='lightgreen'>
    //     {`${vals.tokenAddress.substring(0, 4)}...${vals.tokenAddress.substring(39, 42)}`} 
    //   </p>
    //   )},
    //   sortable: true
    // }
  ]




  const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
  const [coindata, setcoindata] = useState([])
  const [inToken, setintoken] = useState({})
  const [fiat, setFiat] = useState({ type: '' })
  const [crypto, setCrypto] = useState({ type: '' })
  const [outToken, setouttoken] = useState({})
  const [tokenoutImage, settokenoutimage] = useState("")
  const [tokenImage, settokenimage] = useState("")
  const [InputAmount, setInputAmount] = useState('')
  const [outAmount, setoutAmount] = useState('')
  const [status, setStatus] = useState("Exchange")
  const [Tokeninfo, setTokeninfo] = useState([])
  const [balance, setBalance] = useState(0)
  const [secondbalance, setsecondbalance] = useState("0")
  const [UserInfo, setUserInfo] = useState({})
  const [intokenaddress, setintokenaddress] = useState("");
  const [outtokenaddress, setouttokenaddress] = useState("");
  const [userWalletaddress, setuserWalletaddress] = useState("");
  const [bnbBalance, setBnbBalance] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [isApprovedLP, setIsApprovedLP] = useState(false);
  const [type, setType] = useState("");
  const [liquidityRate, setLiquidityRate] = useState(0);
  const [exchangehint, setExchangehint] = useState(true);
  const[swapmodal, setSwapmodal] = useState(false);
  const [fee, setFee] = useState(0);

  const [bridgeShow, setBridgeshow] = useState(false)
  const [bridge, setBridge] = useState(false)
  const [ismax, setismax] = useState(false)
  const [amountmax, setamountmax] = useState('')
  const [isBSC, setisBSC] = useState(true);
  const [network, setnetwork] = useState("BSC");
  const [isExchange,setExchage] = useState(false)
  const [isTo,setisTo] = useState(false)

  const [showloader,setShowloader] = useState(false);
  const [minreceived,setminreceived] = useState(0);
  const [isSwap,setisSwap] = useState(false)
  const [isPending,setisPending] = useState(false)

  // const [intechange,setinterchnage] = useState(false)



  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

    showLoad();

    if (localStorage.getItem("exchangepage") == 1) {
      setExchangehint(false)
    }

    getUserBalance();
    gettokeninfo();
    getBnb();


  }, [userWalletaddress]);

 

  const showLoad = ()=>
  {
    document.getElementsByTagName("body")[0].classList.add("body_over_hidden");
    setShowloader(true);
  }


  const hideLoad = ()=>
  {
    document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");
    setShowloader(false);

  }
  const gettokeninfo = async () => {

    const tokeninfo = await getTokeninfo();
    setTokeninfo(tokeninfo?.data)
  
   

    setintoken({ 'type': "", 'symbol': "Select" })

    setouttoken({ 'type': "", 'symbol': "Select" })

   

  }

  const GetTokenPrice = async (value) => {

    if (intokenaddress == "" || outtokenaddress == "") {
      toast.error("Please select the token", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
    } 
    else if ( balance < value) {
      toast.error("You don't have enough balance", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
      setisSwap(false)
      setExchage(true)
      isPending(false)
       return false;
    } 
    else {

     setStatus("Loading...")

   
    const result = isBSC ? await getLiquidity(intokenaddress, outtokenaddress) : await getLiquidityEth(intokenaddress, outtokenaddress);
    console.log("result",result?.balance1);
    setFee(result?.fee);
  


    const amount = isBSC ?  await getAmountsIn(intokenaddress, outtokenaddress, value) :  await getAmountsInEth(intokenaddress, outtokenaddress, value);
     console.log("amount",amount?.getAmountsOut);
    setoutAmount(amount?.getAmountsOut.toFixed(5));
  //min_received calculation
  const data1 = parseFloat(value) - (1 * (parseInt(fee) / 10000) / 100);
  const data2 = data1 * parseFloat(amount?.getAmountsOut)
  const data3 = parseFloat(data2) * 0.5 / 100;
  const data4 = parseFloat(amount?.getAmountsOut) -  data3;
  setminreceived(data4.toFixed(5));
  setisSwap(true)

  sessionStorage.setItem("swaphashlink", isBSC ?  "https://bscscan.com/tx/" : "https://etherscan.io/tx/" )
 
 

    console.log("parseFloat(result?.getAmountsOut) > parseFloat(result?.balance1))",parseFloat(result?.getAmountsOut) > parseFloat(result?.balance1));
    if (parseInt(amount?.getAmountsOut) > parseInt(result?.balance1)) {
      setExchage(true)
      setisSwap(false)
      setStatus("Insufficient Funds")
      return false;
    } else if (amount?.getAmountsOut == undefined) {
      setExchage(true)
      setisSwap(false)
      setStatus("Pair not found")
      return false;
    }

    else {
      setExchage(false)
      
      setStatus("Exchange")
    }
  

   
  

     } 
  }

  const sencondGetTokenPrice = async (value) => {

    if (intokenaddress == "" || outtokenaddress == "") {
      toast.error("Please select the token", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
    }
     else if ( secondbalance < value) {
      toast.error("You don't have enough balance", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
      setisSwap(false)
      setExchage(true)
       return false;
    } 
    else {
      setStatus("Loading...")
      const result = isBSC ? await getLiquidity(outtokenaddress,intokenaddress) : await getLiquidityEth(outtokenaddress,intokenaddress);
      setFee(result?.fee);
      const amount = isBSC ?  await getAmountsIn(outtokenaddress,intokenaddress, value) :  await getAmountsInEth(outtokenaddress,intokenaddress, value);
     
      setInputAmount(amount?.getAmountsOut.toFixed(5));
console.log("result?.balance0",result?.balance0);

 //min_received calculation
 const data1 = parseFloat(value) - (1 * (parseInt(fee) / 10000) / 100);
 const data2 = data1 * parseFloat(amount?.getAmountsOut)
 const data3 = parseFloat(data2) * 0.5 / 100;
 const data4 = parseFloat(amount?.getAmountsOut) -  data3;
 setminreceived(data4.toFixed(5));
 setisSwap(true)

 sessionStorage.setItem("swaphashlink", isBSC ?  "https://bscscan.com/tx/" : "https://etherscan.io/tx/" )



    if (parseFloat(amount?.getAmountsOut) > parseFloat(result?.balance0)) {
      setExchage(true)
      setisSwap(false)
      setStatus("Insufficient Funds")
      return false;
    } else if (amount?.getAmountsOut == undefined) {
      setExchage(true)
      setisSwap(false)
      setStatus("Pair not found")
      return false;
    }

    else {
      setExchage(false)
      setStatus("Exchange")
    }
   

     } 
  }

  const maxDeposit = async () => {

    if (intokenaddress == "") {
      toast.error("Please select the token", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
    }
    else{
      setInputAmount(balance.toString());
      setismax(true)
      setamountmax(balance.toString())
      await GetTokenPrice(balance.toString())
    }

  }

  const getUserBalance = async () => {
    var temarray = [];
    var UserInfo = await UsergetInfobyname(UserName);
    setUserInfo(UserInfo?.data?.Holding);
    setuserWalletaddress(UserInfo?.data?.walletaddress);
    var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
      if (item.type === "token") {
        var dat = await UsetokeninfoBySymbolandchainid(item.symbol,item.chainId)
        dat['data']['Balance'] = item
        temarray.push(dat['data'])
        // return dat['data']
      }
    }))

    console.log("temarraytemarray",temarray);
    setcoindata(temarray)

    hideLoad()
  }

  const getBnb = async () => {
    
      if(userWalletaddress != "" && userWalletaddress != null){

        if(isBSC){
        await getBNBBlance(userWalletaddress).then((data) => {
          setBnbBalance(data)
        })
      }else{
        await getETHBlance(userWalletaddress).then((data) => {
          setBnbBalance(data)
        })
      }
    
    }
  }


  const handleChange = async (value, tokenaddress,chainId, type) => {
    setInputAmount(0)

  
  
     
      console.log("searchedBalanceValueExchange" , UserInfo);
      const searchData = await searchedBalanceValueExchange(UserInfo,value,chainId);
      setBalance(searchData[0]?.amount ? searchData[0]?.amount : 0)
      console.log("searchData[0]?.amount",searchData[0]?.amount);
      if ( searchData[0]?.amount < 0 || (parseFloat(InputAmount) > balance) ||searchData[0]?.amount == undefined ) {
        setInputAmount(0)
        isExchange(false)
        return false
     
      } 
      // console.log("InputAmount,",InputAmount,balance);
      //  if ( parseFloat(InputAmount) < balance) {
      //   toast.error("You don't have enough balance", {
      //     position: position.position,
      //     style: style,
      //     iconTheme: iconTheme,
      //   })
      //   setisSwap(false)
      //   setExchage(true)
      //    return false;
      // } 
      setBridge(Number(searchData[0]?.amount) <= 0 ? true : false)
      if (type === "token") {
        if(isBSC){
          await checkAllowance(tokenaddress, inToken.symbol);
          // var tokeninfo = await UseTokenInfo(tokenaddress);
        }else{
          await checkAllowance(tokenaddress, inToken.symbol);
          // var tokeninfo = await UseTokenInfoEth(tokenaddress);
        }

       

      

     


    }

  }

  const SecondhandleChange = async (value,tokenAddress,chainId,type) => {
    // if (  InputAmount < secondbalance) {
      setoutAmount(0)

    //   toast.error("You don't have enough balance", {
    //     position: position.position,
    //     style: style,
    //     iconTheme: iconTheme,
    //   })
    //   return false
   
    // }
   

      const searchData = await searchedBalanceValueExchange(UserInfo, value,chainId);
      setsecondbalance(searchData[0]?.amount ? searchData[0]?.amount : 0);
      if ( searchData[0]?.amount < 0 || searchData[0]?.amount == undefined ) {
        setoutAmount(0)
        return false
     
      } 
      if (type === "token") {
        if(isBSC){
          await checkAllowance(tokenAddress, inToken.symbol);
          // var tokeninfo = await UseTokenInfo(tokenAddress);
        }else{
           await checkAllowance(tokenAddress, inToken.symbol);
        // var tokeninfo = await UseTokenInfoEth(tokenAddress);

        }

      }
      
    // }

  }


  const approve = async () => {
    if (userWalletaddress && intokenaddress) {

      const approve = {
        userWalletaddress: userWalletaddress,
        tokenaddress: intokenaddress,
        network : isBSC ? "BSC" : "ETH"


      };
      await UseApprove(approve).then((data) => {

        setIsApproved(true);

      })
      // approveContractRouter(userWalletaddress,intokenaddress);
    } else {
      toast.error("select tokens first", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
    }



  }



  const checkAllowance = async (tokenAddress, tokenSymbol) => {

    var adminwalletaddress = await getAdminWallet()
    var adminaddress = isBSC ? adminwalletaddress?.data?.AdminwalletBSC : adminwalletaddress?.data?.AdminwalletETH;
    if (tokenSymbol != "BNB" || inToken.type === "token") {

      if (isBSC) {
        await checkIsApprovedRouter(adminaddress, tokenAddress).then((data) => {


          if (data == true) {
            setIsApproved(true);
          } else {
            setIsApproved(false);
          }
        })

      } else {
        await checkIsApprovedRouterETH(adminaddress, tokenAddress).then((data) => {


          if (data == true) {
            setIsApproved(true);
          } else {
            setIsApproved(false);
          }
        })
      }


    } else {

      setIsApproved(true);
    }
  }

 



  var audio = new Audio(pikachu)
  const Onswap = async () => {
    audio.play()



    const Swapamount = {

      inputToken: isTo  ? outToken?.symbol : inToken?.symbol,
      outputToken: isTo ? inToken?.symbol : outToken?.symbol,
      inputAmount: isTo ? outAmount :  InputAmount,
      user_name: UserName,
      outputAmount: isTo ? InputAmount : outAmount,
      inTokenAddress: isTo ? outtokenaddress : intokenaddress,
      outtokenaddress: isTo ?  intokenaddress : outtokenaddress,
      fee: fee,
      network : isBSC ? "BSC" : "Eth"

    };

    setStatus("Pending...")
 
 var result = await UseSwap(Swapamount);
 if(result?.data?.success === true && result?.data?.status === 200){

    setSwapmodal(true)
 }

    
     
};


  return (
    <>
      {exchangehint && <ExchangeHint onDismiss={() => setExchangehint(false)} />}
      {bridgeShow && <Bridgemodal onDismiss={() => setBridgeshow(false)} />}
      {swapmodal && <Swapmodal onDismiss={() => setSwapmodal(false)} />}
     

      <div className='dashboard acquire'>
        <div className='bggradleft'></div>
        <div className='bggradright'></div>

        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /></div>
          <div className='content exchange'>
            {showloader?
            <div className='loader_overlay'>
            <div className='loader_div'>
              <img src={loaderimg} className='img-fluid loader_logo' />
            </div>
          </div>
          :
          <></>
            }
            
            <div className='container-fluid container-theme p-4 position-relative'>
              <div className='row'>
              <div className='mb-4 deposit_topbtns'>
                                
                                <h2 className='headfont'>Exchange</h2>
                                              <Button className="btn btn-theme deposit_btns" onClick={() => setExchangehint(true)}>Hint</Button>
              
                                          </div>
                <div className='col-xxl-5 col-xl-6 col-lg-8 col-md-12 mt-0'>

                  {/* <div className='heqding_drop_flex mb-4 dd_inpout_desi'>
                  
                    

                  </div> */}
                </div>
              </div>

              <div className='conts'>

                <div className='row jc-center exchange_sec_nut'>
                  <div className='col-12 col-md-7 col-lg-7 mt-0'>
                    <div className='d-flex justify-content-end  align-items-center heqding_drop_flex mb-4 dd_inpout_desi'>

                      <DropdownButton variant="outline-secondary" className='choosenet' title={<>
                        {network}
                      </>}
                        id="input-group-dropdown-3" align="end"  >

                        <Dropdown.Item onClick={() => { setisBSC(true); setnetwork("BSC") }}>
                          BSC
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => { setisBSC(false); setnetwork("ETH") }}>
                          ETH
                        </Dropdown.Item>




                      </DropdownButton>
                    </div>
                    <h5 className='mb-0 exchagehead'>Choose currency you would like exchange</h5>
                    <div className='card card_cutout mt-3 card_cutout_exch'>

                      <div className='rightinput dd_inpout_desi'>
                        {/* <InputGroup className=""> */}
                        <div className='row'>
                          <div className='col-12 col-lg-8 input_ex_des'>

                            <div className='input-group input_grp_with_btu'>
                              <Form.Control aria-label="Text input with dropdown button"
                                required
                                // value={InputAmount}
                                value={ismax ? amountmax : InputAmount}
                                onChange={(e) => { setisTo(false);setInputAmount(e.target.value); setismax(false); GetTokenPrice(e.target.value) }} placeholder='you have'
                              />

                              <div className='input-group-append append_no_tran'>
                                <button onClick={() => { setismax(true); maxDeposit() }} className='btn btn_max'>Max</button>
                              </div>

                            </div>



                            <p>Balance: {balance} </p>

                          </div>
                          <div className='col-12 col-md-4 dropdwo_height_Scrol'>
                            <DropdownButton variant="outline-secondary" title={tokenImage == "" ? <>
                              <img src={check} className='check_img_resize'></img> {inToken.symbol}</> :
                              <><img src={backurl + `/Images/${tokenImage}`} />
                              {!isBSC && inToken.symbol === "ZARCEth" ? "ZARC" : inToken.symbol}
                               {/* {inToken.symbol} */}
                               </>
                            }

                              // <><img src={backurl + `/Images/${tokenImage}`} /> {inToken.symbol}</>


                              id="input-group-dropdown-2" align="end"  >

                              {isBSC ? <>

                                {Tokeninfo && Tokeninfo.map((item) => {
                                  return (

                                    (item.type == 'token' && item.chainId == '56' && item.isEnabled) || (item.type == 'crypto' && item.chainId == '56' && item.isEnabled) ?
                                      <>

                                        {

                                          (
                                            <Dropdown.Item disabled={outToken?.symbol == item.symbol ? true : false } onClick={(e) => { setFiat({ 'type': item.type }); setintoken({ 'type': item.type, 'symbol': item.symbol }); settokenimage(item.logoURI); handleChange(item.symbol,item.tokenAddress,item.chainId,item.type); setintokenaddress(item.tokenAddress); }}> <img src={backurl + `/Images/${item.logoURI}`} />{item.symbol}</Dropdown.Item>
                                          )
                                        }
                                      </> : <></>
                                  )

                                }

                                )}  </> : <> {Tokeninfo && Tokeninfo.map((item) => {
                                  return (

                                    (item.type == 'token' && item.chainId == '1' && item.isEnabled) || (item.type == 'crypto' && item.chainId == '1' && item.isEnabled) ?
                                      <>

                                        {

                                          (
                                            <Dropdown.Item disabled={outToken?.symbol == item.symbol ? true : false } onClick={(e) => { setFiat({ 'type': item.type }); setintoken({ 'type': item.type, 'symbol': item.symbol }); settokenimage(item.logoURI); handleChange(item.symbol,item.tokenAddress,item.chainId); setintokenaddress(item.tokenAddress); }}> <img src={backurl + `/Images/${item.logoURI}`} />{item.symbol === "ZARCEth" ? "ZARC" : item.symbol}</Dropdown.Item>
                                          )
                                        }
                                      </> : <></>
                                  )

                                }

                                )} </>}

                            </DropdownButton>
                          </div>
                        </div>
                       

                      </div>





                    </div>

                    <div className='text-center exbtn '>
                     
                      <span className='fa fa-exchange text-center'></span>
                    </div>


                    <div className='card card_cutout  card_cutout_exch'>
                      <div className='rightinput inp exchange_sec_nut'>
                        <div className='row'>
                          <div className='col-12 col-lg-8 input_ex_des'>

                            <Form.Control aria-label="Text input with dropdown button"
                              required
                              // readOnly
                              value={outAmount}
                              placeholder='you will receive (approximately)'
                              onChange={(e) => {  setisTo(true);setoutAmount(e.target.value); setismax(false); sencondGetTokenPrice(e.target.value) }} 

                            />


                            <p>Balance: {secondbalance} </p>
                            <p></p>
                          </div>
                          <div className='col-12 col-md-4 dd_inpout_desi dropdwo_height_Scrol'>
                            <DropdownButton variant="outline-secondary"
                              title={tokenoutImage == "" ? <>
                                <img src={check} className='check_img_resize'></img> {outToken.symbol}</> :
                                <>
                                  <img src={backurl + `/Images/${tokenoutImage}`} />
                                  {!isBSC && outToken.symbol === "ZARCEth" ? "ZARC" : outToken.symbol}
                                  {/* {outToken.symbol} */}
                                   </>
                              }
                              //  title={outToken.symbol}
                              id="input-group-dropdown-2" alFDFign="end"  >
                              {isBSC ? <>
                                {Tokeninfo && Tokeninfo.map((item) => {

                                  return (
                                    (item.type == 'token' && item.chainId == '56' && item.isEnabled) || (item.type == 'crypto' && item.chainId == '56' && item.isEnabled) ?
                                      <Dropdown.Item disabled={inToken?.symbol == item.symbol ? true : false } onClick={(e) => {setCrypto({ 'type': item.type }); setouttoken({ 'type': item.type, 'symbol': item.symbol }); settokenoutimage(item.logoURI); SecondhandleChange(item.symbol,item.tokenAddress,item.chainId,item.type); setouttokenaddress(item.tokenAddress) }}> <img src={backurl + `/Images/${item.logoURI}`} />{item.symbol}
                                      </Dropdown.Item> : <></>)

                                }


                                )
                                }</> : <> {Tokeninfo && Tokeninfo.map((item) => {

                                  return (
                                    (item.type == 'token' && item.chainId == '1' && item.isEnabled) || (item.type == 'crypto' && item.chainId == '1' && item.isEnabled) ?

                                      <Dropdown.Item disabled={inToken?.symbol == item.symbol ? true : false } onClick={(e) => { setCrypto({ 'type': item.type }); setouttoken({ 'type': item.type, 'symbol': item.symbol }); settokenoutimage(item.logoURI); SecondhandleChange(item.symbol,item.tokenAddress,item.chainId,item.type); setouttokenaddress(item.tokenAddress); }}> <img src={backurl + `/Images/${item.logoURI}`} />{item.symbol === "ZARCEth" ? "ZARC" : item.symbol}
                                      </Dropdown.Item> : <></>)

                                }


                                )
                                }</>}


                            </DropdownButton>
                          </div>
                        </div>
                      </div>


                      {/* <div className='text-center exbtn '><span className='fa fa-exchange text-center'></span></div> */}


                    </div>

                    {/* <button onClick={()=>{setSwapmodal(true)}}></button> */}

                    <div className='text-center mt-3 mb-3'>
                      {fiat && fiat.type == 'token' ?
                        <>
                          {(isApproved) ? <>
                            {InputAmount > 0 && inToken?.symbol != outToken?.symbol ?
                              <button className='btn btn-theme' id="swapbtn" disabled={isPending || isExchange && isExchange} onClick={async()=> {setisPending(true) ;await Onswap();setisPending(false)}}>{status}</button> :
                              <button className='btn btn-theme' disabled={isExchange && isExchange}><span className='btn_max_text'>{status}</span></button>}
                          </> : <>
                            <button className='btn btn-theme' disabled={inToken.symbol == "" || outToken.symbol == ""}

                              id="swapbtn" onClick={approve}>Approve</button></>}
                        </> : <>
                          {InputAmount > 0 && inToken?.symbol != outToken?.symbol?
                            <button className='btn btn-theme' id="swapbtn" disabled={isPending || isExchange && isExchange} onClick={async()=> {setisPending(true) ;await Onswap();setisPending(false)}}>{status}</button> :
                            <button className='btn btn-theme' disabled={isExchange}>{status}</button>}</>
                      }

                      {bridge && parseFloat(balance) <= 0 ?
                        <Link to="/bridge" className='btn btn-theme ms-3'
                        >
                          <span style={{ minWidth: "64px", display: "block" }}>Bridge</span>
                        </Link> : <> </>}

                    </div>


          

                  
                  </div>



                  <div id="tokenBalance" className='col-12 col-md-5 col-lg-5 mt-0'>

                    {isSwap ? 
                  <div className='card card_cutout mb-3 pt-3 card_cutout_exch'>
                      <h4 className='font_new_profbig mb-4'>Swap Details</h4>

                          <div className='row swp_deta_fort'>
                            <div className='col-xl-12 mb-2'>
                              <div className='d-flex gap-2'>
                                <p className='mb-0 min_width_bank_column'>Minimum Received : </p>
                                <span className='textaqua_blue ms-1'>{minreceived} {isTo ? inToken?.symbol : outToken?.symbol}</span>
                              </div>
                            </div>

                            

                            <div className='col-xl-12 mb-2'>
                              <div className='d-flex gap-2'>
                                <p className='mb-0 min_width_bank_column'>Trading Fee : </p>
                                <span className='textaqua_blue ms-1'>{parseFloat(isTo ? outAmount :  InputAmount) * (parseInt(fee) / 10000) / 100} {isTo ? inToken?.symbol : outToken?.symbol}</span>
                              </div>
                            </div>

                            <div className='col-xl-12 mb-2'>
                              <div className='d-flex gap-2'>
                                <p className='mb-0 min_width_bank_column'>Route : </p>
                                <span className='textaqua_blue ms-1'>{isTo  ? (outToken?.symbol=== "ZARCEth" ? "ZARC" :  outToken?.symbol) : inToken?.symbol === "ZARCEth" ? "ZARC" : inToken?.symbol}  
                                 <i className='fa fa-chevron-right'></i>{isTo ? (inToken?.symbol === "ZARCEth" ? "ZARC" : inToken?.symbol) : (outToken?.symbol=== "ZARCEth" ? "ZARC" :  outToken?.symbol)}</span>
                              </div>
                            </div>
                          </div>
</div> : <></> }
                    <div className='card card_cutout mb-3'>
                      <div className='tabtable nohead potfolio tablescroll themescroll theme_scrol_mr scroll_data_table_exc'>
                        <DataTable columns={columns} data={coindata} />
                      </div>
                    </div>
             
                  </div>
                </div>
                <div className='row jc-center'>
                  <div className='col-xxl-9'>
                    <div className='mt-4'>

                      <div className='notecont py-0'>
                        <p className='pos_ab_note'>Important</p>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <div className='py-4 text-center'>
                              <li className='mt-2'> <p className='text-left'>
                                <img src={check} />
                                <span>Please allow a few minutes for any deposited funds to appear on the platform, as it may take time for them to reflect based on the blockchain verification.</span></p></li>
                              <li className='mt-2'>
                                <p className='text-left'><img src={check} />
                                  <span>

                                  To bridge your ZARC tokens on another network, click on the 'Bridge' button.</span></p></li>
                            </div>
                          </div>


                        </div>

                      </div>

                      {/* <p className='note_sec_p_new'>
                        <b>Note: </b>
                        <span><span className='ps-2'>Fiat </span>- USD, ZAR, YEN</span>
                        <span> <span className='ps-2'>Crypto Coin </span> - ZARC, BTC, ETH, USDT, BNB.</span>
                      </p> */}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Exchange
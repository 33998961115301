import {backurl} from '../../config/env';
import axios from "axios";
import * as config from "../../config/env";
import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from '../UseToast'
import Header from '../../Components/Header';

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))



 


   export const UserTransactions = async(userid)=>{
      const userinfo =  await   axios.get(`${backurl}/api/user/transactions/${userid}`)
      return userinfo.data;
}
export const UserFiatTransactions = async(userid)=>{
   const userinfo =  await   axios.get(`${backurl}/api/user/fiatstatus/${userid}`)
  
   return userinfo.data;
}



export const UsergetInfobyname = async(UserName) => {
   const token = sessionStorage.getItem('Token');
   const userinfo =  await axios.get(`${backurl}/api/user/infobyname/${UserName}`,{headers: {authorization : `Bearer ${token}`} })
   return userinfo.data;
}

export const UserGetByName = async(UserName) => {
   const userinfo =  await axios.get(`${backurl}/api/user/getUserDetails/${UserName}`)
   return userinfo.data;
}

export const UsetokeninfoBySymbol = async(tokenid) => {
   
   const tokeninfo =  await   axios.get(`${backurl}/api/token/info/${tokenid}`)
         return tokeninfo.data;
}
export const UsetokeninfoBySymbolandchainid = async(symbol,chainId) => {
   
   const tokeninfo =  await   axios.get(`${backurl}/api/token/symbol/${symbol}/${chainId}`)
         return tokeninfo.data;
}



export const UsegetLimitNotification = async(user_name) => {
   
   const tokeninfo =  await   axios.get(`${backurl}/api/user/limitnotification/${user_name}`)
         return tokeninfo.data;
}
export const UseUpdateLimitNotification = async(user_name) => {
   
   const tokeninfo =  await   axios.get(`${backurl}/api/user/updateLimitnotification/${user_name}`)
         return tokeninfo.data;
}
export const UsegetAllNotification = async(user_name) => {
   
   const tokeninfo =  await   axios.get(`${backurl}/api/user/allnotification/${user_name}`)
         return tokeninfo.data;
}


export const getTokeninfo = async() => {
    
   const Tokeninfo =  await   axios.get(`${backurl}/api/asset/details`)
         return Tokeninfo.data;
}
   

export const getTokenPrice = async() => {
    
   const TokenPrice =  await   axios.get(`${backurl}/api/getTokenPrice`);
   return TokenPrice?.data;
}


export const GetUseAmountsOutPerToken = async(intoken,outtoken,Amount) => {
    
   const Tokenamountpertoken =  await axios.get(`${backurl}/api/exchange/${intoken}/${outtoken}/${Amount}`)
 
   return Tokenamountpertoken?.data;
  
}


export const Userdatetransaction = async (from, to , userid) => {
   // console.log('to---------->',to.split('-'))19-20-2022
   console.log('from',from,"to",to.split('-'),'to1',Number(to.split()[0]));
   if (from.length > 0 && to.length > 0  && Number(to.split('-')[0])> 1950 ) {
      if (from <= to) {
         let payload = {
            startdate: from,
            enddate: to,
            type: "both",
            userid : userid
         };
         var formdata = new FormData();
         formdata.append("type" , "both");
        formdata.append("startdate" , from);
        formdata.append("enddate" , to);
        formdata.append("userid" , userid);
         console.log("paayload", payload);
         const userinfo = await axios.post(config.backurl + '/api/user/transactiondates', formdata);
         console.log("userinfo", userinfo);
         return userinfo;
      }
      else {

         toast.error("You must select to date greater than or equal to from date !..", {
            position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
         // window.alert("You must select to date greater than or equal to from date !..");
         //setTo('');
      }
   }

   if (from?.length > 0 && !to?.length) {
      let payload = {
         startdate: from,
         type: "from",
         userid : userid
      };
      var formdata = new FormData();
      formdata.append("type" , "from");
     formdata.append("startdate" , from);
     formdata.append("enddate" , to);
     formdata.append("userid" , userid);
      console.log("paayload", payload);
      const userinfo = await axios.post(config.backurl + '/api/user/transactiondates', formdata);
      console.log("userinfo", userinfo);
      return userinfo;
      // await filterguestuserhook(payload).then((result)=>{
      //     console.log("filter guest user hook result" , result);
      //     result?.data?.record?.map((data,i)=>data.serial = i+1)
      //     setGuestuser(result?.data?.record);
      // })
   } else {
      console.log("err");

   }

   if (!from?.length && to?.length > 0) {
      let payload = {
         enddate: to,
         type: "to",
         userid : userid
      };
      var formdata = new FormData();
      formdata.append("type" , "to");
     formdata.append("startdate" , from);
     formdata.append("enddate" , to);
     formdata.append("userid" , userid);
      console.log("paayload", payload);
      const userinfo = await axios.post(config.backurl + '/api/user/transactiondates', formdata);
      console.log("userinfo", userinfo);
      return userinfo;
   } else {
      console.log("err");

   }
}



export const UseBankActive = async() => {
   
   const tokeninfo =  await   axios.get(`${backurl}/api/getactivebank`)
         return tokeninfo.data;
}


export const GetFiatToCrypto = async(intoken,outtoken,Amount) => {
  
   const Tokenamountpertoken =  await axios.get(`${backurl}/api/fiattocrypto/${intoken}/${outtoken}/${Amount}`)
 
   return Tokenamountpertoken?.data;
  
}



export const GeCryptoToFiat = async(intoken,outtoken,Amount) => {
  
   const Tokenamountpertoken =  await axios.get(`${backurl}/api/cryptotofiat/${intoken}/${outtoken}/${Amount}`)
 
   return Tokenamountpertoken?.data;
  
}

export const getAllFeeInfo = async ()=>
{

   
 const Bankinfo = await  axios.get(`${backurl}/api/admin/user/getFee`);

 return Bankinfo.data;
}






export const getTokenBalance = async ()=>
{

 try{
       const Bankinfo = await  axios.get(`${backurl}/api/solanatoken/info`);
      

       return Bankinfo?.data?.data;
 }catch(e){
       
 }

}


export const getAdminWallet = async ()=>
{
 const Bankinfo = await  axios.get(`${backurl}/api/admin/wallet`);

 return Bankinfo.data;
}

import React, { useState,useEffect } from 'react';
import {Modal, DropdownButton, Dropdown, Tabs, Tab, Row, Col, Nav} from 'react-bootstrap'
import { UseBankActive, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBankValue } from '../../Hooks/UseValidation';
import TransferedFunds from '../Modals/TransferedFunds';
import * as config from "../../config/env";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import bridge from '../../Assets/Images/hint/bridge.png'




const options= {
  responsive:{
    0: {
        items: 1,
    },
    450: {
        items: 1,
    },
    600: {
        items: 1,
    },
    1000: {
        items: 1,
    },
},
}

const ExchangeHint = (props) =>{
   const[bridgehint, setbridgehint] = useState(true)
 
   
   const skip  = () =>{
    localStorage.setItem("bridgehintpage",1)
  }


  

   return(
    <>
    <Modal size="lg" show={bridgehint}  id="bridgehintopup" onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big_hint'>
    {/* {true && <TransferedFunds bankdata={SearchCoinData && SearchCoinData} refcode={props.refcode} onDismiss={() => setFunds(false)} />} */}
      <Modal.Header className='border-0 pb-0'>
     
           {/* <button type="button" className="btn close close_modal ms-auto text-white"><span aria-hidden="true">×</span><span class="sr-only"></span></button> */}
      </Modal.Header>
      <Modal.Body className='px-sm-5 p-3 themescroll'>
       
      <div className='mb-4 content viewdetails'>
      
       
        
        <h3 className='headfont text-center'>Bridge</h3>
        <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <img src={bridge} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>·	In the first tab, input your current ZARC balance on your network (e.g., ZARC (ETH)). In the second tab, choose the network where you want to exchange ZARC (e.g., ZARC (SOL)). To complete the transaction, simply click the "bridge" button. Your updated balance and network will be displayed on the right-hand side of the bridge tab.</p>
    </div>
</div>



</OwlCarousel>
</div>
<div className='text-center mt-4'>
          <button className="btn btn-theme" onClick={() => {props.onDismiss();skip()}}>Skip Hints</button>
          </div>
        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default ExchangeHint
import React, { useState } from 'react';


import dashboard from '../Assets/Images/dashboard.png'
import redeem from '../Assets/Images/deposit.png'
import deposit from '../Assets/Images/exchange.png'
import withdraw from '../Assets/Images/withdraw.png'
import acquire from '../Assets/Images/transaction.png'
import history from '../Assets/Images/profile.png'
import neo from '../Assets/Images/neonomad.png'
import notification from '../Assets/Images/notification.png'
import logout from '../Assets/Images/logout_icon.png'
import contact_icon from '../Assets/Images/contact_icon.png'
import Bridgemodal from './Modals/Bridgemodal';
import { Link, NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Sidebar = () =>{
    const[bridgeShow, setBridgeshow] = useState(false)
    const optionsnew = {
        autoplay:true,
        autoplaySpeed:1000,
        autoplayHoverPause:true,
         loop:true, 
         margin:10,
         dots:false
      }
    // const addactive = (e) =>{
    //     e.target.parentNode.parentNode.classList.add("active");
    // }

    return(
        <>
        {bridgeShow && <Bridgemodal onDismiss={() => setBridgeshow(false)}  />}
        <ul className='pt-4 sidebarlist'>
            <li><NavLink  to="/dashboard"><button><img src={dashboard} className="me-2"/> Dashboard</button></NavLink></li>
            <li><NavLink to="/deposit"><button> <img src={redeem} className="me-2"/>Deposit</button></NavLink></li>
            <li><NavLink to="/withdraw"><button> <img src={withdraw} className="me-2"/>Withdraw</button></NavLink></li>

            <li><NavLink to="/exchange"><button><img src={  deposit} className="me-2"/>Exchange</button></NavLink></li>
            <li><NavLink to="/bridge"><button><img src={  deposit} className="me-2"/>Bridge</button></NavLink></li>

            <li><NavLink to="/transaction"><button><img src={ acquire } className="me-2"/>Transaction</button></NavLink></li>
            <li><NavLink to="/profile"><button><img src={history } className="me-2"/>Profile</button></NavLink></li>
            <li className='showin767'><NavLink to="/notification"><button><img src={notification } className="me-2"/>Notification</button></NavLink></li>
           
            <li className='showin767'><NavLink to="/profile"><button>
                <img src={logout} className="me-2"/>Logout</button></NavLink></li>

                <li className='showin767'><a href='https://neonomadhelp.zendesk.com/hc/en-us' target='_blank'><button>
                <img src={contact_icon} className="me-2"/>Contact</button></a></li>
           
            <li className='neonomad w-100'>


            <OwlCarousel className='owl-theme carouse_prof_card' {...optionsnew} items={1} >
                <div className='text-center'>
                <img src={neo} className="neo m-auto" />
                <p className='poppins f-14 mt-3 font_trade_grey'>Start Trading Now</p>
                <div className='text-center'>
                <a href="https://beta-terminal.neonomad.io/" className='btn btn-theme mx-auto' target="_blank">Trade</a>
                </div>
                </div>
                <div className='text-center'>
                <img src={neo} className="neo m-auto" />
                <p className='poppins f-14 mt-3 font_trade_grey'>Start Trading Now</p>
                <div className='text-center'>
                <a href="https://beta-dapp.neonomad.io/" className='btn btn-theme mx-auto' target="_blank">Swap</a>
                </div>
                </div>
                {/* <div className='text-center'>
                <img src={neo} className="neo m-auto" />
                <p className='poppins f-14 mt-3 font_trade_grey'>Start Trading Now</p>
                <div className='text-center'>
                <a href="https://app.neonomad.io/swap/?from=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&to=buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW" className='btn btn-theme mx-md-auto' target="_blank">Swap</a>
                </div>
                </div> */}
                </OwlCarousel>
            </li>
        </ul>
        </>
    )
}

export default Sidebar
import React, { useState ,useEffect} from 'react';
import {Modal, Form, Button,InputGroup} from 'react-bootstrap'
import { UseFiatWithdraw } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { GeCryptoToFiat, UsergetInfobyname, UsetokeninfoBySymbol, getAllFeeInfo } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBalanceValue, searchedBankValue } from '../../Hooks/UseValidation';
import check from './../../Assets/Images/check.png'
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../../Hooks/UseToast'

const FiatWithdrawModal = (props) =>{
   const[fiatwithdraw, setFiatWithdraw] = useState(true)
   const[showtoken , setShowToken] = useState(false)
   const[selected , setSelected] = useState("")
   const [userbankdata,setUserbankdata] = useState([])
   const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
   const [Search, setSearch]   = useState("")
   const [isSearch, setisSearch] = useState(false)
   const [SearchCoinData, SetSearchCoinData] = useState([])
   const [Amount, SetAmount] = useState(0)
   const [password, setpassword] = useState(0)
   const [passwordneweye, setpasswordneweye] = useState(false)
   const [WithdrawFee, setWithdrawFee] = useState("")
   const [userFinalAmount,setuserFinalAmount] = useState(0)
   const [price, setPrice] = useState(0);
   const [coindata, setcoindata] = useState([])
   const [ismax,setismax] = useState(false)
   const [maxamount,setmaxamount] = useState(0)
   const[Network , setNetwork] = useState("")
   const [UserInfo, setUserInfo] = useState({})
   const [adminFee,setadminFee] = useState(0)
   const [isPending,setisPending] = useState(false)
   
   const showNewPassword = (e) => {

    var e = document.getElementById("password");
    if (e.type === "password") {
        setpasswordneweye(true)
        e.type = "text";
    } else {
        setpasswordneweye(false)
        e.type = "password";
    }
};
   useEffect(() => {
  
   getdata();
  }, [])

   const getdata = async() =>{
    var UserInfo  =    await UsergetInfobyname(UserName);
    setUserInfo(UserInfo?.data?.Holding)
    setUserbankdata(UserInfo?.data?.BankAccount);
    var feedata =  await getAllFeeInfo();
    setWithdrawFee(parseFloat(feedata?.data[0]?.withdrawFee) > 0 ? feedata?.data[0]?.withdrawFee : 0);

    var UserInfo  =    await UsergetInfobyname(UserName);
   

    const searchData = await searchedBalanceValue(UserInfo?.data?.Holding, "ZARC");
    
    setmaxamount(parseFloat(searchData[0]?.amount))
    //   setBalance(searchData[0]?.amount ? searchData[0]?.amount : 0)

    var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
        var dat = await UsetokeninfoBySymbol(item.symbol)
    
        dat['data']['Balance'] = item
        
        return dat['data']
    
    }))
    
    if(tokenSymbol.length > 0){
      setcoindata(tokenSymbol)
     
      
    }
   }


   const Onmaxamount = async (value) => {
    
    
          const searchData = await searchedBalanceValue(UserInfo,value);
          setmaxamount(searchData[0].amount)
                                        
             
  
  }

  const FiatData = async (value) => {
    
    if(maxamount < value){
        toast.error("You don't have enough balance", {
          position: position.position,
          style: style,
          iconTheme: iconTheme,
        })
        setisPending(true)
      }else if(SearchCoinData.length == 0){
        toast.error("Please first select the bank", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          })
          setisPending(true)
      }
      else if(Network== ""){
        toast.error("Please select network", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          })
          setisPending(true)
      }
      
      else{
       
        var tokenprice = await GeCryptoToFiat("ZARC",SearchCoinData.currency, value);
       
         var priceInPercentage = WithdrawFee / 100 * parseFloat(tokenprice?.tokenOut);
         setadminFee(parseFloat(WithdrawFee) == 0 ? 0 : priceInPercentage)
         var userFinalAmount = parseFloat(WithdrawFee) > 0 ? parseFloat(tokenprice?.tokenOut) -  priceInPercentage : tokenprice?.tokenOut;
         
      
         setPrice( parseFloat(userFinalAmount))
         setisPending(false)
    
      }
   
  }

   const onSubmit = async (e) => {

    e.preventDefault();
    const data = {
            
            holdername: SearchCoinData.holdername, 
            currency: SearchCoinData.currency,
            amount:price, 
            isApproved : false,
            bankname: SearchCoinData.bankname, 
            Accountnumber: SearchCoinData.Accountnumber, 
            IFSCcode: SearchCoinData.IFSCcode, 
            branch: SearchCoinData.bankcity,
            ZarcAmount : ismax ? maxamount : Amount,
            adminFee : adminFee,
            withdrawdate: new Date(Date.now()).toDateString()
            
  };
const editprofiledata ={
        user_name : UserName,
        bankdetails :data,
        password:password,
        network:Network === "ZARC" ? "BSC" : Network === "ZARCSol" ?  "Solana":  "ETH",
        
    }
   
    
       await UseFiatWithdraw(editprofiledata)
    }
   const handleChange = async (value) => {
     
    if(value.length > 0){
        
          const searchData = await searchedBankValue(userbankdata,value);
          
        // const searchData =  userbankdata.find((data,i)=>{return (i  == value)?data[0]:[]});
          
                                   SetSearchCoinData(searchData[0] && searchData[0])
                                // document.getElementById("bankdata").classList.remove("blur")       
                                   setisSearch(true)
    }else{
        setisSearch(false)
    }
  
  }

   return(
    <>
    <Modal size="lg" show={fiatwithdraw}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll pb-3'>
       
      <div className='mb-4 '>
        <form onSubmit={onSubmit}>
        <h3 className='headfont mb-5 text-center'>Withdraw ZARC as Fiat</h3>

        <div className='notecont py-0'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-12'>
                            <div className='py-4 flkex_cent_notes'>
                        <li className='mt-2'> <p className=''>
                            <img src={check} />
                            <span className='mar_top_wirdw'>Widrawal time will take 4-24 hours due to bank Network</span></p></li>
                      
                                </div>
                        </div> 
                       

                    </div>
                   
                </div>
        
                <div className='row mt-4'>
            <div className='col-12 col-md-6 mb-3'>
            <div className='w-full '>
        

        <p className='mb-0 headfont mb-3  label_input_font'>Withdraw account</p>
        <div className='withdrawselect showappeare'>
        
            <select className='form-control'required 
            onChange={(e) => { setSearch(e.target.value);
                var index = e.target.selectedIndex;
                var optionElement = e.target.childNodes[index]
                var option =  optionElement.getAttribute('data-id');
                handleChange(option) }}>
                
                <option>{Search ? Search : "Select Bank"}</option>
            {userbankdata && userbankdata.map((item) =>
                <option data-id={item.bankname} onClick={(e)=>{setSearch(item.bankname);handleChange(item.bankname)}}>{item.bankname}</option>
            )}
            </select>
            <span className='fa fa-chevron-down text-dark'></span>
         
        </div>

  </div>
            </div>


            <div className='col-12 col-md-6'>
            <div className='w-full'>
                    <p className='mb-0 headfont mb-3 label_input_font sel_net_font mt-0'>Select Network</p>
                    <div onClick={() => setShowToken(!showtoken)} className='wizardinput position-relative selectbox'>
                        {console.log("Network",Network)}
                        <span className='d-inline-block sh_span_margi'>{Network == "ZARC" ? "BSC" : Network == "ZARCSol" ? "Solana" :  Network == "ZARCEth" ? "Eth" : "Select Network "}</span>
                       <Button variant="link"  className="nounder text-light"><spna className={showtoken ? "fa fa-chevron-up" :"fa fa-chevron-down"}></spna> </Button> 
                       <div className={showtoken ? 'selecttoken' : 'd-none'}>
                            <Form.Check  onChange={(e) => {Onmaxamount("ZARC"); setNetwork ("ZARC")}} type="radio" name="token" value="ZARC"label="BSC"/>
                            <Form.Check  onChange={(e) => {Onmaxamount("ZARCSol");setNetwork ("ZARCSol")}} type="radio" name="token" value="ZARCSol"label="Solana" />
                            <Form.Check  onChange={(e) => {Onmaxamount("ZARCEth");setNetwork ("ZARCEth")}}type="radio" o name="token" value="ZARCEth"label="Eth" />
                        </div> 
                    </div>
        <p  className='mb-0 lightgreen f-12 text-right mt-2'>Withdraw Fee - {WithdrawFee} %</p>

                </div>

            </div>
           
            <div className='col-12 col-md-6 mb-3'>
                 
          <div className='w-full'>
                <p  className='mb-0 headfont mb-3  label_input_font'>Amount to withdraw in ZARC</p>
                <div className='wizardinput'>
                <div className='input-group input_grp_with_btu'>

                    <input type="text" 
                    required
                    value={ismax ? maxamount : Amount}
                     onChange={(e) => {setismax(false); SetAmount(e.target.value);FiatData(e.target.value)}}
                     className='form-control' placeholder='Amount'/>
                
                      <div className='input-group-append append_no_tran'>
                                                    <button onClick = {() => {setismax(true);setmaxamount(maxamount) ; FiatData(maxamount)}}className='btn btn_max'>Max</button>
                                                </div>
                                                </div>
                </div>
            </div>
            { coindata && coindata.length > 0 ?
                    <>{coindata && coindata.map((item) =>
                       
                        
                        (item.Balance.symbol === Network ? 
                <p className='lightgreen f-12 text-right mt-2 mb-1'>Zarc Balance { Network ? (item.Balance.amount).toFixed(3) : 0 } </p> 
                 : <>
                 </>)
                 )}</> :<><span className='lightgreen f-12'>ZARC 0</span></> }
            <p  className='lightgreen f-12 text-right mt-2 mb-1'>You will receive {price.toFixed(7)} {isSearch ? SearchCoinData && SearchCoinData?.currency : ""}</p>

            </div>

            <div className='col-12 col-md-6 mb-3'>
            <div className='w-full '>
                <p  className='mb-0 headfont mb-3  label_input_font'>Withdraw Account Number</p>
                <div className='wizardinput'>
                    <input required type="text" value={isSearch ? SearchCoinData && SearchCoinData?.Accountnumber : ""}className='form-control' placeholder='Account'/>
                    <span><i>{isSearch ? SearchCoinData && SearchCoinData?.currency : ""}</i></span>
                </div>
          </div>

            </div>

            <div className='col-12  mb-3'>

            <div className='w-full '>
                <p  className='mb-0 headfont mb-3  label_input_font'>Login Password</p>
                <div className='wizardinput'>
                <InputGroup className="mb-3">
                    <Form.Control
                     placeholder='Login Password' 
                     type="password" 
                     required 
                     onChange={(e) => { setpassword(e.target.value)}}
                     id="password"
                     
                      className='form-control' />
                     {!passwordneweye ?
                    <Button id="basic-addon2"><i className='fa fa-eye-slash'onClick={showNewPassword}></i></Button> :
                    <Button id="basic-addon2"><i className='fa fa-eye'onClick={showNewPassword}></i></Button>}
                  </InputGroup>
                    
                </div>
          </div>


          
              
            </div>
            </div>

     
    
     
      

       <div className='w-full mt-lg-0 mt-0'>

{Amount > 0 ?

<button className='btn btn-theme w-full mt-0' disabled={isPending} onClick={() => {props.open(); props.onDismiss()}}>Withdraw</button> :
<button className='btn btn-theme w-full mt-0'disabled={true}>Withdraw</button>
}

</div>
        
      

</form>
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default FiatWithdrawModal
import React,{useState,useEffect} from 'react';
import { Button,Dropdown, Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'
import { UserFiatTransactions, UsergetInfobyname, UserTransactions } from '../Hooks/UseBackendAction/UseGetController';
import { searchedValue } from '../Hooks/UseValidation';
import { Link } from 'react-router-dom';


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const History = () =>{
  const [username, setusername] = useState(sessionStorage.getItem("username"))
  const [records, setRecords] = useState([])
  const [Search, setSearch]   = useState("")
  const [isSearch, setisSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData]      = useState([])
  const columns = [
    {
        name: "S.No",
        selector: (vals,index) => index + 1,
        sortable: true
      },
    {
      name: "Currency",
      selector: (vals) => vals.currency,
      sortable: true
    },

    {
      name: "Deposit Amount",
      selector: (vals) => vals.amount,
      sortable: true
    },
    {
      name: "Zarc Amount",
      selector: (vals) => parseFloat(vals.ZarcAmount).toFixed(10),
      sortable: true
    },
    {
      name: "Status",
      selector: (vals) => (vals.isApproved == null ?
        <Button variant='outline-secondary' className='samewidth'>Pending</Button> : vals.isApproved == true ?
          <Button variant='outline-success' className='samewidth'>Completed</Button> : <Button variant='outline-danger' className='samewidth'>Canceled</Button>),
      sortable: true
    },

    {
      name: "Date",
      selector: (vals) => ((vals.updatedAt.substring(0,10))),
      sortable: true
    }


  ]

useEffect(() => {
  Getdata();
},[]) 


const handleChange = async (e) => {
        
    if((e.target.value).length > 0){
          const searchData = await searchedValue(records,e.target.value);
          
         
                                   SetSearchCoinData(searchData)
                                  
                                   setisSearch(true)
    }else{
        setisSearch(false)
    }
  
  }

const Getdata = async () => {
       
  var UserInfo  =    await UsergetInfobyname(username);
  var fiatStatus = await UserFiatTransactions(UserInfo?.data?.userId);


    // setfiatDepositdata(UserInfo.success ? fiatStatus?.data.reverse() : [])
  
  
  if(UserInfo?.success === true){
    setRecords(UserInfo.success ? fiatStatus?.data.reverse() : [])
  };
  
}

return(
<>
 <div className='history dashboard transsec'>
 <div className='bggrad'></div>
 <div className='bggradleft'></div>

    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans transsec'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h2 className='headfont '>Fiat Transaction</h2>
            </div>
            
               <div className='tabtable mt-5 potfolio  darkbox card_cutout  br-6'>
               <Link to="/dashboard" ><button className=' btn btn-outline-secondary px-2'> <span className='fa fa-chevron-left me-2'></span>  Back</button></Link>
                <div className='d-flex jc-between align-items-center mt-4 mb-4'>
                   <h4 className='headfont'>History</h4>
                   <div className='transscroll'>
                   
                   </div>
                   <div className=' themeinput mb-3'>
                    <input type="text"   onChange={(e)=>{setSearch(e.target.value);handleChange(e)}} placeholder="Filter in records by currency" className='form-control mw-300 ms-auto'/>
                </div>
                </div>
             
              {!isSearch ?
                <DataTable pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records}/> : 
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={SearchCoinData}/>}
               </div>
            
            
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default History
import './App.css';
import {BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Dashboard from './Components/Dashboard.js'
import Transaction from './Components/Transaction';
import Exchange from './Components/Exchange';
import Bridge from './Components/Bridge';

import Deposit from './Components/Deposit';
import Withdraw from './Components/Withdraw';


import Landing from './Components/Landing';
import Profile from './Components/Profile';
import Notification from './Components/Notification';
import FiatHistoty from './Components/FiatTransaction';


function App() {
  return (
    <div className="App">
       <div><Toaster /></div> 
      <BrowserRouter>
        <Routes>
        
          <Route path="/login" element={<Landing />} />
          <Route
          path='/'
          element={ <Navigate to="/login" /> }
           />
          {/* <Redirect exact from="/"  to="/login"></Redirect> */}
          <Route path="/register" element={<Landing />} />
          <Route path="/forgotpassword" element={<Landing />} />
          <Route path="/dashboard"  element= {<Dashboard />} />
          <Route path="/reset/:otp"  element={<Landing /> } />
        
          <Route path="/exchange" element={<Exchange />} />
          <Route path="/bridge" element={<Bridge />} />

          <Route path="/deposit" element={<Deposit />} />
          

          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/withdraw" element={<Profile />} /> */}
          <Route path="/withdraw" element={<Withdraw />} />

          <Route path="/transaction" element={<Transaction />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/fiat" element={<FiatHistoty />} />
       
         
 
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;

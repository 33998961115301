import React, { useEffect, useState } from 'react';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap'
// import prof from '../../Assets/Images/prof1.jpg'
import prof from '../../Assets/Images/dprof.png'
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../../Hooks/UseToast'
import { backurl } from "../../config/env";
import { UseEditProfile } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { countryList } from '../../Hooks/UseValidation';

const EditProfile = (props) => {
  const [profile, setProfile] = useState(true)
  const [editprof, setEditProfimg] = useState(prof)
  const [userid, setuserid] = useState(props?.record?.userId);
  const [UserName, setUserName] = useState(props.record.user_name);
  const [FirstName, setFirstName] = useState(props.record.first_name);
  const [LastName, setLastName] = useState(props.record.last_name);
  const [Email, setEmail] = useState(props.record.email)
  const [Password, setPassword] = useState("")
  const [NewPassword, setNewPassword] = useState("")
  const [CurrentPassword, setCurrentPassword] = useState("")
  const [Country, setCountry] = useState(props.record.Country)
  const [imagepath, set_imagepath] = useState("")
  const [image, set_image] = useState(props.record.logoURI)
  const [file, set_file] = useState({})
  const [ImageURL, setImageURL] = useState("")
  const [imagesize, setimagesize] = useState(100)
  const [CountryList, setcountryList] = useState(countryList)


  const getimage = (e) => {
    const files = e.target.files[0];
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        setEditProfimg(this.result);
      });
    }
  }



  const ImageUpload = event => {
    var name = event.target.value;
    set_file(event.target.files[0]);
    var size = Number(event.target.files[0].size);
    if (size > imagesize * 1000) {
      toast.error(`Please select image size less than ${imagesize} KB`, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })

    } else {
      set_imagepath(name);
      var url = URL.createObjectURL(event.target.files[0])
      setImageURL(url)
    }
  };

  const onEditSubmit = async (e) => {

    e.preventDefault();
    const editprofiledata = {
      userId: userid,
      user_name: UserName,
      first_name: FirstName,
      last_name: LastName,
      email: Email,
      currentpassword: Password,
      password: NewPassword,
      Country: Country,
      file: file != {} ? file : imagepath,

    };
    await UseEditProfile(editprofiledata)


  }

  return (
    <>
      <Modal show={profile} centered scrollable className='modal_style thememodal modal_big'>
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
        <Modal.Body>
          <form className='mb-4' onSubmit={onEditSubmit}>
            <div className='d-flex  align-items-center'>
              <div className='editimg'>

                {image != "" ? <>{ImageURL && ImageURL.length > 0 ?
                  <img src={ImageURL} className='round_small_img'/> : <img src={backurl + `/Images/${image}`} className='round_small_img'/>
                }</> : <>{ImageURL && ImageURL.length > 0 ?
                  <img src={ImageURL} className='round_small_img'/> : <img src={editprof} className='round_small_img'/>
                } </>}
                <Button variabnt="link" className='bnt_eidtprof_modal'>
                  <input type="file" accept=".jpg,.jpeg,.png"
                    onChange={ImageUpload}
                  // onClick={(e) => getimage(e)}
                  />
                  <span className='fa fa-pencil'></span></Button>


              </div>
              <div>
                <h5 className='mb-0 headfont'>Profile</h5>
                <p className='text-muted pb-0 mb-0 f-14'>{UserName}</p>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full'>
                <label className='mb-3'>First Name</label>
                <input placeholder='Killer' maxlength="15" value={FirstName} onChange={(e) => { setFirstName(e.target.value) }} className='form-control' />
              </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full'>
                <label className='mb-3'>Last Name</label>
                <input placeholder='Joe' maxlength="15" value={LastName} onChange={(e) => { setLastName(e.target.value) }} className='form-control' />
              </div>
              </div>
              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full'>
                <label className='mb-3'>Email</label>
                <input placeholder='example@gmail.com' value={Email} onChange={(e) => { setEmail(e.target.value) }} className='form-control' />
              </div>
              </div>
              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full'>
                <label className='mb-3'>Country</label>
                {/* <input placeholder='country' className='form-control'/> */}
                <select className='form-control'
                  value={Country}
                  onChange={(e) => { setCountry(e.target.value) }}>
                  <option >{Country != "" ? Country : "Select Country"}</option>
                  {CountryList && CountryList.map((item) =>

                    <option value={item.name}>{item.name}</option>
                  )}
                   </select>
              </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full'>
                <label className='mb-3'>Current Password</label>
                {/* <input placeholder='Password' value={Password} onChange={(e) => { setPassword(e.target.value) }} className='form-control'/> */}
                <InputGroup className='pb-0'>
                  <Form.Control
                    placeholder='Password' value={Password} onChange={(e) => { setPassword(e.target.value) }} className='form-control border_0_inut_right'
                  />
                  <InputGroup.Text className="border_0_inut_grp" id="basic-addon2"><i className='fa fa-eye'></i></InputGroup.Text>
                </InputGroup>
              </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full'>
                <label className='mb-3'>New password</label>
                <input placeholder='new password' value={NewPassword} onChange={(e) => { setNewPassword(e.target.value) }} className='form-control' />
              </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
              <div className='wizardinput w-full mt-0'>
              <label className='mb-3'>Confirm New Password</label>
              <input placeholder='Confirm Password' value={CurrentPassword} onChange={(e) => { setCurrentPassword(e.target.value) }} className='form-control' />
            </div>
              </div>

              <div className='col-12 col-md-6 mb-1 alingbtn_end_prof'>
                
            <div className='text-end w-full'>
              <button className='btn btn-theme btn_big_theme_border mt-0'>Update</button>
            </div>
              </div>
             
             

            </div>
        
          


          </form>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default EditProfile
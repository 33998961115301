import React, { useState,useEffect } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'

import DataTable from 'react-data-table-component';

import coin1 from '../../Assets/Images/coin1.png'
import coin2 from '../../Assets/Images/coin2.png'
import coin3 from '../../Assets/Images/coin3.png'
import coin4 from '../../Assets/Images/coin4.png'
import coin5 from '../../Assets/Images/coin5.png'
import { UseUpdateBAnkdetails, UseUploadBAnkdetails } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import {currency_list,countryList, validateBankDetails} from "../../Hooks/UseValidation"


const UpdateAccountDetails = (props) =>{
   const[updateaccountdetails, setupdateAccountDetails] = useState(true)
   const[showtoken , setShowToken] = useState(false)
   const[selected , setSelected] = useState("")
   const [currencyList,setcurrencyList] = useState(currency_list)
   const [Country,setCountry] = useState(props?.record?.CountryList)
   const [currency,setcurrency] = useState(props?.record?.currency)
   const [holdername,setholdername] = useState(props?.record?.holdername)
   const [bankname,setbankname] = useState(props?.record?.bankname)
   const [Accountnumber,setAccountnumber] = useState(props?.record?.Accountnumber)
   const [IFSCcode,setIFSCcode] = useState(props?.record?.IFSCcode)
   const [CountryList,setcountryList] = useState(countryList)
   const [bankaddress,setbankaddress] = useState(props?.record?.bankaddress)
   const [bankcity,setbankcity] = useState(props?.record?.bankcity)
   const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
   const [UserInfo, setUserInfo] = useState({})
   const [errors, seterrors] = useState({})

 

useEffect(() => {
  Getdata();
 
  }, []);

const Getdata = async () => {
     
  var UserInfo  =    await UsergetInfobyname(UserName);
 
  setUserInfo(UserInfo.data.BankAccount)
}


const Onsubmit = async() =>{
  var bankdetails ={
    "holdername" : holdername,
    "currency":currency,
    "bankname": bankname,
    "Accountnumber":Accountnumber,
    "IFSCcode":IFSCcode,
    "CountryList":Country,
    "bankaddress":bankaddress,
    "bankcity":bankcity,
     "time":Date.now()
}
 const bankdata = {
  user_name:UserName,
  Bankdetails :bankdetails
 }

 const { errors, isValid } = await validateBankDetails(bankdetails);
 if (!isValid) {
  
     seterrors(errors)
 }else{ 
 
  UseUpdateBAnkdetails(bankdata)}
 
}

   return(
    <>
    <Modal size="xl" show={updateaccountdetails}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-4 '>
        <h5 className='headfont  text-center'>Bank Account Details</h5>

        <div className='d-lg-flex gap-3 mt-4'>
         
         <div className='w-full'>
               <p  className='waiz_modal_label'>Bank Name</p>
               <div className='wizardinput '>
                   <input type="text" readOnly maxLength={45} value={bankname} onChange={(e) => { setbankname(e.target.value) }}className='form-control' placeholder='Bank Name'/>
                   <span className="text-danger f-12 d-block text-left">{errors.bankname}</span>
               </div>
           </div>
           <div className='w-full'>
               <p  className='waiz_modal_label'>Account Number</p>
               <div className='wizardinput '>
                   <input type="text" maxLength={45} value={Accountnumber} onChange={(e) => { setAccountnumber(e.target.value) }}className='form-control' placeholder='Account Number'/>
                   <span className="text-danger f-12 d-block text-left">{errors.Accountnumber}</span>
               </div>
           </div>
      </div>

       <div className='d-lg-flex gap-3 mt-4'>
          <div className='w-full '>
                <p className='waiz_modal_label'>Select Currency</p>
                <div className='wizardinput'>
                   
                    <select className='form-control'
                    value={currency}
                    onChange={(e) => { setcurrency (e.target.value)}}>
                      <option >Select Currency</option>
                      <option value="EUR">EUR</option>
                      <option value="USD">USD</option>
                      <option value="ZAR">ZAR</option>
                      <option value="GBP">GBP</option>
                                           {/* {currencyList && currencyList.map((item) => 
                            
                                <option value={item.code}>{item.code}</option>
                                          )} */}
                    </select>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.currency}</span>
          </div>
          <div className='w-full'>
                <p className='waiz_modal_label'>Account Holder Name</p>
                <div className='wizardinput '>
                    <input type="text"
                    maxLength={45}
                    value={holdername} onChange={(e) => { setholdername(e.target.value) }}
                     className='form-control' placeholder='Account Holder Name'/>
            <span className="text-danger f-12 d-block text-left">{errors.holdername}</span>
                </div>
            </div>
       </div>
      

       <div className='d-lg-flex gap-3 mt-4'>
         
         <div className='w-full'>
               <p  className='waiz_modal_label'>Bank Code</p>
               <div className='wizardinput '>
                   <input type="text" maxLength={45} value={IFSCcode} onChange={(e) => { setIFSCcode(e.target.value) }}className='form-control' placeholder='Bank Code'/>
                   <span className="text-danger f-12 d-block text-left">{errors.IFSCcode}</span>
               </div>
           </div>
           <div className='w-full'>
               <p  className='waiz_modal_label'>Bank Country</p>
               <div className='wizardinput '>
               <select className='form-control'
                    value={Country}
                    maxLength={45}
                    onChange={(e) => { setCountry (e.target.value)}}>
                      <option >Select Country</option>
                                           {CountryList && CountryList.map((item) => 
                            
                                <option value={item.name}>{item.name}</option>
                                          )}
                    </select>
               </div>
                    <span className="text-danger f-12 d-block text-left">{errors.CountryList}</span>
           </div>
      </div>

      <div className='d-lg-flex gap-3 mt-4'>
         
          <div className='w-full'>
                <p  className='waiz_modal_label'>Bank Address</p>
                <div className='wizardinput '>
                    <input type="text" maxLength={45} value={bankaddress} onChange={(e) => { setbankaddress(e.target.value) }}className='form-control' placeholder='Bank Address'/>
                    <span className="text-danger f-12 d-block text-left">{errors.bankaddress}</span>
                </div>
            </div>
            <div className='w-full'>
                <p  className='waiz_modal_label'>Bank city</p>
                <div className='wizardinput '>
                    <input type="text"maxLength={45} value={bankcity} onChange={(e) => { setbankcity(e.target.value) }} className='form-control' placeholder='Bank city'/>
                    <span className="text-danger f-12 d-block text-left">{errors.bankcity}</span> 
                </div>
            </div>
       </div>
       <div className='text-end'>
       <button className='btn btn-theme mt-4 btn_big_theme_border' onClick={()=>Onsubmit()}>Update</button>
       </div>

</div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default UpdateAccountDetails
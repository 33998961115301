import React, { useState,useEffect } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { UseCryptoWithdraw } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { getAllFeeInfo, getTokeninfo, UsergetInfobyname, UsetokeninfoBySymbol } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBalanceValue } from '../../Hooks/UseValidation';
import {style,iconTheme,position} from '../../Hooks/UseToast'
import check from '../../Assets/Images/check.png'

const WithdrawModal = (props) =>{
   const[withdraw, setWithdraw] = useState(true)
   const[showtoken , setShowToken] = useState(false)
   const[selected , setSelected] = useState("")
   const [Network,setNetwork] = useState("")
   const [address,setaddress] = useState("")
   const [amount,setamount] = useState("")
   const [tokeninfo, setTokeninfo] = useState("")
   const [symbol,setSymbol] = useState()
   const [userBalance,setuserBalance] = useState([])
   const [balance,setBalance] = useState("0")
   const [UserInfo, setUserInfo] = useState({})
   const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
   const [SearchCoinData, SetSearchCoinData]  = useState([])
   const [User, setUser] = useState({})
   const [walletaddress,setwalletaddress] = useState("")
   const [coindata, setcoindata] = useState([])
   const [isSolana,setSolana] = useState(false)
   const [selectedToken,setselectedToken] = useState(props?.selectToken)
   const [WithdrawFee, setWithdrawFee] = useState("")
   const [submitbtn,setsumbitbtn] = useState("Submit")
   const [ismax,setismax] = useState(false)
   const [maxamount,setmaxamount] = useState(0)

   useEffect(() => {
   
    gettokeninfo();
    // showLoader();
    // handleChange()
}, [])

   const gettokeninfo = async () => {
   
    const tokeninfo = await getTokeninfo();
        setTokeninfo(tokeninfo.data[0].symbol)
        var UserInfo  =    await UsergetInfobyname(UserName);
        setUser(UserInfo?.data)
        setUserInfo(UserInfo?.data?.Holding)
        const searchData = await searchedBalanceValue(UserInfo?.data?.Holding,selectedToken);
       
    
        setmaxamount(parseFloat(searchData[0]?.amount))

        var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
            var dat = await UsetokeninfoBySymbol(item.symbol)
        
            dat['data']['Balance'] = item
            
            return dat['data']
        
        }))
        
        if(tokenSymbol.length > 0){
          setcoindata(tokenSymbol)
         
          
        }

        var feedata =  await getAllFeeInfo();
    setWithdrawFee(feedata?.data[0]?.withdrawFee);
 }

 const handleChange = async () => {
    
        
    if(symbol.length > 0){
          const searchData = await searchedBalanceValue(UserInfo,symbol);
        
          
              SetSearchCoinData(searchData[0])
              setBalance(searchData[0].amount)
                                        
                }
  
  }

  const Onwithdraw = async (e) => {
   

    e.preventDefault();
    const data = {
            UserName : UserName,
            amount: ismax ? maxamount :  amount,
            network: props?.selectNetwork == "bsc" ? "BSC" :  props?.selectNetwork == "solana" ? "Solana" : "ETH",
            symbol:selectedToken,
            walletaddress: walletaddress,
        };

        if(maxamount < amount ){
           
              toast.error(`you don't have enough balance on  ${selectedToken}`, {
                position:position.position,
                 style:style,
                 iconTheme: iconTheme,
             })
        }else if(walletaddress == ""){
            
            toast.error("Required withdraw wallet address", {
                position:position.position,
                 style:style,
                 iconTheme: iconTheme,
             })
        }
        
        
        else{

            await UseCryptoWithdraw(data)
            setsumbitbtn("Pending...")
         }
        
       
       
  }
   return(
    <>
    <Modal size="lg" show={withdraw}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-0'>
      <h3 className='headfont mb-5 text-center'>Withdraw ZARC</h3>

      <div className='notecont py-0'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-6 border_right_note'>
                            <div className='py-4'>
                        <li className='mt-2'> <p className=''>
                            <img src={check} />
                            <span>Please enter a Tether (ZARC) address below. We support ERC20, TRC20, BEP20, and SPL Tether.</span></p></li>
                      
                                </div>
                        </div> 
                        <div className='col-12 col-md-6'>
                        <div className='py-4'>
                        <li className='ps-md-4 mt-2'><p><img src={check} /><span>Entering any other address will result in the loss of your funds.</span></p></li>
                     </div>
                        </div>

                    </div>
                   
                </div>

       
       <div className='d-lg-flex gap-3 mt-4'>

          <div  className='w-full '>
            <div className='row'>
            <div className='col-12 col-md-6'>
            <div className='w-full'>
                    <div className=''>
                        <p className='mb-0 headfont mb-3  label_input_font'>Crypto</p>
                        {/* <Form.Check inline label=".sol domain name (optional)" className='noshrink f-14' name="group1" type="checkbox" /> */}
                    </div>
                    <div className='wizardinput'>
                    <input className='form-control'
                    value={selectedToken === "ZARCSol" ? "ZARC(SOL)" : selectedToken === "ZARC" ? "ZARC(BSC)" : selectedToken === "ZARCEth" ? "ZARC(ETH)" : selectedToken }
                    required
                    readOnly
                    onChange={(e) => { setSymbol (e.target.value);handleChange(e.target.value)}}>
                      {/* <option value="ZARC"> ZARC</option> */}
                                           {/* {tokeninfo && tokeninfo.map((item) =>  */}
                            
                                {/* <option value={tokeninfo}>{tokeninfo}</option> */}
                                          {/* )} */}
                    </input>
                    </div>
                
                  
                </div>

       <p className='lightgreen f-12 mt-2 text-right'>Withdraw Fee - {WithdrawFee} %</p> 

            </div>
            <div className='col-12 col-md-6'>
            <div className='w-full'>
                    <p className='mb-0 headfont mb-3 label_input_font sel_net_font mt-0'>Network</p>
                    <div onClick={() => setShowToken(!showtoken)} className='wizardinput position-relative selectbox'>
                        {/* <p className='f-12'>Network</p> */}
                        <span className='d-inline-block sh_span_margi'>{ props?.selectNetwork == "bsc" ? "BSC" :  props?.selectNetwork == "solana" ? "Solana" : "eth"}</span>
                        {/* <Button variant="link"  className="nounder text-light"><spna className={showtoken ? "fa fa-chevron-up" :"fa fa-chevron-down"}></spna> </Button> */}
                        {/* <div className={showtoken ? 'selecttoken' : 'd-none'}>
                            <Form.Check  type="radio"onChange={(e) => {setNetwork (e.target.value);setShowToken(false)}} name="token" value="Eth"label="Eth"/>
                            <Form.Check  type="radio" onChange={(e) => {setNetwork (e.target.value);setShowToken(false);setSolana(true)}}name="token" value="Solana"label="Solana" />
                            <Form.Check  type="radio" onChange={(e) => {setNetwork (e.target.value);setShowToken(false)}} name="token" value="BSC BEP20"label="BSC" />
                        </div> */}
                    </div>
                </div>

            </div>
            <div className='col-12 col-md-6'>
            <div className='w-full'>
            {/* {isSolana && Network === "Solana SPL"?  <> */}
                <div className='d-flex jc-between gap-2 mt-3 mb-2'>
                    <p className='mb-0 headfont mb-2 mt-0 label_input_font'>withdraw wallet Address {props?.selectNetwork == "bsc" ? "BSC" :  props?.selectNetwork == "solana" ? "Solana" : "Eth"} </p>
                  
            </div>
                <div className='wizardinput'>
                    <input type="text" 
                    // required 
                    onChange={(e) => setwalletaddress(e.target.value)} className='form-control' placeholder='withdraw wallet Address'/>
                </div>
                {/* </> */}
                 {/* :<></>} */}
           
                </div>
            </div>
            <div className='col-12 col-md-6'>
            <div className='w-full'>
                <div className='d-flex jc-between gap-2 mt-3 mb-2'>
                    <p className='mb-0 headfont mb-2 mt-0 label_input_font'>Amount to withdraw</p>
                  
                   
            </div>
                <div className='wizardinput'>

                <div className='input-group input_grp_with_btu'>
                <input type="text"
                 value={ismax ? maxamount : amount}
                 required onChange={(e) => {setismax(false);setamount(e.target.value)}} className='form-control' placeholder='Amount'/>

                                                <div className='input-group-append append_no_tran'>
                                                    {/* <button className='btn btn_max'>Max</button> */}
                                                    <button onClick = {() => {setamount(maxamount) ;setismax(true)}}className='btn btn_max'>Max</button>

                                                </div>

                                            </div>

                </div>
                { coindata && coindata.length > 0 ?
                    <>{coindata && coindata.map((item) =>
                        
                        (item.Balance.symbol === selectedToken && !isSolana? 
                <p className='lightgreen f-12 text-right mt-2'>{selectedToken === "ZARCSol" ? "ZARC(SOL)" : selectedToken === "ZARC" ? "ZARC(BSC)" : selectedToken === "ZARCEth" ? "ZARC(ETH)" : selectedToken} {(item.Balance.amount).toFixed(3)} </p> 
                 : <>
                   {item.Balance.symbol === selectedToken && isSolana? 
                <p className='lightgreen f-12 text-right mt-2'>{item.Balance.symbol} {(item.Balance.amount).toFixed(3)} </p> 
                 : <></>}</>)
                 )}</> :<><p className='lightgreen f-12 text-right mt-2'>ZARC 0</p></> }
                </div>
            </div>
            
            <div className='col-12'>
            <div className='w-full mt-lg-0 mt-0 text-center'>

                {parseFloat(maxamount) > 0 || parseFloat(amount) > 0 ?
            
            <button className='btn btn-theme mt-4 btn_big_theme_border py-2' onClick={Onwithdraw}>{submitbtn}</button>  :
            <button className='btn btn-theme mt-4 btn_big_theme_border py-2' disabled={true}>{submitbtn}</button> }

            
            {/* <button className='btn btn-theme mt-4 btn_big_theme_border py-2' onClick={() => setsumbitbtn("Pending...")}>{submitbtn}</button> */}
           

          </div>
            </div>
            </div>
           
            {/* <div className='d-lg-flex gap-3 align-items-end'> */}
              

             
                
               

                
            {/* </div> */}
             
          </div>

        

       </div>
      

       
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default WithdrawModal
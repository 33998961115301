import React, { useState ,useEffect} from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import { TENMINUTESINSECONDS } from '../../config/env';
import { Check2FACode, CheckLoginOtp } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import axios from "axios";
import {Buffer} from 'buffer';
import qr from '../../../src/Assets/Images/qr.png'
import QRCode from "react-qr-code";
import { UserGetByName, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';

const TwoFAuth = (props) =>{
   const[twofact, setTwoFact] = useState(true)
   const [box1,setbox1] = useState("")
   const [box2,setbox2] = useState("")
   const [box3,setbox3] = useState("")
   const [box4,setbox4] = useState("")
   const [box5,setbox5] = useState("")
   const [box6,setbox6] = useState("")
   const [username,setusername] = useState(sessionStorage.getItem("username"))
   const [Enablebtn, setEnablebtn] = useState(false)
   const [counter, setCounter] = useState(TENMINUTESINSECONDS)
   const [Seconds, setseconds] = useState(0)
   const [Minutes, setminutes] = useState(0)
   const [ip, setIP] = useState('');
    
  const [authcode,setauthcode] = useState("")
  const [key,setKey] = useState("")
  

  useEffect(() => {
    getuserData();
  },[]);

  const on2FASubmit = async () => {

    
    
    const User2fAData = {
      // headers : {authorization: `Bearer ${accessToken}`},

      user_name: username,
      key: key,
      token:box1 + box2 + box3 + box4 + box5 + box6
        
    };
   
    await Check2FACode(User2fAData)

};
  const getuserData = async() => {
    var UserInfo  = await UserGetByName(username);
    if(UserInfo.success === true){
          var key = UserInfo?.data?.authcode.replace(/\W/g, '').toLowerCase();
          setKey(key)
          var link = "otpauth://totp/{{NAME}}?secret={{KEY}}";
          var name = "ZARC"
          var codeToQr = link.replace(/{{NAME}}/g, name).replace(/{{KEY}}/g, key)
         
          setauthcode(codeToQr)
     }
 };
 
  let tabChange = (val) => {
    let ele = document.querySelectorAll('input.otp');
    if (val == 6) {
      ele[val-1].focus()
    } else {
      if (5 >= val === true) {
        if (ele[val - 1].value !== '') {
          ele[val].focus()
        } else if (ele[val - 1].value === '') {
          ele[val - 1].focus()
        }
      }
    }

  }
     const setFunction = (data)=> {
      console.log("setFunction_setFunction",data);
      setbox1(data[0]);setbox2(data[1]);setbox3(data[2]);setbox4(data[3]);setbox5(data[4]);setbox6(data[5]);
      tabChange(6)
    }
   return(
    <>
<Modal backdrop="static"  keyboard={false}  size="md" show={twofact} centered scrollable  onHide={() => {props.onDismiss()}} className='modal_style thememodal loginmodal'>
      <Modal.Header className='border-0 pb-0'>
      {/* <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only"></span></button> */}

      </Modal.Header>
      <Modal.Body>
       
      <div className='mb-4'>
        <div className=''>
        <h3 className='headfont mb-4 text-center'>2FA OTP</h3>
            
        </div>
        <p className='f-14'>Enter the code that you see in the app in the 
            text field and click confirm </p>
            {/* <div className='text-center '>  
            <QRCode size="150" value={authcode} />
            
             </div> */}
        <p className='text-center'><label className='mt-5'>Enter 2FA Code</label></p>
        <form action="" className=" mb-4 confirm justify-content-center">
              {/* <input className="otp" type="text"  onChange={(e) => { setbox1(e.target.value) }} onKeyUp={() => tabChange(1)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox2(e.target.value) }} onKeyUp={() => tabChange(2)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox3(e.target.value) }} onKeyUp={() => tabChange(3)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox4(e.target.value) }} onKeyUp={() => tabChange(4)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox5(e.target.value) }} onKeyUp={() => tabChange(5)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox6(e.target.value) }} onKeyUp={() => tabChange(6)} maxlength="1" /> */}
              <input className="otp" type="text"  value={box1} onChange={(e) => { e.target.value.length >= 6 ? setFunction(e.target.value) :  setbox1(e.target.value[0]) }} onKeyUp={() => tabChange(1)} maxlength={box1 ? "1": "6"} />
              <input className="otp" type="text"  value={box2} onChange={(e) => { e.target.value.length >= 6 ? setFunction(e.target.value) :  setbox2(e.target.value[0]) }} onKeyUp={() => tabChange(2)} maxlength={box2 ? "1": "6"} />
              <input className="otp" type="text"  value={box3} onChange={(e) => { e.target.value.length >= 6 ? setFunction(e.target.value) :  setbox3(e.target.value[0]) }} onKeyUp={() => tabChange(3)} maxlength={box3 ? "1": "6"} />
              <input className="otp" type="text"  value={box4} onChange={(e) => { e.target.value.length >= 6 ? setFunction(e.target.value) :  setbox4(e.target.value[0]) }} onKeyUp={() => tabChange(4)} maxlength={box4 ? "1": "6"} />
              <input className="otp" type="text"  value={box5} onChange={(e) => { e.target.value.length >= 6 ? setFunction(e.target.value) :  setbox5(e.target.value[0]) }} onKeyUp={() => tabChange(5)} maxlength={box5 ? "1": "6"} />
              <input className="otp" type="text"  value={box6} onChange={(e) => { e.target.value.length >= 6 ? setFunction(e.target.value) :  setbox6(e.target.value[0]) }} onKeyUp={() => tabChange(6)} maxlength={box6 ? "1": "6"} />
         </form>
         <div className="text-center">
      
          
            <button className="btn btn-theme" onClick={()=> on2FASubmit()}>Confirm</button>
           </div>
           
                
      </div>
      </Modal.Body>

    </Modal>
  </>
   )
}

export default TwoFAuth
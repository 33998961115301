import React, { useState,useEffect } from 'react';
import {Modal, DropdownButton, Dropdown, Tabs, Tab, Row, Col, Nav} from 'react-bootstrap'
import { UseBankActive, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBankValue } from '../../Hooks/UseValidation';
import TransferedFunds from '../Modals/TransferedFunds';
import * as config from "../../config/env";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import fiat1 from '../../Assets/Images/hint/fiat1.png'
import fiat2 from '../../Assets/Images/hint/fiat2.png'
import fiat3 from '../../Assets/Images/hint/fiat3.png'
import fiat4 from '../../Assets/Images/hint/fiat4.png'
import fiat5 from '../../Assets/Images/hint/fiat_deposit.png'


import crypto1 from '../../Assets/Images/hint/crypto1.png'
import crypto2 from '../../Assets/Images/hint/crypto2.png'

const options= {
  responsive:{
    0: {
        items: 1,
    },
    450: {
        items: 1,
    },
    600: {
        items: 1,
    },
    1000: {
        items: 1,
    },
},
}

const DepositHint = (props) =>{
   const[deposithint, setDeposithint] = useState(true)
 
   

const skip  = () =>{
  localStorage.setItem("depositpage",1)
}

// const closepopup = ()=>{
//      window.location.href = config?.frontendurl + '/deposit'
//     $('#deposithintopup').hide()
// }
  

   return(
    <>
    <Modal size="lg" show={deposithint}  id="deposithintopup" onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big_hint'>
    {/* {true && <TransferedFunds bankdata={SearchCoinData && SearchCoinData} refcode={props.refcode} onDismiss={() => setFunds(false)} />} */}
      <Modal.Header className='border-0 pb-0'>
     
           {/* <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only"></span></button> */}
      </Modal.Header>
      <Modal.Body className='px-sm-5 p-3 themescroll'>
       
      <div className='mb-4 content viewdetails'>
      
       
        
        <h3 className='headfont text-center'>Deposit</h3>
  

        <Tab.Container id="uncontrolled-tab-example" defaultActiveKey="fiathint" className="mb-3 pt-4">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="tab_stl_new tab_stye_modal">
            <Nav.Item>
              <Nav.Link eventKey="fiathint">
              <div>
              Fiat
              </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="cryptohint">
                <div>
                Crypto
                </div>
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="fiathint">
         <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <img src={fiat1} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>•	Fiat or Crypto: Select either Fiat or Crypto based on your preferred deposit type.
<p className='desc_hint mt-3'>• Choose Fiat if you want to deposit traditional currency or select Crypto for digital currencies</p>
</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={fiat2} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>Choose Standard Bank from the drop-down menu under EFT and proceed to make the payment using the displayed banking details. Remember to include your unique deposit code as a reference.</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={fiat3} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>You are almost done! Just click on "I have transferred funds" once you have successfully made your EFT.</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={fiat4} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>To complete your transaction, please upload your proof of payment and fill out the form. Remember to select your preferred chain for your ZARC before clicking 'upload'.</p>
    
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={fiat5} className='img-fluid img_hint' />
   
    <p className='desc_hint mt-3'>After completing your deposit and uploading the proof of payment, track the status and history of your fiat deposit on the dashboard tab in real-time.</p>
    </div>
</div>
</OwlCarousel>
</div>

      
   
            </Tab.Pane>
            <Tab.Pane eventKey="cryptohint">
            <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <img src={crypto1} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>To receive your ZARC, choose the blockchain you prefer (e.g., BEP 20). Ensure you've selected the correct chain before scanning the QR code or copying the wallet address. Once selected, simply transfer your assets from your self-custody wallet to your NeoNomad wallet by scanning the QR code or copying the deposit address</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={crypto2} className='img-fluid img_hint' />
    <p className='desc_hint mt-5'>After making your crypto deposit, your balance will be updated in the balance section at the top. Please note that it may take up to 2 network confirmations for the balance to reflect accurately.</p>
    </div>
</div>


</OwlCarousel>
</div>
       
            </Tab.Pane>
          </Tab.Content>
<div className='text-center mt-4'>
          <button className="btn btn-theme" onClick={() =>{props.onDismiss(); skip()}}>Skip Hints</button>
          </div>
        </Col>
      </Row>
    </Tab.Container>


        
  

      
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default DepositHint
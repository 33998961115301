import React, { useState, useEffect } from 'react';
import { Modal, DropdownButton, Dropdown, Form } from 'react-bootstrap'
import {  UsergetInfobyname, getTokeninfo } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBalanceValue } from '../../Hooks/UseValidation';
import * as config from "../../config/env";
import check from '../../Assets/Images/check.png';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../../Hooks/UseToast'
import { UseUserBridge } from '../../Hooks/UseBackendAction/UseCreateUser.js';







const Bridgemodal = (props) => {
    const [bridgeShow, setBridgeshow] = useState(true);
    const [Tokeninfo, setTokeninfo] = useState([]);
    const [fromImage,setfromImage] = useState("")
    const [fromToken,setfromToken] = useState("")
    const [fromBalance,setfromBalance] = useState("0")
    const [toImage,settoImage] = useState("")
    const [toToken,settoToken] = useState("")
    const [UserInfo, setUserInfo] = useState({})
    const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
    const [fromamount,setfromamount] = useState("0")
    
    




    useEffect(() => {
        getData();
       
    }, []);



    const getData = async () => {

        const tokeninfo = await getTokeninfo();
        setTokeninfo(tokeninfo?.data);
        var UserInfo = await UsergetInfobyname(UserName);
        setUserInfo(UserInfo?.data?.Holding);

    }


    const handleChange = async (value) => {
        const searchData = await searchedBalanceValue(UserInfo, value);
        setfromBalance(searchData[0]?.amount ? searchData[0]?.amount : 0)
    }

    

    const Bridge = async() =>{

        if (fromToken == "" || toToken == "") {
    
            toast.error("select tokens first", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
              })
          
        }else if(parseFloat(fromBalance)  < parseFloat(fromamount))
        {
            toast.error("You don't have enough balance", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
              })
        }
         else{

            const data = {
                user_name :UserName,
                fromtoken :fromToken,
                totoken : toToken,
                amount :parseFloat(fromamount),
            }
        
        await UseUserBridge(data)

        }
        
      
    }

    const max = async() =>{

        setfromamount(fromBalance)

    }

    



    return (
        <>
            <Modal size="lg" show={bridgeShow} id="exchangehintopup" onHide={() => props.onDismiss()} centered scrollable className='modal_style thememodal modal_big_hint'>

                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className='px-sm-5 p-3 themescroll'>

                    <div className='mb-4 content viewdetails'>



                        <h3 className='headfont text-center'>Bridge</h3>
                        <div className='mt-3'>


                            <div className='card card_cutout mt-3 card_cutout_exch'>

                                <div className='rightinput dd_inpout_desi exchange_sec_nut'>

                                    <div className='row'>
                                        <div className='col-12 col-md-8 input_ex_des'>

                                            <div className='input-group input_grp_with_btu'>
                                                <Form.Control aria-label="Text input with dropdown button"
                                                 placeholder='Please Enter here' 
                                                 value={fromamount}
                                                 onChange={(e) =>setfromamount(e.target.value)}

                                                />
                                                <div className='input-group-append'>
                                                    <button className='btn btn_max' onClick={()=> max()}>Max</button>
                                                </div>

                                            </div>
                                            <p>Balance: {fromBalance} </p>

                                        </div>
                                        <div className='col-12 col-md-4 dropdwo_height_Scrol'>
                                            <DropdownButton variant="outline-secondary" className='dropdow_open_modal'
                                                 title={ fromImage == "" ? <>
                                                 <img src={check} className='check_img_resize'></img> Select</> : 
                                                 <><img src={config.backurl + `/Images/${fromImage}`} /> {fromToken}</>
                                                 } 
                                                id="input-group-dropdown-2" align="end">
                                                {Tokeninfo && Tokeninfo.map((item) => {
                                                    return (
                                                        item.symbol === "ZARC" || item.symbol === "ZARCSol" || item.symbol === "ZARCEth" ?
                                                            <> <Dropdown.Item   disabled={toToken == item.symbol ? true : false} onClick={() => {setfromToken(item.symbol); setfromImage(item.logoURI); handleChange(item.symbol);}}> <img src={config.backurl + `/Images/${item.logoURI}`} />{item.symbol}
                                                            </Dropdown.Item></> : <></>

                                                    )


                                                }
                                                )}
                                  </DropdownButton>
                                        </div>
                                    </div>
                                    {/* </InputGroup> */}

                                </div>





                            </div>

                            <div className='text-center exbtn '>
                                {/* <img src={imgtrans} /> */}
                                <span className='fa fa-exchange text-center'></span>
                            </div>


                            <div className='card card_cutout  card_cutout_exch'>
                                <div className='rightinput inp exchange_sec_nut'>
                                    <div className='row'>
                                        <div className='col-12 col-md-8 input_ex_des'>

                                            <Form.Control aria-label="Text input with dropdown button"

                                                placeholder='Please Enter here' 
                                                value={fromamount}
                                                />





                                           
                                            <p></p>
                                        </div>
                                        <div className='col-12 col-md-4 dd_inpout_desi dropdwo_height_Scrol'>
                                        <DropdownButton variant="outline-secondary" className='dropdow_open_modal'
                                                 title={ toImage == "" ? <>
                                                 <img src={check} className='check_img_resize'></img> Select</> : 
                                                 <><img src={config.backurl + `/Images/${toImage}`} /> {toToken}</>
                                                 } 
                                                id="input-group-dropdown-2" align="end">
                                                {Tokeninfo && Tokeninfo.map((item) => {
                                                    return (
                                                        item.symbol === "ZARC" || item.symbol === "ZARCSol" || item.symbol === "ZARCEth" ?
                                                            <> <Dropdown.Item disabled={fromToken == item.symbol ? true : false} onClick={() => {settoToken(item.symbol); settoImage(item.logoURI)}}> <img src={config.backurl + `/Images/${item.logoURI}`} />{item.symbol}
                                                            </Dropdown.Item></> : <></>

                                                    )


                                                }
                                                )}
                                  </DropdownButton>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className='text-center exbtn '><span className='fa fa-exchange text-center'></span></div> */}


                            </div>
                        </div>
                        {parseFloat(fromamount) > 0 ?
                        <div className='text-center mt-4'>
                            <button className="btn btn-theme" onClick={()=> Bridge()}>Bridge</button>
                        </div> :
                        <div className='text-center mt-4'>
                        <button className="btn btn-theme" disabled ={true}>Bridge</button>
                    </div>
}

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Bridgemodal
import React, { useState, useEffect } from 'react';
import logo from '../Assets/Images/logo.svg'
import ForgetPassword from './Modals/Forgetpassword';
import Login from './Modals/LoginModal';
import Register from './Modals/RegisterModal';

import * as config from "../config/env";

const LandHeader = () =>{
    const[login, setLogin] = useState(false)
    const[register, setRegister] = useState(false);
    const[password, setPassword] = useState(false);
   


    useEffect(() => {
        const link = window.location.pathname.split("/")[1];
        if(link == "login"){
            setLogin(true)
        }else if (link == "register"){
            setRegister(true) 
        }else if(link == "forgotpassword"){
            setPassword(true)
        }

    }, []);

const closeLogin = async =>{
    setLogin(false)
    window.location.href =  config?.Landingpage
}


const closeRegister = async =>{
    setRegister(false)
    window.location.href =  config?.Landingpage
}

const closeforget = async =>{
    setPassword(false)
    window.location.href =  config?.Landingpage
}




    return(
        <div className='landhead'>
            {login && <Login onDismiss={() => closeLogin()} showreg={() => window.location.href= config?.Landingpage+"/register"} forget={() => window.location.href= config?.Landingpage+"/forgotpassword"}/>}
            {register && <Register onDismiss={() => closeRegister()} showlogin={() => window.location.href= config?.Landingpage+"/login"}/>}
            {password && <ForgetPassword onDismiss={() =>closeforget()}  showlogin={() => window.location.href= config?.Landingpage+"/login"}/> }


        {/* <div className='d-flex jc-between py-3'>
      <a href='/'> <img src={logo} /></a> 
        <button className='btn btn-theme ' onClick={() => setLogin(true)}>Connect App</button>
        </div> */}
        </div>
    )
}

export default LandHeader
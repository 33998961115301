import React, { useState } from 'react';
import {Modal, Button,ProgressBar} from 'react-bootstrap'
import jpg from '../../Assets/Images/jpg.svg'
import {style,iconTheme,position} from '../../Hooks/UseToast'
import toast, { Toaster } from 'react-hot-toast';
import { Useuploadfile } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import upload from '../../Assets/Images/upload_img_new.png'



const Verification = (props) =>{
    const[verify, setVerify ]= useState(true)
    const[filename , setFilename] = useState([])
    const [username,setusername] = useState(sessionStorage.getItem("username"))
    const [imagesize, setimagesize] = useState(50)
    const [imagepath, set_imagepath] = useState("")
    const [file, set_file] = useState({})
    const [Level1,setLevel1] = useState(0)
    const [orginalsize,setorginalsize] = useState()
    const [doctype,setdoctype] = useState("Passport")
    const [ImageURL, setImageURL] = useState("")

    const FileUpload = event => {
      var name = event.target.value;
      var size = Number(event.target.files[0].size);
      if(size > imagesize  * 1000){
        toast.error(`Please select file size less than ${imagesize} KB`, {
          position:position.position,
          style:style,
          iconTheme: iconTheme,
        })
        
      }else{
        setLevel1(70)
        set_file(event.target.files[0]);
        set_imagepath(name);
        var url = URL.createObjectURL(event.target.files[0])
  setImageURL(url)
        setorginalsize(size);
      
      }
  };

  const CancleImage = async() =>{
    setLevel1(0)
    set_imagepath("");
    setorginalsize("");
    setImageURL("")

  }
const getfile = (e) =>{
  var a = e.target.files[0].name
  setFilename(data => [...data, a])
}

const onSubmit = async () => {

  const fileData ={
      user_name:username,
      FileType:doctype,
      file:file
     };
     
     await Useuploadfile(fileData)
     setLevel1(100)
  }

   return(
    <>
    <Modal show={verify} size="md" centered scrollable className='modal_style thememodal preference'>
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss(); props.closemod()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='pt-0'>
      <div className='mb-4'>
            <div className='mb-0'>
                <h5 className='headfont text-center mt-4'>Upload KYC File</h5>
                {/* <p className='text-muted f-14 pb-0 mb-0 text-center'>Lorem ipsum is simply a dummy text</p> */}
           
        </div>
        <div className='w-full'>
                    <div className='d-lg-flex jc-between gap-2 mb-0'>
                        <p className='mb-0 headfont mb-3 mt-4 label_input_font'>Select Document</p>
                        {/* <Form.Check inline label=".sol domain name (optional)" className='noshrink f-14' name="group1" type="checkbox" /> */}
                    </div>
                    <div className='wizardinput'>
                    <select className='form-control'
                    value={doctype}
                      required
                    onChange={(e) => setdoctype (e.target.value)}>

                      <option value="Passport"> Passport</option>
                      <option value="Voter's Identity Card"> Voter's Identity Card</option>
                      <option value="Aadhaar Card"> Aadhaar Letter/Card.</option>
                      <option value="PAN Card"> PAN Card</option>
                      
                                         
                                {/* <option value={tokeninfo}>{tokeninfo}</option> */}
                                         
                    </select>
                    </div>
                  
                </div>
        <div>
          <p className='headfont mb-3  mt-4 label_input_font'>Upload files</p>
          <div className='selectbank upload'>

          <div className='position-relative upload_img_div'>
                
                {/* <img src={upload} /> */}
                {imagepath.length !== 0 ? 
                                      <img src={imagepath} /> : <img src={upload} /> }
                <p className='headfont mb-0'>{ImageURL.length !== 0 ? " " : ""}</p>
                <input type="file"  value={imagepath} onChange={FileUpload}/> 
            </div>
            </div>

<p className='mb-0 headfont mt-2 label_input_font text-muted'>File size less than 50kb</p>
         
        </div>



 

    <div className='uploadfiles mt-3'>
          {/* <div className='d-flex uploads flex-1 gap-2 align-items-center mt-2'>
            
              <img src={jpg} />
              <div>
              <p className='filename mb-0'>filename.png</p>
             
              <p className='mb-0'>35MB</p>
              </div>
              <div className='closebtn'> <button className='fa fa-times circlebtn bg-white text-dark'></button></div>
          </div> */}
          <div className='d-flex uploads  gap-2 align-items-center mt-2'>
              
              {ImageURL.length !== 0 ? 
                                     <div>   <img src={ImageURL} /> </div>: <div> <img src={jpg} /> </div>}
                                    
              <div className='w-full'>
              <p className='filename mb-0'>{imagepath ? imagepath : "File Name"}</p>
              <ProgressBar now={Level1}/>
              <p className='mb-0'>{orginalsize ? `${orginalsize / 1000} KB` : "File Size"} </p>
              </div>
              <div className='closebtn' onClick={CancleImage}> <button className='fa fa-times circlebtn bg-disabled'></button></div>
          </div>
    </div>


        
      
        
        <div className='text-center mt-4'>
        {imagepath != "" ?
                                <button onClick={() => onSubmit()} className="btn btn-theme btn_big_theme_border">Upload</button>:
                                <button  disabled className="btn btn-theme btn_big_theme_border">Upload</button>}
            {/* <button className='btn btn-theme'>Update</button> */}
            </div>
        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default Verification
import BigNumber from 'bignumber.js';
import { UseERC20Eth, UseWeb3, UseWeb3Eth } from "./useWeb";
import { v3Router_Ethaddress, v3Router_abi, v3Router_address} from "../../config/routerContract";
import { ERC20_ABI } from '../../config/erc20';



export const UseERC20 = async (VALUE) =>{
    const web3 = await UseWeb3();
    const contract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return contract;                
}

export const UseRouter = async (VALUE) =>{
    const web3 = await UseWeb3();
    const contract = new web3.eth.Contract(v3Router_abi, VALUE);
    return contract;                
}

export const checkIsApprovedRouter = async (account,token) => {
   
    const erc20contract = await UseERC20(token);
    const allow = await erc20contract.methods.allowance(account,v3Router_address).call();
    return (parseInt(allow)>0)?true:false
}


export const checkIsApprovedRouterETH = async (account,token) => {
   
    const erc20contract = await UseERC20Eth(token);
    const allow = await erc20contract.methods.allowance(account,v3Router_Ethaddress).call();
    return (parseInt(allow)>0)?true:false
}

export const approveContractRouter = async(account,token)=>{
    const erc20Contract = await UseERC20(token);
    await erc20Contract.methods.approve(v3Router_abi,"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account})
}

export const getBNBBlance = async (account) => {

    const web3 = await UseWeb3();
    const bnbBalance = await web3.eth.getBalance(account);
    const bnb = parseFloat(bnbBalance / 10 ** 18)
    // const bnb = ((new BigNumber(bnbBalance)).div(new BigNumber(10).pow(18))).toNumber();

    return parseFloat(bnb.toString());
}


export const getETHBlance = async (account) => {

    const web3 = await UseWeb3Eth();
    const bnbBalance = await web3.eth.getBalance(account);
    const bnb = parseFloat(bnbBalance / 10 ** 18)
    return parseFloat(bnb.toString());
}

export const UseTokenInfo = async (TOKEN) => {
    const token = await UseERC20(TOKEN);
    const name= await token.methods.name().call();
    const symbol= await token.methods.symbol().call();
    const decimals= await token.methods.decimals().call();
    const data = {
        name: name,
        symbol: symbol,
        decimals: decimals,
        address: TOKEN
    }
    return data;
}

export const UseTokenInfoEth = async (TOKEN) => {
    const token = await UseERC20Eth(TOKEN);
    const name= await token.methods.name().call();
    const symbol= await token.methods.symbol().call();
    const decimals= await token.methods.decimals().call();
    const data = {
        name: name,
        symbol: symbol,
        decimals: decimals,
        address: TOKEN
    }
    return data;
}


export const toFixedNumber = (x)=> {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x.toString();
}
import React, { useState,useEffect } from 'react';
import {Modal, DropdownButton, Dropdown, Tabs, Tab, Row, Col, Nav} from 'react-bootstrap'
import { UseBankActive, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBankValue } from '../../Hooks/UseValidation';
import TransferedFunds from '../Modals/TransferedFunds';
// import $ from 'jquery';
import * as config from "../../config/env";

const ViewDetails = (props) =>{
   const[details, setDetails] = useState(true)
   const[funds, setFunds] = useState(false)
   const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
   const [UserInfo, setUserInfo] = useState({})
   const [userbankdata,setUserbankdata] = useState([])
   const [Search, setSearch]   = useState("")
   const [isSearch, setisSearch] = useState(false)
   const [SearchCoinData, SetSearchCoinData] = useState([])
   

   useEffect(() => {
    document.getElementById("bankdata").classList.add("blur")
   getdata();
  }, [])

   const getdata = async() =>{
    var UserInfo  =    await UsergetInfobyname(UserName);
    setUserInfo(UserInfo.data)
    setUserbankdata(UserInfo?.data?.BankAccount)

    var Bankinfo = await UseBankActive();
    setUserbankdata(Bankinfo?.data)
       
   }

const closepopup = ()=>{
  // alert(1)
     window.location.href = config?.frontendurl + '/deposit'
    // $('#detailspopup').hide()
}
   const handleChange = async (value) => {
        
    if(value.length > 0){
          const searchData = await searchedBankValue(userbankdata,value);
         
          
          
          localStorage.setItem("holdername",searchData[0]?.holdername)
          localStorage.setItem("currency",searchData[0]?.currency)
          localStorage.setItem("bankname",searchData[0]?.bankname)
          localStorage.setItem("Accountnumber",searchData[0]?.Accountnumber)
          localStorage.setItem("IFSCcode",searchData[0]?.IFSCcode)
          localStorage.setItem("bankcity",searchData[0]?.bankcity)
          localStorage.setItem("AccountType",searchData[0]?.AccountType)
        
         
                                   SetSearchCoinData(searchData && searchData[0])
                                   document.getElementById("bankdata").classList.remove("blur")       
                                   setisSearch(true)
    }else{
        setisSearch(false)
    }
  
  }

   return(
    <>
    <Modal size="lg" show={details}  id="detailspopup" onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
    {/* {true && <TransferedFunds bankdata={SearchCoinData && SearchCoinData} refcode={props.refcode} onDismiss={() => setFunds(false)} />} */}
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{closepopup()}}><span aria-hidden="true">×</span><span class="sr-only"></span></button>
      </Modal.Header>
      <Modal.Body className='px-sm-5 p-3 themescroll'>
       
      <div className='mb-4 content viewdetails'>
      
       
        
        <h3 className='headfont text-center'>Deposit funds to your Rand wallet</h3>
  

        <Tab.Container id="uncontrolled-tab-example" defaultActiveKey="portfolio" className="mb-3 pt-4">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="tab_stl_new tab_stye_modal">
            <Nav.Item>
              <Nav.Link eventKey="portfolio">
                <div>VIA EFT</div>
                
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='choosenet choosenet_hover' >
                <div className='disabled_btn_tab'>
                VIA CARD
                </div>
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="portfolio">
            <div className='text-center mt-3'>
            <button className='btn btn-trans-link w-full'>Make a deposit using  <span className='text_blue_bn_lnk'>admin bank account</span></button>

            {/* <button className='btn btn-trans-link w-full'>Make a deposit <span className='text_blue_bn_lnk'>{props.refcode}</span></button> */}
        </div>

        <div className='d-flex flex-1 align-items-center my-4 selectbank'>
        <h3 class="headfont mb-3  mt-4 label_input_font">Select your bank</h3>
        <div className='word_brak_item_bak'>
              <DropdownButton id="dropdown-basic-button" className='w-full' title={SearchCoinData && SearchCoinData?.bankname ?SearchCoinData && SearchCoinData?.bankname : "Select Bank"}>
                  
              {userbankdata && userbankdata.map((item) =>
                <Dropdown.Item onClick={(e)=>{setSearch(item.bankname);handleChange(item.bankname)}}>{item.bankname}
                </Dropdown.Item>
              )} 
            </DropdownButton>
            </div>
        </div>
        <div className=' ' >
            <div id="bankdata">
            <div  className='w-100 mb-lg-0 mb-4'>
                
                <p className='d-flex jc-between label_input_font'>
                    <span className='acc_number_min_wid'> Account number: </span> <span className='label_input_font_ans label_input_font_ans_word_break'> {SearchCoinData && SearchCoinData?.Accountnumber}</span>
                </p>
               
                <p className='d-flex jc-between label_input_font'>
                    <span>IFSC: </span> <span className='label_input_font_ans'> {SearchCoinData && SearchCoinData?.IFSCcode}</span>
                </p>
                
                <p className='d-flex jc-between label_input_font'>
                    <span>Branch: </span> <span className='label_input_font_ans'> {SearchCoinData && SearchCoinData?.bankcity}</span>
                </p>


                <p className='d-flex jc-between label_input_font'>
                    <span>Reference No: </span> <span className='label_input_font_ans'> {props?.refcode} </span>
                </p>

                <p className='d-flex jc-between label_input_font'>
                    <span>Account Type: </span> <span className='label_input_font_ans'> {SearchCoinData && SearchCoinData?.AccountType}</span>
                </p>

            </div>
        <div className='mt-4 text-center'>
            {/* <button className='btn btn-theme' onClick={() => {setFunds(true)}}>I have transferred Funds</button> */}
            <button className='btn btn-theme btn_trans_fund_new' onClick={() => {props.open(); props.onDismiss()}}>I have transferred Funds</button>

        </div>
        </div>
    </div>
            </Tab.Pane>
            <Tab.Pane eventKey="viacard">
       
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>


        
  

      
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default ViewDetails
import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import { UseForgotPassword } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { sleep } from '../../Hooks/UseBackendAction/UseGetController';
import { validateForgotPassword } from '../../Hooks/UseValidation';


const NewPassword = (props) =>{
    const[newpassword, setNewPassword] = useState(true)

    
    const [Password,setPassword] = useState("")
    const [ConfirmPassword,setConfirmPassword] = useState("")
    const [Email, setEmail] = useState(localStorage.getItem("forgotEmail"))
    const [Cpasswordeye, setCpasswordeye] = useState(false)
    const [errors, seterrors] = useState({})
    const [passwordeye, setpasswordeye] = useState(false)

    const showPassword = (e) => {

      var e = document.getElementById("ConfirmPassword1");
      if (e.type === "password") {
          setpasswordeye(true)
          e.type = "text";
      } else {
        setpasswordeye(false)
          e.type = "password";
      }
  }

  

  const showConfirmPassword = (e) => {

      var e = document.getElementById("ConfirmPassword");
      if (e.type === "password") {
          setCpasswordeye(true)
          e.type = "text";
      } else {
          setCpasswordeye(false)
          e.type = "password";
      }
  }

    const userForgotpassword = async(data) =>{
      await UseForgotPassword(data)
      await sleep(1000) 
     
}
  const onSubmit = async (e) => {
    e.preventDefault();
      
      const userData = {
          user_name: Email,
          password: Password,
          confirmpassword: ConfirmPassword,
          otp:props.otp
    
      };


      const { errors, isValid } = validateForgotPassword(userData);
      if (!isValid) {
         
          seterrors(errors)
      }
      else {
       
           await userForgotpassword(userData)
         
      }
    
  };
   return(
    <div className='modal_back_image'>
    <Modal id="loginmodel" show={newpassword}  centered scrollable className='modal_style thememodal modal_big'>
   
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body>
      <form className='mb-4 logins' onSubmit={onSubmit}>
        <h3 className='headfont mb-4 text-center'>New Password</h3>
        <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>New Password</label>
                    <InputGroup className="mb-3">
        <Form.Control
       type="password"
       placeholder='New Password' 
       required
       id="ConfirmPassword1"
        onChange={(e) => { setPassword(e.target.value) }}
        className='form-control'/>
        {!passwordeye ?  <InputGroup.Text id="basic-addon2"><i className='fa fa-eye-slash'onClick={showPassword}></i></InputGroup.Text>:
        <InputGroup.Text id="basic-addon2"><i className='fa fa-eye' onClick={showPassword}></i></InputGroup.Text>}
      </InputGroup>
      <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span>

       </div>
      <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Confirm Password</label>
                    <InputGroup className="mb-3">
        <Form.Control
        type="password"
        placeholder='Confirm Password' 
        required
        id="ConfirmPassword"
        onChange={(e) => { setConfirmPassword(e.target.value) }}        
       className='form-control'/>
        {!Cpasswordeye ? <InputGroup.Text id="basic-addon2"><i className='fa fa-eye-slash'onClick={showConfirmPassword}></i></InputGroup.Text> :
        <InputGroup.Text id="basic-addon2"><i className='fa fa-eye'onClick={showConfirmPassword}></i></InputGroup.Text>}
      </InputGroup>
      <span className="text-danger f-12 mb-3 d-block text-left">{errors.confirmpassword}</span>

       </div>
           
          

        <div className='w-full mb-3'>
          <button className='btn btn-theme w-full' >Reset</button>
        </div>
     


       
        
      </form>
    </Modal.Body>

</Modal>
  </div>
   )
}

export default NewPassword
import React, { useState ,useEffect} from 'react';
import {Modal,Form,Button} from 'react-bootstrap'
import { UseTransactionHash } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../../Hooks/UseToast'

import check from '../../Assets/Images/check.png'
import { getAllFeeInfo, sleep } from '../../Hooks/UseBackendAction/UseGetController.js';


const Transaction = (props) => {
    const[transaction, setTransaction] =useState(true)
    const[hash, setHash] =useState("")
    const[showtoken , setShowToken] = useState(false)
    const [Network,setNetwork] = useState("")
    const [depositFee,setdepositFee] = useState("");
     const [submitBtn,setsubmitBtn] = useState("Submit");
    
    const onSubmit = async (e) => {
        e.preventDefault();

        if(hash.length == 66 || hash.length == 88){

            const userData = {
                transactionhash: hash,
                Network :Network,
                userAddress : props?.walletaddress
         
           };
           await UseTransactionHash(userData)
           
        }else{

        

        toast.error("Transaction hash is invalid", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          })
        }
     
       
     }

     useEffect(() => {
        getData()
       
    
      }, [])
    
     const getData = async () => {

       
        var feedata =  await getAllFeeInfo();
              setdepositFee(feedata?.data[0]?.depositFee);
              
      
             
      }
    return(
        <>
           <Modal size="md" show={transaction}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
  <Modal.Header className='border-0 pb-0'>
 
       <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only"></span></button>
  </Modal.Header>
  <Modal.Body className='p-lg-4 themescroll'>
   
  <form className='mb-0' onSubmit={onSubmit}>

  <div className='notecont py-0 mt-3'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-6 border_right_note'>
                            <div className='pt-4 pb-3'>
                        <li className='mt-2'> <p className=''>
                            <img src={check} />
                            <span>Important: Paste your  transaction hash here</span></p></li>
                       
                                </div>
                        </div> 
                        <div className='col-12 col-md-6'>
                        <div className='pt-4 pb-3'>
                        <li className='mt-2'><p><img src={check} />Don't make mistake entering same  transaction hash again</p></li>
                     </div>
                        </div>

                    </div>
                   
                </div>


 
    <h5 className='headfont mt-4 label_input_font'>Transaction Hash</h5>
   
   
    <div className='wizardinput'>
                <input type="text"
                 onChange={(e) => { setHash(e.target.value) }} 
                 required className='form-control' placeholder='Transaction hash'/>
            </div>

   <h5 className='headfont mt-3 label_input_font text-right'>Deposit Fee - {depositFee}%</h5>
            <h5 className='headfont mt-3 label_input_font'>Select Network</h5>
   
   <div  onClick={() => setShowToken(!showtoken)} className='wizardinput position-relative selectbox'>
       {/* <p className='f-12'>Network</p> */}
       <span className='mt-2 d-inline-block'>{Network ? Network :"Select Network"}</span>
       <Button variant="link" className="nounder text-light"><spna className={showtoken ? "fa fa-chevron-up" :"fa fa-chevron-down"}></spna> </Button>
       <div className={showtoken ? 'selecttoken' : 'd-none'}>
           <Form.Check required type="radio" onChange={(e) => {setNetwork (e.target.value);setShowToken(false)}} name="token" value="Solana"label="Solana" />
           <Form.Check required type="radio"onChange={(e) => {setNetwork (e.target.value);setShowToken(false)}} name="token" value="Ethereum ERC20"label="Ethereum"/>
           <Form.Check required type="radio" onChange={(e) => {setNetwork (e.target.value);setShowToken(false)}}name="token" value="BSC BEP20"label="BSC BEP20" />
       </div>
   </div>



               
            <div className='w-full mt-lg-0 mt-3 text-center'>
        
        
        <button className='btn btn-theme mt-4 py-2 me-2 btn_big_theme_border'onClick={() => setsubmitBtn("Pending...")}>{submitBtn}</button>
        
        </div>
   </form>
</Modal.Body>

</Modal></>
    )
}

export default Transaction
import axios from "axios";
import * as actionTypes from '../../store/actions';
import * as config from "../../config/env";
import isEmpty from "is-empty";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../store/setAuthToken";
import { sleep } from "./UseGetController";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../UseToast';
import $ from "jquery";


export const UseHeaders = () => {
    const accessToken = sessionStorage.getItem("Token");

    const headers = {
        headers: { Authorization: `Bearer ${accessToken}` }
    }
    return headers;
}

export const UseRefreshToken = () => {

    const refreshtoken = { refreshtoken: sessionStorage.getItem("Token") }
    return refreshtoken;
}

export const UseCreateUser = (param) => {
    try {
        axios
            .post(config.backurl + "/api/user/create", param)
            .then(async (res) => {
                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.data}`, {
                        position: position.position,
                        style: style,
                        iconTheme: iconTheme,
                    })
                    setTimeout(() => {

                        window.location.href = config.Landingpage;
                    }, 1000);

                } else {
                    {
                        toast.error(`${res.data.data}`, {
                            position: position.position,
                            style: style,
                            iconTheme: iconTheme,
                        })

                    }
                }

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};
export const UseloginUser = async (param) => {


    let result;
    try {

        axios
            .post(config.backurl + "/api/user/login", param)
            .then(async (res) => {
                console.log("res", res.data);
                if (res.status === 200 && res.data.success === true) {

                    // sessionStorage.setItem("Token",res.data.accessToken)
                    sessionStorage.setItem("username", res.data.user_name ? res.data.user_name : undefined)
                    sessionStorage.setItem("userLoginmail", res.data.email ? res.data.email : undefined)
                    sessionStorage.setItem("isauth", res.data.isAuth === true ? "true" : "false")
                    toast.success(`${res.data.data}`, {
                        position: position.position,
                        style: style,
                        iconTheme: iconTheme,
                    }
                    )
                    // setTimeout(() => {
                    //    window.location.href = config.frontendurl + "/dashboard";
                    // }, 1000);
                } else {
                    toast.success(`${res.data.data}`, {
                        position: position.position,
                        style: style,
                        iconTheme: iconTheme,
                    })
                }

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }
    return result;
};

export const CheckLoginOtp = (param) => {


    axios
        .post(config.backurl + "/api/user/checklogin/otp", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
                sessionStorage.setItem("Token", res.data.accessToken)
                sessionStorage.setItem("username", res.data.user_name);
                sessionStorage.setItem("userId", res.data.userId ? res.data.userId : undefined)
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/dashboard"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};

export const logoutUser = () => {

    sessionStorage.removeItem("Token")
    sessionStorage.removeItem("username")
    sessionStorage.removeItem("userLoginmail")
    window.location.href = config.Landingpage;
};


export const UseEditProfile = (param) => {


    var EditUserData = new FormData();
    if (!isEmpty(param.userId)) EditUserData.append('userId', param.userId);
    if (!isEmpty(param.user_name)) EditUserData.append('user_name', param.user_name);
    if (!isEmpty(param.first_name)) EditUserData.append('first_name', param.first_name);
    if (!isEmpty(param.last_name)) EditUserData.append('last_name', param.last_name);
    EditUserData.append('password', param.password == "" ? "" : param.password);
    EditUserData.append('currentpassword', param.currentpassword == "" ? "" : param.currentpassword);
    EditUserData.append('Country', param.Country);


    if (!isEmpty(param.file)) {
        EditUserData.append('file', param.file);

    }
    const headers = UseHeaders();
    axios
        .post(config.backurl + "/api/userprofile/update", EditUserData)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                sessionStorage.setItem("username", res.data.user_name);
                localStorage.setItem("isEdit", `${res.data.success}`)

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/profile"

            } else {

                // await UpdateRefreshRoken(res.data.isExpired,UseEditProfile,EditUserData)
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        });
};

export const Useuploadfile = (param) => {


    var KycFiledata = new FormData();
    if (!isEmpty(param.user_name)) KycFiledata.append('user_name', param.user_name);
    // if (!isEmpty(param.password)) KycFiledata.append('password', param.password);
    if (!isEmpty(param.FileType)) KycFiledata.append('FileType', param.FileType);
    if (!isEmpty(param.file)) {
        KycFiledata.append('file', param.file);

    }

    axios
        .post(config.backurl + "/api/user/kyc/file", KycFiledata)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/profile"

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};

export const UseUploadBAnkdetails = (param) => {
    axios
        .post(config.backurl + "/api/user/Bank/details", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/profile"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};





export const UseUpdateBAnkdetails = (param) => {
    axios
        .post(config.backurl + "/api/user/update/bankinfo", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/profile"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};


export const UseDepositFiat = (param) => {


    var DepositFiat = new FormData();
    if (!isEmpty(param.userId)) DepositFiat.append('userId', param.userId);
    if (!isEmpty(param.transactionID)) DepositFiat.append('transactionID', param.transactionID);
    if (!isEmpty(param.holdername)) DepositFiat.append('holdername', param.holdername);
    if (!isEmpty(param.currency)) DepositFiat.append('currency', param.currency);
    if (!isEmpty(param.amount)) DepositFiat.append('amount', param.amount);
    if (!isEmpty(param.bankname)) DepositFiat.append('bankname', param.bankname);
    if (!isEmpty(param.Accountnumber)) DepositFiat.append('Accountnumber', param.Accountnumber);
    if (!isEmpty(param.IFSCcode)) DepositFiat.append('IFSCcode', param.IFSCcode);
    if (!isEmpty(param.branch)) DepositFiat.append('branch', param.branch);
    if (!isEmpty(param.AccountType)) DepositFiat.append('AccountType', param.AccountType);
    if (!isEmpty(param.ZarcAmount)) DepositFiat.append('ZarcAmount', param.ZarcAmount);
    if (!isEmpty(param.walletaddress)) DepositFiat.append('walletaddress', param.walletaddress);
    if (!isEmpty(param.network)) DepositFiat.append('network', param.network);
    if (!isEmpty(param.adminFee)) DepositFiat.append('adminFee', param.adminFee);
    if (!isEmpty(param.depositdate)) DepositFiat.append('depositdate', param.depositdate);




    if (!isEmpty(param.file)) {
        DepositFiat.append('file', param.file);

    }

    axios
        .post(config.backurl + "/api/user/deposit", DepositFiat)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                localStorage.removeItem("holdername")
                localStorage.removeItem("currency")
                localStorage.removeItem("bankname")
                localStorage.removeItem("Accountnumber")
                localStorage.removeItem("IFSCcode")
                localStorage.removeItem("branch")

                window.location.href = config.frontendurl + "/deposit"

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};


export const UseCryptoWithdraw = (param) => {
    axios
        .post(config.backurl + "/api/user/withdraw/token", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/profile"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};



export const UseFiatWithdraw = (param) => {
    axios
        .post(config.backurl + "/api/user/withdraw/fiat", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/profile"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};


// export const UseSwap = (param) => {

//     console.log("paramparamparam",param);

//     try {
//  axios
//             .post(config.backurl + "/api/exchange/:input/:output", param)
//             .then(async (res) => {
//                 if(res.status === 200 && res.data.success === false){
//                     toast.error(`${res.data.message}`, {
//                         position:position.position,
//                         style:style,
//                         iconTheme: iconTheme,
//                     })
//                     await sleep(1000) 



//                 }else{



//                     if (res.status === 200 && res.data.success === true) {
//                         toast.success(`${res.data.message}`, {
//                             position:position.position,
//                             style:style,
//                             iconTheme: iconTheme,
//                         })
//                         console.log("response",res?.data?.data?.hash);

//                         sessionStorage.setItem("swap",res?.data?.data?.hash )

//                         await sleep(1000)
//                         // // if(sessionStorage.getItem("swap") != "" || sessionStorage.getItem("swap") != null|| sessionStorage.getItem("swap") != undefined ){

//                         //     $('#exchangeswappopup').show();
//                         // //   };


//                         window.location.href = config.frontendurl + "/exchange"

//                 }else{{
//                     toast.error(`${res.data.data}`, {
//                         position:position.position,
//                         style:style,
//                         iconTheme: iconTheme,
//                     })
// }                      await sleep(1000)
//                        window.location.href = config.frontendurl + "/exchange"


// }
//             }

//             })
//             .catch(err => {

//             }
//             );

//     }
//     catch (e) {
//         console.log("error", e);
//     }

// };





export const UseSwap = async (param) => {

  

    try {

        var res = await axios.post(config.backurl + "/api/exchange/:input/:output", param);

        if (res) {


            if (res.status === 200 && res.data.success === false) {
                toast.error(`${res.data.message}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000);
                window.location.href = config.frontendurl + "/exchange"

            } else {

                if (res.status === 200 && res.data.success === true) {
                    // toast.success(`${res.data.message}`, {
                    //     position: position.position,
                    //     style: style,
                    //     iconTheme: iconTheme,
                    // })
                   
console.log(" res?.data?.data", res?.data?.data);
                    sessionStorage.setItem("swap", res?.data?.data?.hash)
                    sessionStorage.setItem("swapAmount",res.data.message)

                    await sleep(1000)

                }

                return res;
            }
        }

}
    catch (e) {
        console.log("error", e);
    }

};




export const UseUserBridge = async(param) => {

   
var res = await axios.post(config.backurl + "/api/admin/bridge", param)

if(res){


    if (res.status === 200 && res.data.success === true) {

        toast.success(`${res.data.data}`, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
        await sleep(1000)
        window.location.href = config.frontendurl + "/bridge"
    } else {
        toast.success(`${res.data.data}`, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
    }
}
      
};

export const Check2FACode = (param) => {


    axios
        .post(config.backurl + "/api/user/authcode/:token", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
                sessionStorage.setItem("Token", res.data.accessToken)
                sessionStorage.setItem("username", res.data.user_name);
                sessionStorage.setItem("userfname", res.data.firstName);
                sessionStorage.setItem("userlname", res.data.lastName);
                sessionStorage.removeItem("isauth")
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/dashboard"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};



export const UseisAuth = (param) => {


    axios
        .post(config.backurl + "/api/user/isauth/:token", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)

                // window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const NewContact = (param) => {

    try {

        axios
            .post(config.backurl + "/api/contact/add/:info", param)
            .then(async (res) => {

                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.data}`, {
                        position: position.position,
                        style: style,
                        iconTheme: iconTheme,
                    })
                    await sleep(1000)

                    window.location.reload()

                } else {
                    {
                        toast.error(`${res.data.data}`, {
                            position: position.position,
                            style: style,
                            iconTheme: iconTheme,
                        })

                    }
                }

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};



export const UseRequestResetPassword = (param) => {


    axios
        .post(config.backurl + "/api/user/request/reset", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
                localStorage.setItem("forgotEmail", res.data.forgotemail);

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config?.Landingpage

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseForgotPassword = (param) => {


    axios
        .post(config.backurl + "/api/user/password/forgot", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                localStorage.removeItem("forgotEmail");
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
                // $('#newpasswordmodal').hide();
                window.location.href = config.frontendurl;

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};



export const UseTransactionHash = (param) => {


    axios
        .post(config.backurl + "/api/user/deposit/hash", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                // $('#newpasswordmodal').hide();
                window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseSortByDate = (param) => {

    console.log("param", param);
    axios
        .post(config.backurl + "/api/user/transactiondates", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
                // $('#newpasswordmodal').hide();
                window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseApprove = (param) => {

    try {

        axios
            .post(config.backurl + "/api/approve", param)
            .then(async (res) => {

                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.message}`, {
                        position: position.position,
                        style: style,
                        iconTheme: iconTheme,
                    })
                    await sleep(1000)
                    // window.location.href = config.frontendurl + "/exchange"

                } else {
                    {
                        toast.error("Try Again", {
                            position: position.position,
                            style: style,
                            iconTheme: iconTheme,
                        })

                    }
                }

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};





import React, { useEffect, useState } from 'react';
import { Button, Tabs, Tab, Form, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import TwoFAuth from './Modals/TwoFAuth';

import Header from './Header';
import Sidebar from './Sidebar';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import DonutChart from 'react-donut-chart';

import edit from '../Assets/Images/edit.svg'
import DataTable from 'react-data-table-component';
import EditProfile from './Modals/EditProfile';
import Preference from './Modals/Preference';
import faker from 'faker';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'

import bcoin1 from '../Assets/Images/bcoin1.png'
import bcoin2 from '../Assets/Images/bcoin2.png'
import bcoin3 from '../Assets/Images/bcoin3.png'
import bcoin4 from '../Assets/Images/bcoin4.png'
import OtpModal from '../Components/Modals/OtpModal';

import ApexCharts from 'apexcharts'
import Chart from "react-apexcharts";
import { UserFiatTransactions, UsergetInfobyname, UsetokeninfoBySymbol, getTokenPrice } from '../Hooks/UseBackendAction/UseGetController';
import { backurl } from '../config/env';
import { getChartDatas } from '../Hooks/UseBackendAction/UseAdmin';
import loaderimg from './../Assets/Images/loaderlogo.png'

// import { getV3data } from '../Hooks/UniswapV3';
// import {getPrice}  from '../Hooks/UseSwap/Useprice';
// const getPrice = require("../Hooks/UseSwap/Useprice");

ChartJS.register(
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);


// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top'  ,
//     },
//     title: {
//       display: false,
//       text: 'Chart.js Line Chart',
//     },
//   },
// };


//....... viji

// var chart = new ApexCharts(document.querySelector("#chart"), options);
// chart.render();


// var options = {
//   series: [{
//   name: 'series1',
//   data: [31, 40, 28, 51, 42, 109, 100],

// }, {
//   name: 'series2',
//   data: [11, 32, 45, 32, 34, 52, 41]
// }],
//   chart: {
//   height: 350,
//   type: 'area'
// },
// dataLabels: {
//   enabled: false
// },
// stroke: {
//   curve: 'smooth'
// },
// xaxis: {
//   type: 'datetime',
//   categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
// },
// tooltip: {
//   x: {
//     format: 'dd/MM/yy HH:mm'
//   },
// },
// };




const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];

export const data = {
  labels,
  datasets: [
    {
      // label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'yellow',
      backgroundColor: 'yellow',
    },
    {
      // label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: '#fff',
      backgroundColor: '#fff',
    },
  ],
};

const Dashboard = () => {

  const [profile, setProfile] = useState(false)
  const [preference, setPreference] = useState(false)
  const [twofact, settwofact] = useState(false)
  const [otp, setOtp] = useState(false)
  const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
  const [coindata, setcoindata] = useState([])
  const [fiatDepositdata, setfiatDepositdata] = useState([])
  const [chartDataUpdated, setChartDataUpdated] = useState(false);
  const [records,setRecords] = useState([])
  const [zarcBSCPrice,setzarcBSCPrice] = useState(0);
  const [zarcSolanaPrice,setzarcSolanaPrice] = useState(0);

  const chartoptions = {
    options: {
      // colors : ['#001489', '#f1ba0a','#1bebdb','#b76e79','#1f191b'],
      colors: ["#0d074f","#1bebdb"],
  
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: ["Last Month", "Month", "Last week", "Yesterday", "Today"]
      },
      dataLabels: {
        enabled: false
      },
  
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
  
    },
    series: [
      {
        name: "ZARC",
        data: records.length > 0 ? records.find((el)=> el.token == "ZARC")?.chartData : [0.069172, 0.019172, 0.029172, 0.0491720, 0.059172],
  
      },
  
      {
        name: "USD",
        data: [0.0003, 1.0003, 0.0003, 1.0003, 1.0003]
        // data: [ 0.0003, 1.0003, 0.0003, 1.0003,1.0003]
      },
      // {
      //   name: "EUR",
      //   data: [ 0.105482, 2.95482, 0.85482, 0.65482, 0.95482]
      // },
      // {
      //   name: "GBP",
      //   data: [ 5.82644, 1.82644, 0.52644, 1.6644, 0.82644]
      // }
    ],
  }
  const columns = [
    {
      name: "Currency",
      selector: (vals) => vals?.currency,
      // sortable: true
    },

    {
      name: "Deposit Amount",
      selector: (vals) => vals?.amount,
      sortable: true,
      // minWidth:"150px"
    },
    {
      name: "ZARC Amount",
      selector: (vals) => Number(vals?.ZarcAmount).toFixed(7),
      sortable: true,
      // minWidth:"150px"
    },
    {
      name: "Status",
      selector: (vals) => (vals.isApproved == null ?
        <Button variant='outline-secondary' className='samewidth'>Pending</Button> : vals.isApproved == true ?
          <Button variant='outline-success' className='samewidth'>Completed</Button> : <Button variant='outline-danger' className='samewidth'>Canceled</Button>),
      sortable: true
    },

    {
      name: "Time",
      selector: (vals) => (vals.updatedAt.substring(0,10)),
      sortable: true
    }


  ]
 
  const [chart, setchart] = useState(chartoptions)
  const [showloader,setShowloader] = useState(false)


  const showLoad = ()=>
  {
    document.getElementsByTagName("body")[0].classList.add("body_over_hidden");
    setShowloader(true);
  }


  const hideLoad = ()=>
  {
    document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");
    setShowloader(false);

  }

  useEffect( () => {
    document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

    showLoad();

    getChart()
  //  getprice()
    getUserBalance();
    GetTokenPrice();
    // getswap()
    // getChartdataUSD
    document.getElementById("balance").classList.add("blur")
    document.getElementById("balance1").classList.add("blur")
    document.getElementById("balance2").classList.add("blur")
    document.getElementById("shownotes").classList.remove("d-none")

    // document.getElementById("balance3").classList.add("blur")
  }, []);

  // const getprice = async ()=>{
  //   var data  = await getPrice();
  //   console.log("price",data);
  // }

  const getUserBalance = async () => {
    var UserInfo = await UsergetInfobyname(UserName);
 var fiatStatus = await UserFiatTransactions(UserInfo?.data?.userId);
 const chartData = await getChartDatas();
 setRecords(chartData)
console.log("UserInfo",fiatStatus);
 if(fiatStatus?.success === true) {
   setfiatDepositdata(UserInfo.success ? fiatStatus && fiatStatus?.data?.reverse() : [])
  };

    var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
      var dat = await UsetokeninfoBySymbol(item?.symbol)

      dat['data']['Balance'] = item
      return dat['data']

    }))


    if (tokenSymbol.length > 0) {
      setcoindata(tokenSymbol)
      document.getElementById("balance").classList.remove("blur")
      document.getElementById("balance1").classList.remove("blur")
      document.getElementById("balance2").classList.remove("blur")
      document.getElementById("shownotes").classList.add("d-none")
      // document.getElementById("balance3").classList.remove("blur")
    }

    hideLoad()

  }

  
// const getswap =async () =>{
//   const data = await getV3data();
//   console.log("datadatadata",data);

// }

const GetTokenPrice = async () =>{
  var tokenprice = await getTokenPrice();

  
  setzarcBSCPrice((1/ parseFloat(tokenprice?.data?.zarcBSC)).toFixed(5));
  setzarcSolanaPrice((1 / parseFloat(tokenprice?.data?.zarcSolana)).toFixed(5))
  

}


  const getChartdataUSD = () => {



    setchart({
      ...chart, ...{
        options: { ['colors']: ["#0d074f","#1bebdb"] },
        series: [chart.series[0], { name: "USD", data: [0.0003, 1.0003, 0.0003, 1.0003, 1.0003] }]
      }
    })
   
    setChartDataUpdated(true);

  }


  const getChartdata = () => {


    setchart({
      ...chart, ...{
        options: { ['colors']: ["#0d074f","#b76e79"] },
        series: [chart.series[0], { name: "ZAR", data: records.length > 0 ? records.find((el)=> el.token == "ZAR")?.chartData : [3.07715, 10.07715, 13.07715, 14.07715, 17.07715] }]
      }
    })

    setChartDataUpdated(true);

  }

  const getChartdataEUR = () => {

    setchart({
      
      ...chart, ...{
        options: { ['colors']: [ "#0d074f","#e03a48"] },
        series: [chart.series[0], { name: "EUR", data: records.length > 0 ? records.find((el)=> el.token == "EUR")?.chartData : [0.105482, 2.95482, 0.85482, 0.65482, 0.95482] }]
      }
    })

    setChartDataUpdated(true);
}


  const getChartdataGPB = () => {

    setchart({
      ...chart, ...{
        
        options: { ['colors']: ["#0d074f","#c3cc12"] },

        // options: { ['colors']: ["#0d074f",chart.options.colors[1]] },
        series: [chart.series[0], { name: "GBP", data: records.length > 0 ? records.find((el)=> el.token == "GBP")?.chartData : [5.82644, 1.82644, 0.52644, 1.6644, 0.82644] }]
      }
    })

    setChartDataUpdated(true);

  }

  const getChart = async () => {
    let oldintervalId = localStorage.getItem('interval')
    if (oldintervalId) {
      clearInterval(oldintervalId);
      localStorage.removeItem('interval')
    }
    const intervalId = setInterval(async () => {
      const chartData = await getChartDatas();
      setRecords(chartData)
    }, 18000000)
    localStorage.setItem('interval', intervalId)
  }
  
  return (
    <>
      <div className='dashboard dashsec'>
        <div className='bggrad'></div>
        <Header />
        <div className='page'>
          {otp && <OtpModal onDismiss={() => setOtp(false)} />}
          {profile && <EditProfile onDismiss={() => setProfile(false)} />}
          {preference && <Preference onDismiss={() => setPreference(false)} />}
          {twofact && <TwoFAuth onDismiss={() => settwofact(false)} />}
          <div className='sidebar'><Sidebar /></div>
          <div className='content'>
          {showloader?
            <div className='loader_overlay'>
            <div className='loader_div'>
              <img src={loaderimg} className='img-fluid loader_logo' />
            </div>
          </div>
          :
          <></>
            }
           
            <div className='container-fluid container-theme p-4'>

              <h2 className="headfont mb-sm-5">Dashboard</h2>

              <div className='row dashbox text-center position-relative'>
              <div id='shownotes'> Please make a deposit to have full access to the site
</div>
                
                {coindata && coindata.length < 0 ? <> {coindata && coindata.map((item) =>
                  <div className={`col-lg-3 col-sm-6 my-sm-2 my-4 ${item.symbol === "ZARC" || item.symbol === "ZARCSol"? "d-none" : ""}`} >

                    <div className='hoverbox box p-1'>
                      <div className='inner_hover_box ps-4 py-4'>
                        <div className='d-flex align-items-center'>
                      <img src={backurl + `/Images/${item?.logoURI}`} />
                      <div className='ms-3 text-left'>
                      <h5 className='headfont mb-1'>{parseFloat((item?.Balance?.amount).toFixed(3)) || 0}</h5>
                      {/* <p><span className='fa fa-bolt lightgreen'></span> <span className='lightgreen'> 45%</span> {item.symbol}</p> */}
                      <p className='mb-0 text-left'>{item.symbol}</p>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                )}

                

                </> 
                
                : 
                <>
                <div id="balance" className='col-lg-3 col-sm-6 my-sm-2 my-4'>
               
                  <div className='hoverbox box p-1'>
                  <div className='inner_hover_box ps-4 ps-lg-2 py-4'>
                      <div className='d-flex align-items-center'>
                    <img src={bcoin2} />
                    <div className='ms-3 text-left'>
                    <h5 className='headfont mb-1 text-left'>BSC-ZARC</h5>
                    <p className='mb-0 text-left'><span className='fa fa-bolt lightgreen'></span> {zarcBSCPrice}</p>
                 </div>
                 </div>
                 </div>
                  </div>
                </div>
                <div id="balance1" className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                <div className='hoverbox box p-1'>
                  <div className='inner_hover_box ps-4 ps-lg-2 py-4'>
                      <div className='d-flex align-items-center'>
                    <img src={bcoin2} />
                    <div className='ms-3 text-left'>
                    <h5 className='headfont mb-1 text-left'>SOLANA-ZARC</h5>
                    <p className='mb-0 text-left'><span className='fa fa-bolt lightgreen'></span> {zarcSolanaPrice}</p>
                 </div>
                  </div>
                  </div>
                  </div>
                  </div>
                 
                <div id="balance2" className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                <div className='hoverbox box p-1'>
                  <div className='inner_hover_box ps-4 ps-lg-2 py-4'>
                      <div className='d-flex align-items-center'>
                    <img src={bcoin2} />
                    <div className='ms-3 text-left'>
                    <h5 className='headfont mb-1 text-left'>ETH-ZARC</h5>
                    <p className='mb-0 text-left'><span className='fa fa-bolt lightgreen'></span> {zarcBSCPrice}</p>
                 </div>
                  </div>
                </div>
                </div>
                </div>

                {/* <div id="balance3" className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                 <div className='hoverbox box p-1'>
                  <div className='inner_hover_box ps-4 ps-lg-2 py-4'>
                  <div className='d-flex align-items-center'>
                    <img src={bcoin2} />
                    <div className='ms-3 text-left'>
                    <h5 className='headfont mb-1 text-left'>$999.30</h5>
                    <p className='mb-0 text-left'><span className='fa fa-bolt lightgreen'></span> <span className='lightgreen'> 45%</span> This week</p>
                 </div>
                 </div>
                  </div>
                </div>
                </div> */}
              
                </>
                  }


                {/* <div className='col-lg-3 col-sm-6 my-lg-2 my-4'>
                    <div className='hoverbox box ps-4 pt-4 br-6'>
                        <img src={bcoin3} />
                        <h5 className='mt-5 headfont'>$999.30</h5>
                        <p><span className='fa fa-bolt text-danger'></span> <span className='text-danger'> 45%</span> This week</p>
                    </div>
                </div> */}
                {/* <div className='col-lg-3 col-sm-6 my-lg-2 my-4'>
                    <div className='hoverbox box ps-4 pt-4 br-6'>
                        <img src={bcoin4} />
                        <h5 className='mt-5 headfont'>$999.30</h5>
                        <p><span className='fa fa-bolt lightgreen'></span> <span className='lightgreen'> 45%</span> This week</p>
                    </div>
                </div> */}
              </div>
              <div className='row '>
                <div className='col-lg-6 mt-3'>
                  <div className='box mh-300 br-6 px-0 pt-2 position-relative'>
                    <div className='d-xl-flex gap-2 d-block p-3 pb-0 jc-between'>
                      <div>
                        <h4 className='headfont headfont_normal mb-0 '>Market overview</h4>
                        {/* <p className='mb-0 f-12 font_gery_new'>lorem ipsum is dummy cont</p> */}
                      </div>
                      <div className='d-flex gap-xl-4 jc-between align-items-center mt-xl-0 mt-3'>
                        <div className='f-12'>
                          <Form.Check inline label="ZAR" value="ZAR" name="group1" onChange={() => getChartdata()} type="radio" />
                          <Form.Check inline label="USD" value="USD" name="group1" onChange={() => getChartdataUSD()} type="radio" defaultChecked/>
                          <Form.Check inline label="GBP" value="GBP" name="group1" onChange={() => getChartdataGPB()} type="radio" />
                          <Form.Check inline label="EUR" value="EUR" name="group1" onChange={() => getChartdataEUR()} type="radio" />
                        </div>
                        {/* <Dropdown>
                              <Dropdown.Toggle className='btn btn-theme' id="dropdown-basic">
                                Monthly 2022
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Monthly</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Weekly</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Yearly </Dropdown.Item>
                              </Dropdown.Menu>
                          </Dropdown> */}
                      </div>


                    </div>

                    <hr className='themehr' />

                    <div className='px-0 '>  {/*  chartbox */}
                      <div className='d-flex jc-center'>
                        {/* <Line  data={data} options= {options}/> */}



                        {/* viji .......... */}
                        <div className='w-100'>
                          {/* {chartDataUpdated &&   */}
                          <Chart
                            options={chart?.options}
                            series={chart?.series}
                            type="area"
                          />
                          {/* } */}
                          {/* <div id="chart"></div> */}
                        </div>



                      </div>
                      {/* <div className='text-end mb-4 pb-1'>
                        <NavLink to="/history"><button className='btn btn-theme'>View more</button></NavLink></div> */}
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 mt-3'>
                  <div className='box px-3 br-6 tabtable nohead pt-2 potfolio h-100 '>
                    <div className='p-3'>
                      <h4 className='headfont headfont_normal mb-0'>Fiat Deposit status</h4>
                      <p className='f-12 font_gery_new'>Track fiat deposit status and history in real-time</p>
                    </div>
                    <div className='portscroll themescroll dashboard_tae_data'>
                      <DataTable columns={columns} data={fiatDepositdata} />
                    </div>
                    <NavLink to="/fiat">  <button className='btn btn-border-only viewmore'><span className='fa fa-chevron-down'></span> </button></NavLink>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
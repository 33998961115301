import React, { useState, useEffect, useTransition } from 'react';
import { DropdownButton, Dropdown, Tabs, Tab, Button, Row, Col, Nav } from 'react-bootstrap'
import Header from './Header';
import Sidebar from './Sidebar';
import aquirelogo from './../Assets/Images/acquirelogo.svg'
import fiat from './../Assets/Images/fiat.png'
// import crypt from './../Assets/Images/crypt.png'
import fiatbg from './../Assets/Images/fiatbg.png'
import check from './../Assets/Images/check.png'
import copy from './../Assets/Images/copy.png'
import qr from './../Assets/Images/qr.png'
import QRCode from "react-qr-code";
import toast, { Toaster } from 'react-hot-toast';
import flag from './../Assets/Images/flag.jpg'
import CopyToClipboard from "react-copy-to-clipboard";
import { style, iconTheme, position } from '../Hooks/UseToast'
import ViewDetails from './Modals/ViewDetailsModal';
import DepositHint from './Modals/DepositHint';

import TransferedFunds from './Modals/TransferedFunds';
import {UsergetInfobyname, getAllFeeInfo } from '../Hooks/UseBackendAction/UseGetController';
import { useLocation } from 'react-router-dom';
import Transaction from './Modals/Transaction';


const Deposit = () => {
    const [currency, setCurrency] = useState(true)
    const switchto = (e) => {
        e.target.checked ? setCurrency(false) : setCurrency(true)
    }
    const [details, setDetails] = useState(false)
    const[deposithint, setDeposithint] = useState(true)

    const [funds, setFunds] = useState(false)
    const [transaction, setTransaction] = useState(false)
    const [Depositaddress, setDepositaddress] = useState("")
    const [DepositSOladdress, setDepositSoladdress] = useState("")
    const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
    const [UserInfo, setUserInfo] = useState({})
    const [isSolana, setisSolana] = useState("null")
    const [activetab, setActiveTab] = useState();
    const [depositFee,setdepositFee] = useState("");
    // var activetab= true
    const location = useLocation()


    useEffect(() => {
  document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

        if(localStorage.getItem("depositpage") == 1){
            setDeposithint(false) 
        }
       
        getdata();
    }, [activetab])


    const copyText = (a, b) => {
        toast.success("Address Copied", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
    }

    const copyCode = (a, b) => {
        toast.success("Reference code Copied", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
    }

    


    const getdata = async () => {
        var UserInfo = await UsergetInfobyname(UserName);
      
        setDepositaddress(UserInfo?.data?.walletaddress)
        setDepositSoladdress(UserInfo?.data?.solanawalletaddress)

        setUserInfo(UserInfo?.data);
        var feedata =  await getAllFeeInfo();
    setdepositFee(feedata?.data[0]?.depositFee);
    }


    return (
        <>
            {transaction && <Transaction walletaddress={UserInfo?.walletaddress} onDismiss={() => setTransaction(false)} />}
            {details && <ViewDetails refcode={UserInfo?.userId} status={true} onDismiss={() => setDetails(false)} open={() => setFunds(true)} />}
            {funds && <TransferedFunds onDismiss={() => { setFunds(false); }} />}
            {deposithint && <DepositHint onDismiss={() => setDeposithint(false)}  />}

            <div className='dashboard acquire'>
                <div className='bggradleft'></div>
                <div className='bggradright'></div>

                <Header />
                <div className='page depositpage'>
                    <div className='sidebar'><Sidebar /></div>
                    <div className='content redeem'>
                        <div className='container-fluid container-theme p-4 position-relative'>
                            <div className='mb-4 deposit_topbtns'>
                                
                                <h2 className='headfont'>Deposit</h2>
                                <Button className="btn btn-theme deposit_btns" onClick={() => setDeposithint(true)}>Hint</Button>

                            </div>

                            <div className='conts'>
                                <div className='row deposittabs'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 '>

                                    <Tab.Container id="left-tabs-example" defaultActiveKey={location?.state == "deposit" ? "deposit" : "fiat"}>
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className="tab_stl_new">
            <Nav.Item>
              <Nav.Link eventKey="fiat">
                <div>Fiat</div>
                
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="deposit">
                <div>
                Crypto
                </div>
                </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="fiat">
                <div className='card card_cutout mt-3'>

             
            <div className='text-center rounded_img_border'>   
            <img src={fiat} className="" />
            </div>
    <div className='p-lg-5 p-sm-4 p-3'>
        <div className='row jc-center'>
            <div className='col-xl-12 col-lg-12 col-md-12 pos_show_rel'>

              

                <div className='notecont py-0'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-6 border_right_note'>
                            <div className='py-4'>
                        <li className='mt-2'> <p className=''>
                            <img src={check} />
                            <span>Important: Deposit funds exclusively from a bank account registered in your name.</span></p></li>
                        <li className='mt-2'> 
                            <p className=''><img src={check} />
                            <span>
                               
                            	Do not deposit the same amount multiple times within a day.</span></p></li>
                                </div>
                        </div> 
                        <div className='col-12 col-md-6'>
                        <div className='py-4'>
                        <li className='ps-md-4 mt-2'><p><img src={check} /><span>	We accept Electronic Fund Transfers (EFTs) only; cash and cheques are not accepted.</span></p></li>
                        <li className='mt-2 ps-md-4'><p><img src={check} /><span>	For deposits and withdrawals, only South African bank accounts are permitted. </span></p></li> 
                     </div>
                        </div>

                    </div>
                   
                </div>
                {/* <h3 className='headfont mb-3  mt-4 label_input_font'>Bank transfer</h3> */}
                <div className='row'>
                    {/* <div className='col-12 col-md-6'>
                    <div className='bg-black  ps-3 codecopy'>
                        <p className='mb-0'>
                    Ref code : <span className='color_grey_theme '>{UserInfo && UserInfo?.userId}</span>
                    </p>
                    <CopyToClipboard text={`${UserInfo && UserInfo?.userId}`} onCopy={() => copyCode('invite link', `${UserInfo && UserInfo?.userId}`)}>
                        <Button variant='link' className='nounder text-light  '><img src={copy} /> </Button>
                    </CopyToClipboard>
                </div>
                    </div> */}
                    <div className='col-12 col-md-12 mt-4 mt-md-4'>
                    <div className='text-center'>
                    <Button className="btn btn-theme " onClick={() => setDetails(true)}>Deposit</Button>
                </div>
                    </div>
                </div>
               
              
            </div>
        </div>
    </div>
    </div>
            </Tab.Pane>
            <Tab.Pane eventKey="deposit">
            <div className='card card_cutout mt-3'>
            <div className='px-lg-5 pb-3 pt-1'>
            <div className='row jc-center'>
        <div className='col-xl-12 col-lg-12 col-md-12'>
            <div className='row pos_show_rel'>
                <div className='col-12 col-lg-7'>
                <div className=''>
                    <div className='row'>
                        <div className='col-12 col-lg-5 col-xl-4'>
                        <div className='qrcode'><QRCode size="110" value={isSolana === "true" ? DepositSOladdress :  isSolana === "null" ?  Depositaddress : Depositaddress} /></div>

                        </div>
                        <div className='col-12 col-lg-7 col-xl-8'>
                        <h3 class="headfont mb-3  mt-4 label_input_font">Deposit funds to your ZARC Wallet</h3>

                        <div className='showappeare rr'>
                    <select className='form-control'
                        onChange={(e) => setisSolana(e.target.value)}>
                        <option value="null">to your ZARC wallet address (BEP 20)</option>
                        <option value="false">to your ZARC wallet address (ERC 20)</option>
                        <option value="true">to your ZARC wallet address (Solana)</option>



                    </select>
                    <span className='fa fa-chevron-down text-light-white'></span>
                </div>
                            </div>
                    </div>
                    {/* <img src={qr}  /> */}
                </div>
             
                </div>
                <div className='col-12 col-lg-5'>
                <div className='my-4 '>
                
                <div className='text-center mt-4'>
                <h3 class="headfont mb-3  mt-4 label_input_font heigt_set_lael_md">Your Receive Address</h3>


                <div className='bg-black bg_black_bog_copy ps-3 codecopy'>
                        <p className='mb-0 breakword text-left'>
                        {isSolana === "true" ? DepositSOladdress : Depositaddress}
                    </p >
                    <CopyToClipboard text={`${isSolana === "true" ? DepositSOladdress : Depositaddress}`} onCopy={() => copyText('invite link', `${isSolana ? DepositSOladdress : Depositaddress}`)}>
                        <Button variant='link' className='codecopy nounder text-light'><img src={copy} /></Button>
                    </CopyToClipboard>
                </div>
                <h3 class="font_12_green mb-3 mt-1 lightgreen text-right">Deposit Fee  - {depositFee} % </h3>




                   
                </div>
            </div>
                    </div>
            </div>
            <div className='notecont py-0 mt-4'>
                <p className='pos_ab_note'>Please Note:</p>
                    <div className='row'>
                        <div className='col-12 col-md-6 border_right_note'>
                            <div className='py-4'>
                            <li className='mt-2'> <p className=''><img src={check} />
                            Important : If a deposit is made kindly wait to reflect on the platform. Based on the blockchain interaction it will take few minutes to reflect
</p></li>
                    

                            <li className='mt-2'> <p className=''><img src={check} />
                        Send only ZARC to this deposit address. Sending any other coins or tokens to this address may result in a loss of your funds.</p>
                    </li>
                    <li className='mt-2'> <p className=''><img src={check} />
                        Don't send coin on any other chain expect {isSolana == "true" ? " Solana" : isSolana == "null" ? "BEP20" : isSolana =="false" ?  "ERC20" :  "BEP20"} to this address.</p>
                    </li>
                                </div>
                        </div> 
                        <div className='col-12 col-md-6'>
                        <div className='py-4'>
                        <li className='mt-2'><p><img src={check} />Coin will be deposited immediately after 2 network confirmations.</p></li>
                    {/* <li className='mt-2'><p><img src={check} /><span>If a deposit is made kindly wait to reflect on the platform. Based on the blockchain interaction it will take few minutes to reflect
                       
                    </span> </p></li> */}
                     </div>
                        </div>

                    </div>
                   
                </div>
                {/* <div className='text-center mt-3'>
                 <button className='btn btn-theme btn_trans_if mt-3 text-center ps-0 pt-0 pb-0 nounder' onClick={() => setTransaction(true)}>If you deposited,Submit transaction hash here</button>
                 </div> */}
           
        </div>
    </div>
    </div>
    </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>


                                     
                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default Deposit
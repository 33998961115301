import Web3 from "web3";
import { EthRPC_URL, RPC_URL } from "../../config/env";
import { ERC20_ABI } from "../../config/erc20";

export const UseWeb3 = async()=> {
    const RPCURL = RPC_URL;
    const httpProvider = new Web3.providers.HttpProvider(RPCURL);
    const web3 = new Web3(httpProvider);
    return web3;
}

export const UseWeb3Eth = async()=> {
    const RPCURL = EthRPC_URL;
    const httpProvider = new Web3.providers.HttpProvider(RPCURL);
    const web3 = new Web3(httpProvider);
    return web3;
}


export const UseERC20 = async (VALUE) =>{
    const web3 = await UseWeb3();
    const contract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return contract;                
}

export const UseERC20Eth = async (VALUE) =>{
    const web3 = await UseWeb3Eth();
    const contract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return contract;                
}
import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import prof from '../../Assets/Images/prof1.jpg'
import { UseCreateUser } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { validateUserRegister } from '../../Hooks/UseValidation';

const Register = (props) => {

    const[register, setRegister] = useState(true)
    const [userName, setUserName] = useState("")
    const [Email, setEmail] = useState()
    const [MobileNo, setMobileNo] = useState("")
    const [Password, setPassword] = useState()
    const [Confirmpassword, setConfirmpassword] = useState("")
    const [walletaddress, setwalletaddress] = useState("")
    const [errors, seterrors] = useState({})
    const [isAccept, setisAccept] = useState(false)
    const [passwordneweye, setpasswordneweye] = useState(false)
    const [passwordconfirmeye, setpasswordconfirmeye] = useState(false)
     const label = <>I accept and agree to the <a target='_blank' href="https://docs.neonomad.finance/nni/tokenomics-fundamentals/neonomad-terms-of-use" className='aqua me-2'>Terms and Conditions</a>  
     {/* and<a href="#" className='aqua  ms-2'> Privacy Policy</a>  */}
     </>


     const showNewPassword = (e) => {

      var e = document.getElementById("password");
      if (e.type === "password") {
          setpasswordneweye(true)
          e.type = "text";
      } else {
          setpasswordneweye(false)
          e.type = "password";
      }
  };

  const showConfirmPassword = (e) => {

      var e = document.getElementById("confirmPassword");
      if (e.type === "password") {
          setpasswordconfirmeye(true)
          e.type = "text";
      } else {
          setpasswordconfirmeye(false)
          e.type = "password";
      }
  }

   const onchangeCheck=()=>{
      if(isAccept==false){
          setisAccept(true)
      }
      else{
        setisAccept(false)
      }
  }
    const onRegisterSubmit = async (e) => {

      e.preventDefault();
      const UserValidateData = {
          user_name: userName,
          email: Email,
          mobile_no: MobileNo,
          password: Password,
          confirmpassword: Confirmpassword,
          
      };
      const UserData = {
          user_name: userName,
          email: Email,
          mobile_no: MobileNo,
          password: Password,
          confirmpassword: Confirmpassword,
          // walletaddress:walletaddress.toLocaleLowerCase(),

      };
      
      const { errors, isValid } = await validateUserRegister(UserValidateData);
      if (!isValid ) {
         seterrors(errors)
      }else {
        await UseCreateUser(UserData)
      }
};
   return(
    <div className='modal_back_image'>

    <Modal show={register}  centered scrollable className='modal_style thememodal modal_big'>
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={onRegisterSubmit} className='mb-4 logins'>
        <h3 className='headfont mb-4 text-center'>Register</h3>
        <div className='row'>
          <div className='col-12 col-md-6'>
        <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Username</label>
                    <input 
                     required 
                     value={userName} 
                     maxLength="20"
                     pattern="^[a-z0-9_\-]+$"
                     title="Only Accept Lower Case"
                     onChange={(e) => { setUserName(e.target.value)}} 
                     placeholder='Enter Username' 
                     className='form-control'/>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Email</label>
                    <input 
                    required 
                    type="mail"
                    value={Email} 
                    onChange={(e) => { setEmail(e.target.value) }} 
                    placeholder='Enter Email' 
                    className='form-control'
                    />
                  <span className="text-danger f-12 mb-3 d-block text-left">{errors.email}</span>

            </div>
            </div>

            {/* <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Wallet Address</label>
                    <input 
                    required
                     value={walletaddress}
                     onChange={(e) => { setwalletaddress(e.target.value)}} 
                     placeholder='Enter Wallet Address' 
                     type="text"
                     className='form-control'/>
            </div> */}
            <div className='col-12 col-md-12'>
            <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Phone Number</label>
                    {/* <input value={MobileNo}
                    pattern="[1-9]{1}[0-9]{9}"
                    title="Enter 10 digit Mobile Number"
                    maxlength="10"
                    required
                    onChange={(e) => { setMobileNo(e.target.value) }}
                     placeholder='Enter Phone Number' 
                     className='form-control'/> */}
                        <InputGroup className="mb-0 pb-0">
                    <Form.Control
                     value={MobileNo}
                      // pattern="^\+(?=.{15})\d{10,15}_{0,5}$"
                    //  title="Enter 15 digit Mobile Number"
                     maxlength="10"
                     minlength="10"
                     required
                      // type='number'
                     onChange={(e) => { setMobileNo(e.target.value) }}
                      placeholder='Enter Phone Number' 
                      className='form-control' />
                     </InputGroup>
                   {/* <span className="text-danger f-12 mb-3 d-block text-left">{errors.mobile_no}</span> */}

            </div>
            </div>
            <div className='col-12 col-md-6'>
           <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Enter Password</label>
                    {/* <input placeholder='Password' 
                    required
                    value={Password}
                    id="password"
                    onChange={(e) => { setPassword(e.target.value) }}
                    type="password" className='form-control'/> */}
                  <InputGroup className="">
                    <Form.Control
                     placeholder='Password' 
                     required
                     value={Password}
                     id="password"
                     onChange={(e) => { setPassword(e.target.value) }}
                     type="password" className='form-control inpit_without_bor' />
                     {!passwordneweye ?
                    <Button id="basic-addon2" className='border_left_non_inout'><i className='fa fa-eye-slash'onClick={showNewPassword}></i></Button> :
                    <Button id="basic-addon2" className='border_left_non_inout'><i className='fa fa-eye'onClick={showNewPassword}></i></Button>}
                  </InputGroup>
                   <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span>

            </div>
            </div>
            <div className='col-12 col-md-6'>
            <div className='wizardinput w-full mb-3'>
                    <label className='mb-2'>Confirm new  Password</label>
                    {/* <input placeholder='Password' 
                    type="password" 
                    required
                    id="Confirmpassword"
                    value={Confirmpassword}
                    onChange={(e) => { setConfirmpassword(e.target.value) }}
                    className='form-control'/> */}
                    <InputGroup className="">
                    <Form.Control
                     placeholder='Password' 
                     type="password" 
                     required
                     id="confirmPassword"
                     value={Confirmpassword}
                     onChange={(e) => { setConfirmpassword(e.target.value) }}
                     className='form-control'/>
                    {!passwordconfirmeye ?
                    <Button id="basic-addon2" className='border_left_non_inout'><i className='fa fa-eye-slash'onClick={showConfirmPassword}></i></Button> :
                    <Button id="basic-addon2" className='border_left_non_inout'><i className='fa fa-eye'onClick={showConfirmPassword}></i></Button>}
                  </InputGroup>
                  <span className="text-danger f-12 mb-3 d-block text-left">{errors.confirmpassword}</span>

            </div>
            </div>
           </div>
           <label className='f-14 mb-3'><b>Note</b>: <span className='text-secondary'>Supported character for password (a-z),(A-Z),(0-9) and special characters</span></label>
            <Form.Check className='mb-3'
            type="checkbox"
            onChange={()=>onchangeCheck()}
            label={label}
          />
<div className='row'>
<div className='col-12 col-md-6 flex_cente_mfd'>
  <div className='text-start'>
            <Button variant='link' className='aqua ps-0' onClick={() => { props.onDismiss() ; props.showlogin();}}>Already have an account?</Button>
        </div>
  </div>
  <div className='col-12 col-md-6'>
  <div className='text-end'>
          {isAccept  && isAccept?
          <button className='btn btn-theme ms-auto btn_big_theme_border'>Register</button> : 
          <button disabled className='btn btn-theme ms-auto btn_big_theme_border'>Register</button>}
        </div>
  </div>
 
</div>
      
        
      </form>
    </Modal.Body>

</Modal>
  </div>
   )
}

export default Register
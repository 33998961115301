import React, { useState,useEffect } from 'react';
import {Modal, DropdownButton, Dropdown, Tabs, Tab, Row, Col, Nav} from 'react-bootstrap'
import { UseBankActive, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBankValue } from '../../Hooks/UseValidation';
import TransferedFunds from '../Modals/TransferedFunds';
import * as config from "../../config/env";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import exchange0 from '../../Assets/Images/hint/exchange0.png'
import exchange1 from '../../Assets/Images/hint/exchange1.png'
import exchange2 from '../../Assets/Images/hint/exchange2.png'





const Swapmodal = (props) =>{
   const[swapmodal, setSwapmodal] = useState(true)
 
   
 const [swapContent, setSwapcontent] = useState(3)

 const [hashlink,sethashlink] = useState("")
 
 const [swapmsg,setswapmsg] = useState("")

 useEffect(() => {
sethashlink(sessionStorage.getItem("swaphashlink") + sessionStorage.getItem("swap")) 
setswapmsg(sessionStorage.getItem("swapAmount"))

}, [])


  

   return(
    <>
    <Modal size="lg" show={swapmodal}  id="exchangeswappopup" onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big_hint'>
      <Modal.Header className='border-0 pb-0'>
      <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => {props.onDismiss(); window.location.reload()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
     
      </Modal.Header>
      <Modal.Body className='px-sm-5 p-3 themescroll'>
       
      <div className='mb-4 content viewdetails'>
      
       
        
        <h3 className='headfont text-center'>Swap</h3>
        <div className='mt-5 swap_cont_icons'>
        <div className='text-center'>
{swapContent == 1?
<>
        <div class="loaderspi">
            Please wait until your exchange has been completed..</div>

<p>Please wait until your exchange has been completed..</p>
           
        </>
:
       
       swapContent == 2?
       <>
       <i className='fa fa-times-circle-o'></i>
<p>Your exchange has been cancelled</p>

       </>
       :
       <>
       <i className='fa fa-check-circle-o'></i>
{/* <p>Your exchange has been completed successfully</p> */}
<p>{swapmsg}</p>

<div className='text-center mt-4'>
          <a target='_blank' href={hashlink} className="btn btn-theme">View Hash</a>
          </div>

       </>
        }


</div>
</div>

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default Swapmodal
import React, { useState,useEffect } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'

import DataTable from 'react-data-table-component';

import coin1 from '../../Assets/Images/coin1.png'
import coin2 from '../../Assets/Images/coin2.png'
import coin3 from '../../Assets/Images/coin3.png'
import coin4 from '../../Assets/Images/coin4.png'
import coin5 from '../../Assets/Images/coin5.png'
import { UseUploadBAnkdetails } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import { UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import {currency_list,countryList, validateBankDetails} from "../../Hooks/UseValidation"
import UpdateAccountDetails from  "./UpdateAccount"


const AccountDetails = (props) =>{
   const[accountdetails, setAccountDetails] = useState(true)
   const[updateaccountdetails, setupdateaccountdetails] = useState(false)
   const[showtoken , setShowToken] = useState(false)
   const[selected , setSelected] = useState("")
   const [currencyList,setcurrencyList] = useState(currency_list)
   const [Country,setCountry] = useState("")
   const [currency,setcurrency] = useState("")
   const [holdername,setholdername] = useState("")
   const [bankname,setbankname] = useState("")
   const [Accountnumber,setAccountnumber] = useState("")
   const [IFSCcode,setIFSCcode] = useState("")
   const [CountryList,setcountryList] = useState(countryList)
   const [bankaddress,setbankaddress] = useState("")
   const [bankcity,setbankcity] = useState("")
   const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
   const [UserInfo, setUserInfo] = useState({})
   const [errors, seterrors] = useState({})
   const [Currentrecords, setCurrentRecords] = useState({})

   const columns = [
    {
      name: "Bank Name",
       selector: (vals) => vals.bankname,
      sortable: true
    },
    {
        name: "Account Number",
        selector: (vals) => vals.Accountnumber,
        sortable: true
      },
      // {
      //   name: "Currency",
      //    selector: (vals) => vals.currency,
      //   sortable: true
      // },
      // {
      //   name: "Holder Name",
      //    selector: (vals) => vals.holdername,
      //   sortable: true
      // },
      
      // {
      //   name: "Bank Code",
      //   selector: (vals) => vals.IFSCcode,
      //   sortable: true
      // },
      // {
      //   name: "Status",
      //   selector: (vals) => vals.btn,
      //   sortable: true
      // },
      {
        name: "Date",
        selector: (vals) =>  (new Date(vals.time).toDateString()),
        sortable: true
      },
      {
        name: "Edit",
        selector:(vals) =>  <button className='btn btn-theme iconbtn w-28'   onClick={() => {editRecord(vals);setupdateaccountdetails(true)}}><span className='fa fa-edit' ></span></button>,
        sortable: true
      }
      
   
]  

const editRecord = async (record) => {
  setCurrentRecords(record)
}
const vals = [
  {
    img:"Default",
    time: "10:24:05 AM",
    token: "BSC",
    count: "+36",
    btn: <Button variant='outline-success'>Completed</Button>,
    stat: <span className=' green'><i className='fa fa-arrow-down green  text-white'></i> </span>
  },
  {
    img:"Default",
    time: "10:24:05 AM",
    token: "BSC",
    count: "+36",
    btn: <Button variant='outline-secondary'>Canceled</Button>,
    stat: <span className=' red'><i className='fa fa-arrow-down red  text-white'></i></span>
  },
  {
    img:"Default",
    time: "10:24:05 AM",
    token: "BSC",
    count: "+36",
    btn: <Button variant='outline-secondary'>Canceled</Button>,
    stat: <span className=' gray'><i className='fa fa-arrow-down gray text-white'></i></span>
  },
  {
    img:"Default",
    time: "10:24:05 AM",
    token: "BSC",
    count: "+36",
    btn: <Button variant='outline-success'>Canceled</Button>,
    stat: <span className='gray'><i className='fa fa-arrow-down gray text-white'></i></span>
  },
  {
    img:"Default",
    time: "10:24:05 AM",
    token: "BSC",
    count: "+36",
    btn: <Button variant='outline-secondary'>Canceled</Button>,
    stat: <span className=' red'> <i className='fa fa-arrow-down red text-white'></i></span>
  }

]
useEffect(() => {
  Getdata();
  }, []);

const Getdata = async () => {
     
  var UserInfo  =    await UsergetInfobyname(UserName);
 
  setUserInfo(UserInfo.data.BankAccount)
}


const Onsubmit = async() =>{
  var bankdetails ={
    "holdername" : holdername,
    "currency":currency,
    "bankname": bankname,
    "Accountnumber":Accountnumber,
    "IFSCcode":IFSCcode,
    "CountryList":Country,
    "bankaddress":bankaddress,
    "bankcity":bankcity,
     "time":Date.now()
}
 const bankdata = {
  user_name:UserName,
  Bankdetails :bankdetails
 }

 const { errors, isValid } = await validateBankDetails(bankdetails);
 if (!isValid) {
  
     seterrors(errors)
 }else{ 
 
  UseUploadBAnkdetails(bankdata)}
 
}

   return(
    <>
    <Modal size="xl" show={accountdetails}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big'>
    {updateaccountdetails && <UpdateAccountDetails  record={Currentrecords} onDismiss={() => setupdateaccountdetails(false)} />}
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-4 '>
        <h5 className='headfont text-center'>Bank Account Details</h5>

       <div className='d-lg-flex gap-3 mt-3'>
          <div className='w-full '>
                <p className='waiz_modal_label'>Select Currency</p>
                <div className='wizardinput'>
                    <select className='form-control'
                    value={currency}
                    onChange={(e) => { setcurrency (e.target.value)}}>
                      <option >Select Currency</option>
                      <option value="EUR">EURO</option>
                      <option value="USD">USD</option>
                      <option value="ZAR">ZAR</option>
                      <option value="GBP">GBP</option>
                                          
                    </select>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.currency}</span>
          </div>
          <div className='w-full'>
                <p  className='waiz_modal_label'>Account Holder Name</p>
                <div className='wizardinput '>
                    <input type="text"
                    maxLength={45}
                    value={holdername} onChange={(e) => { setholdername(e.target.value) }}
                     className='form-control' placeholder='Account Holder Name'/>
            <span className="text-danger f-12 d-block text-left">{errors.holdername}</span>
                </div>
            </div>
       </div>
       

       
       <div className='d-lg-flex gap-3 mt-3'>
         
          <div className='w-full'>
                <p  className='waiz_modal_label'>Bank Name</p>
                <div className='wizardinput '>
                    <input type="text" 
                    maxLength={45}
                     value={bankname} onChange={(e) => { setbankname(e.target.value) }}className='form-control' placeholder='Bank Name'/>
                    <span className="text-danger f-12 d-block text-left">{errors.bankname}</span>
                </div>
            </div>
            <div className='w-full mt-3 mt-lg-0'>
                <p  className='waiz_modal_label'>Account Number</p>
                <div className='wizardinput '>
                    <input type="text" 
                    maxLength={45}value={Accountnumber} onChange={(e) => { setAccountnumber(e.target.value) }}className='form-control' placeholder='Account Number'/>
                    <span className="text-danger f-12 d-block text-left">{errors.Accountnumber}</span>
                </div>
            </div>
       </div>

       <div className='d-lg-flex gap-3 mt-3'>
         
         <div className='w-full'>
               <p  className='waiz_modal_label'>Bank Code</p>
               <div className='wizardinput '>
                   <input type="text" maxLength={45}value={IFSCcode} onChange={(e) => { setIFSCcode(e.target.value) }}className='form-control' placeholder='Bank Code'/>
                   <span className="text-danger f-12 d-block text-left">{errors.IFSCcode}</span>
               </div>
           </div>
           <div className='w-full'>
               <p  className='waiz_modal_label'>Bank Country</p>
               <div className='wizardinput '>
               <select className='form-control'
                    value={Country}
                    maxLength={45}
                    onChange={(e) => { setCountry (e.target.value)}}>
                      <option >Select Country</option>
                                           {CountryList && CountryList.map((item) => 
                            
                                <option value={item.name}>{item.name}</option>
                                          )}
                    </select>
               </div>
                    <span className="text-danger f-12 d-block text-left">{errors.CountryList}</span>
           </div>
      </div>

      <div className='d-lg-flex gap-3 mt-3'>
         
          <div className='w-full'>
                <p  className='waiz_modal_label'>Bank Address</p>
                <div className='wizardinput '>
                    <input type="text" maxLength={45} value={bankaddress} onChange={(e) => { setbankaddress(e.target.value) }}className='form-control' placeholder='Bank Address'/>
                    <span className="text-danger f-12 d-block text-left">{errors.bankaddress}</span>
                </div>
            </div>
            <div className='w-full mt-3 mt-lg-0'>
                <p  className='waiz_modal_label'>Bank City</p>
                <div className='wizardinput '>
                    <input type="text" maxLength={45} value={bankcity} onChange={(e) => { setbankcity(e.target.value) }} className='form-control' placeholder='Bank city'/>
                    <span className="text-danger f-12 d-block text-left">{errors.bankcity}</span> 
                </div>
            </div>
       </div>

       <div className='text-end'>
       <button className='btn btn-theme mt-4 btn_big_theme_border' onClick={()=>Onsubmit()}>Submit</button>

       </div>

<div className=' tabtable  potfolio mt-4 '>
    <h5 className='font_new_profbig mb-4'>My bank Account</h5>
    <div className='div_dt_tabl_scroll bank_detal_bg_table'>
    {UserInfo && UserInfo.length > 0  ? <DataTable columns={columns} data={UserInfo}/> :
    <><p className='text-center'><span className='fa fa-times text-danger'> </span> No Bank Details Added</p></> }
</div>
</div>
</div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default AccountDetails
import React,{useState,useEffect,useRef} from 'react';
import { Button,Dropdown, Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'
import { UsergetInfobyname, UserTransactions , Userdatetransaction} from '../Hooks/UseBackendAction/UseGetController';
import { CSVLink } from 'react-csv'

import loaderimg from './../Assets/Images/loaderlogo.png'



export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const History = () =>{
  const [username, setusername] = useState(sessionStorage.getItem("username"))
  const [records, setRecords] = useState([])
  const [startdate , setstartDate] = useState("")
  const [enddate , setendDate] = useState("")
  const xlsLink = useRef()
  const csvLink = useRef()
  const [showloader,setShowloader] = useState(false)


   const columns = [
    {
      name: "S.No",
      selector: (vals,index) => index + 1,
      sortable: true
    },
    {
      name: "From",
       selector: (vals) => vals.from.substring(0,6)+"..." + vals.from.substring(35,42),
      sortable: true
    },
    {
      name: "To",
       selector: (vals) => vals.to.substring(0,5)+"..."+vals.to.substring(36,42),
      sortable: true
    },
    {
      name: "Type",
      selector:(vals) => vals.type === "SWAP" ? <Button variant='outline-danger' className='samewidth'>Swap</Button> :
                         vals.type === "DEPOSIT" ? <Button variant='outline-danger' className='samewidth'>Deposit</Button> : 
                         vals.type === "Withdraw"   ? <Button variant='outline-success' className='samewidth'>Withdraw</Button> : <></>,
      sortable: true
    },
    {
      name: "Amount",
      selector: (vals) =>( parseFloat(vals.amount).toFixed(3)),
      sortable: true
    },
    {
      name: "Admin Fee",
      selector: (vals) =>(vals.adminfee ? vals.adminfee : "0"),
      sortable: true
    },
    {
      name: "Explorer",
      selector: (vals) => (
        vals.hash != "" && vals.type === "SWAP" || vals.hash != "" && vals.type === "DEPOSIT" || vals.hash != "" &&  vals.type === "Withdraw"?

        vals.network === "Solana" ?
         <Button variant='outline-success'  target="blank" href={ (vals.type === "Withdraw" || vals.type === "DEPOSIT") ? vals.hash : "https://solscan.io/tx/"+ vals.hash} className=' fa fa-external-link'></Button> :

        vals.network === "BSC" ?
        <Button variant='outline-success'  target="blank" href={(vals.type === "Withdraw" || vals.type === "DEPOSIT") ? vals.hash : "https://bscscan.com/tx/"+vals.hash} className=' fa fa-external-link'></Button> 
        : 
        
       
        <Button variant='outline-success'  target="blank"  href={(vals.type === "Withdraw" || vals.type === "DEPOSIT") ? vals.hash : "https://etherscan.io/tx/"+vals.hash}   className=' fa fa-external-link'></Button>  : <></>
      
    
    
     ),
      sortable: true
    },
    {
      name: "Date",
      selector: (vals) => (vals.updatedAt.substring(0,10)),
      sortable: true
    }

     
     
   
      
   
]  

const showLoad = ()=>
{
  document.getElementsByTagName("body")[0].classList.add("body_over_hidden");
  setShowloader(true);
}


const hideLoad = ()=>
{
  document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");
  setShowloader(false);

}
useEffect(() => {
  document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

  showLoad();
  if(startdate ===  "" && enddate === ""){
    Getdata();
  }
  else{
    searchData();
  }
},[startdate, enddate]) 


const Getdata = async () => {
  var UserInfo  =    await UsergetInfobyname(username);
  
  var TransactionInfo = await UserTransactions(UserInfo?.data?.userId);
  setRecords(TransactionInfo?.data)
  
  hideLoad()
 
}

const searchData = async () => {
  var UserInfo  =    await UsergetInfobyname(username);
   
     await Userdatetransaction(startdate,enddate,UserInfo?.data?.userId).then((TransactionInfo) => {
    setRecords(TransactionInfo?.data)
  })

}
const DownloadXLS = async () => {

  xlsLink.current.link.click()
}
const DownloadCSV = async () => {

  csvLink.current.link.click()
}

return(
<>
 <div className='history dashboard transsec'>
 <div className='bggrad'></div>
 <div className='bggradleft'></div>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans transsec'>
      {showloader?
            <div className='loader_overlay'>
            <div className='loader_div'>
              <img src={loaderimg} className='img-fluid loader_logo' />
            </div>
          </div>
          :
          <></>
            }
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h2 className='headfont '>Transaction</h2>
            </div>
            
               <div className='tabtable mt-5 potfolio card_cutout px-0'>
                <div className='d-sm-flex jc-between align-items-center mb-4 px-3 px-lg-4 flex_chane_1'>
                <h4 className='headfont_po mb-0'>History</h4>
              
                   <div className='d-sm-flex gap-2 mt-sm-0 mt-3 margi_righthed_btn flex_chane_2'>
                   <div className='flex_btn_dowl'>
                {
                  records && records.length > 0 ? (
                    <>
                      <button onClick={DownloadXLS} className='btn btn-theme me-2 btn_download mb-2 mb-md-0'>Download(XLS)</button>
                      <CSVLink
                        data={records}
                        filename='Transaction.xls'
                        className='hidden'
                        ref={xlsLink}
                        target='_blank'
                      /> </>) : (
                    ""
                  )
                }
                {records && records.length > 0 ? (
                  <>
                    <button onClick={DownloadCSV} className='btn btn-theme me-2 btn_download mb-2 mb-md-0'>Download(CSV)</button>
                    <CSVLink
                      data={records}
                      filename='Transaction.csv'
                      className='hidden'
                      ref={csvLink}
                      target='_blank'
                    />
                  </>) : (
                  ""
                )}
                </div>
                       <div className='d-flex align-items-center mb-lg-0 mb-2 justify-content' ><p className='mx-2 mb-0 ' style={{ width:50}}>From</p>
                        <input type="date" className='form-control ' style={{background: "rgb(47, 47, 47)" ,color:" #fff",fontSize: 12, maxWidth:180}}onkeydown="return false" onChange={(e)=>{setstartDate(e.target.value)}}/></div>
                       <div className='d-flex align-items-center mb-lg-0 mb-2 justify-content'><p className='mx-2 mb-0' style={{ width:50}}>To</p>
                        <input type="date"  className='form-control' style={{background: "rgb(47, 47, 47)" ,color:" #fff",fontSize: 12,  maxWidth:180}} onkeydown="return false"onChange={(e)=>{setendDate(e.target.value)}}/></div>
                   </div>
                </div>
                <hr />
                <div className='px-3 px-lg-4 div_dt_tabl_scroll'>
                <DataTable pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records}/>
                </div>
                <></>

               </div>
            
            
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default History
import React, { useState, useEffect } from 'react';
import { Modal, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap'

import Header from './Header';
import Sidebar from './Sidebar';

import {  UsergetInfobyname, UsetokeninfoBySymbol, getTokenBalance, getTokeninfo,UsetokeninfoBySymbolandchainid } from '../Hooks/UseBackendAction/UseGetController';
import { searchedBalanceValue } from '../Hooks/UseValidation';
import * as config from "../config/env";
import check from '../Assets/Images/check.png';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast'
import { UseUserBridge } from '../Hooks/UseBackendAction/UseCreateUser.js';
import DataTable from 'react-data-table-component';
import Bridgehint from './Modals/BridgeHint';
import Swapmodal from './Modals/Swapmodal';

import loaderimg from './../Assets/Images/loaderlogo.png'




const Bridge = () => {
    const columns = [
        {
          name: "Logo",
          sortable: true,
          selector: (vals) => {
            return (
              vals.type === "token" || vals.type === "crypto" ?
                <img
                  src={config.backurl + `/Images/${vals.logoURI}`}
                  width={30}
                  alt='form' className="b-50"
                /> : <></>
            )
          }
        },
        {
          name: "Symbol",
          selector: (vals) => {
            return (
              

                 vals.symbol == "ZARCSol" ? "ZARC(SOL)" :
                 
                 vals.symbol == "ZARC" ? "ZARC(BSC)" : 
                 vals.symbol == "ZARCEth" ? "ZARC(ETH)" : 
                 vals.chainId == "56" ? vals.symbol+"(BSC)":
                 vals.chainId == "1" ? vals.symbol+"(ETH)":
                 vals.chainId == "9" ? vals.symbol+"(SOL)":
                 vals.symbol )},
                 sortable: true
        },
        {
          name: "Balance",
          selector: (vals) => {
    
            return (
                vals.type === "token" || vals.type === "crypto" ?
               <p className=' lightgreen mb-0'>{(vals.Balance.amount).toFixed(3)}</p> : 
              <> </>
            )
          },
          sortable: true
        },
      
      ]
 
    const [Tokeninfo, setTokeninfo] = useState([]);
    const [fromImage,setfromImage] = useState("")
    const [fromToken,setfromToken] = useState("")
    const [fromBalance,setfromBalance] = useState("0")
    const [toImage,settoImage] = useState("")
    const [toToken,settoToken] = useState("")
    const [UserInfo, setUserInfo] = useState({})
    const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
    const [fromamount,setfromamount] = useState("")
    const[zarcBSCBalance,setzarcBSCBalance] = useState(0);
    const[zarcETHBalance,setzarcETHBalance] = useState(0);
    const[zarcSolanaBalance,setzarcSolanaBalance] = useState(0);
    const [coindata, setcoindata] = useState([])
    const [bridgehint, setbridgehint] = useState(true);
    const [isPending, setisPending] = useState(false);
  const[swapmodal, setSwapmodal] = useState(false);

    const [showloader,setShowloader] = useState(false)
    
    const showLoad = ()=>
    {
      document.getElementsByTagName("body")[0].classList.add("body_over_hidden");
      setShowloader(true);
    }
  
  
    const hideLoad = ()=>
    {
      document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");
      setShowloader(false);
  
    }

    useEffect(() => {
  document.getElementsByTagName("body")[0].classList.remove("body_over_hidden");

        showLoad();

        if (localStorage.getItem("bridgehintpage") == 1) {
            setbridgehint(false)
          }
      
        getData();
       
    }, []);



    const getData = async () => {

        const tokeninfo = await getTokeninfo();
        setTokeninfo(tokeninfo?.data);
        var UserInfo = await UsergetInfobyname(UserName);
        setUserInfo(UserInfo?.data?.Holding);

        var tokenBalance = await getTokenBalance();
        setzarcBSCBalance(parseFloat(tokenBalance?.bsctoken))
        setzarcETHBalance(parseFloat(tokenBalance?.ethtoken))
        setzarcSolanaBalance(parseFloat(tokenBalance?.solana))
        var temarray = [];
       var tokenSymbol = await Promise.all(await UserInfo?.data?.Holding.map(async (item, index) => {
        if (item.type === "token") {
  
          // var dat = await UsetokeninfoBySymbol(item.symbol)
          var dat = await UsetokeninfoBySymbolandchainid(item.symbol,item.chainId)
          dat['data']['Balance'] = item
          temarray.push(dat['data'])
          // return dat['data']
  
        }
      }))
      setcoindata(temarray)
        
      hideLoad()
    }


    const handleChange = async (value) => {
        const searchData = await searchedBalanceValue(UserInfo, value);
        setfromBalance(searchData[0]?.amount ? searchData[0]?.amount : 0)
    }

    

    const Bridge = async() =>{

        console.log("fromToken",fromToken,typeof(zarcBSCBalance));

        if (fromToken == "" || toToken == "") {
    
            toast.error("select tokens first", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
              })
          
        }
      
        else if(fromToken === "ZARC" && zarcBSCBalance < parseFloat(fromamount)){
            
                toast.error(`admin don't have enough balance in ${fromToken}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                  })
            
        }
        else if(fromToken === "ZARCSol" && zarcSolanaBalance < parseFloat(fromamount)){
            
            toast.error(`admin don't have enough balance in ${fromToken}`, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
              })
        
    }
    else if(fromToken === "ZARCEth" && zarcETHBalance < parseFloat(fromamount)){
            
        toast.error(`admin don't have enough balance in ${fromToken}`, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          })
    
}
        else if(parseFloat(fromBalance)  < parseFloat(fromamount))
        {
            toast.error(`You don't have enough balance in ${fromToken}`, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
              })
        }
         else{

            const data = {
                user_name :UserName,
                fromtoken :fromToken,
                totoken : toToken,
                amount :parseFloat(fromamount),
            }
        
     var result = await UseUserBridge(data);
  //    if(result?.data?.success === true && result?.data?.status === 200){

  //     setSwapmodal(true)
  //  }

        }
        
      
    }

    const max = async() =>{

        setfromamount(fromBalance)

    }

    
  

  return (

    
    <div className='dashboard acquire'>
    <div className='bggradleft'></div>
    <div className='bggradright'></div>
    {bridgehint && <Bridgehint onDismiss={() => setbridgehint(false)} />}
    {swapmodal && <Swapmodal onDismiss={() => setSwapmodal(false)} />}
   
    <Header />
    <div className='page'>
      <div className='sidebar'><Sidebar /></div>
      <div className='content exchange'>
      {showloader?
            <div className='loader_overlay'>
            <div className='loader_div'>
              <img src={loaderimg} className='img-fluid loader_logo' />
            </div>
          </div>
          :
          <></>
            }
        <div className='container-fluid container-theme p-4 position-relative'>
    <div className='mb-4'>

    <div className='mb-4 deposit_topbtns'>
                                
                                <h2 className='headfont'>Bridge</h2>
                                              <Button className="btn btn-theme deposit_btns" onClick={() => setbridgehint(true)}>Hint</Button>
              
                                          </div>

                        
                        <div className='row mt-5'>
                            <div className='col-12 col-md-7 col-lg-7'>

                           
                        <div className='mt-3'>


                            <div className='card card_cutout mt-3 card_cutout_exch'>

                                <div className='rightinput dd_inpout_desi exchange_sec_nut'>

                                    <div className='row'>
                                        <div className='col-12 col-lg-7 col-xl-8 input_ex_des'>

                                            <div className='input-group input_grp_with_btu'>
                                                <Form.Control aria-label="Text input with dropdown button"
                                                 placeholder='Bridge From' 
                                                 value={fromamount}
                                                 onChange={(e) =>setfromamount(e.target.value)}

                                                />
                                                <div className='input-group-append append_no_tran'>
                                                    <button className='btn btn_max' onClick={()=> max()}>Max</button>
                                                </div>

                                            </div>
                                            <p>Balance: {parseFloat(fromBalance).toFixed(3)} </p>

                                        </div>
                                        <div className='col-12 col-lg-5 col-xl-4 dropdwo_height_Scrol'>
                                            <DropdownButton variant="outline-secondary" className='dropdow_open_modal'
                                                 title={ fromImage == "" ? <>
                                                 <img src={check} className='check_img_resize'></img> Select</> : 
                                                 <><img src={config.backurl + `/Images/${fromImage}`} /> {fromToken === "ZARC" ? "ZARC(BSC)" : fromToken === "ZARCSol" ? "ZARC(SOL)" : "ZARC(ETH)"}</>
                                                 } 
                                                id="input-group-dropdown-2" align="end">
                                                {Tokeninfo && Tokeninfo.map((item) => {
                                                    return (
                                                        item.symbol === "ZARC" || item.symbol === "ZARCSol" || item.symbol === "ZARCEth" ?
                                                            <> <Dropdown.Item   disabled={toToken == item.symbol ? true : false} onClick={() => {setfromToken(item.symbol); setfromImage(item.logoURI); handleChange(item.symbol);}}> <img src={config.backurl + `/Images/${item.logoURI}`} />
                                                            {item.symbol === "ZARC" ? "ZARC(BSC)" : item.symbol === "ZARCSol" ? "ZARC(SOL)" : "ZARC(ETH)"}
                                                            </Dropdown.Item></> : <></>

                                                    )


                                                }
                                                )}
                                  </DropdownButton>
                                        </div>
                                    </div>
                                    {/* </InputGroup> */}

                                </div>





                            </div>

                            <div className='text-center exbtn '>
                                {/* <img src={imgtrans} /> */}
                                <span className='fa fa-exchange text-center'></span>
                            </div>


                            <div className='card card_cutout  card_cutout_exch'>
                                <div className='rightinput inp exchange_sec_nut'>
                                    <div className='row'>
                                        <div className='col-12 col-lg-7 col-xl-8 input_ex_des'>

                                            <Form.Control aria-label="Text input with dropdown button"

                                                placeholder='Bridge To' 
                                                value={fromamount}
                                                />





                                           
                                            <p></p>
                                        </div>
                                        <div className='col-12 col-lg-5 col-xl-4 dd_inpout_desi dropdwo_height_Scrol'>
                                        <DropdownButton variant="outline-secondary" className='dropdow_open_modal'
                                                 title={ toImage == "" ? <>
                                                 <img src={check} className='check_img_resize'></img> Select</> : 
                                                 <><img src={config.backurl + `/Images/${toImage}`} /> { toToken === "ZARC" ? "ZARC(BSC)" : toToken === "ZARCSol" ? "ZARC(SOL)" : "ZARC(ETH)"}</>
                                                 } 
                                                id="input-group-dropdown-2" align="end">
                                                {Tokeninfo && Tokeninfo.map((item) => {
                                                    return (
                                                        item.symbol === "ZARC" || item.symbol === "ZARCSol" || item.symbol === "ZARCEth" ?
                                                            <> <Dropdown.Item disabled={fromToken == item.symbol ? true : false} onClick={() => {settoToken(item.symbol); settoImage(item.logoURI)}}> <img src={config.backurl + `/Images/${item.logoURI}`} />
                                                            {/* {item.symbol} */}

                                                            {item.symbol === "ZARC" ? "ZARC(BSC)" : item.symbol === "ZARCSol" ? "ZARC(SOL)" : "ZARC(ETH)"}

                                                            </Dropdown.Item></> : <></>

                                                    )


                                                }
                                                )}
                                  </DropdownButton>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className='text-center exbtn '><span className='fa fa-exchange text-center'></span></div> */}


                            </div>
                        </div>

                    {/* <button className="mt-3 hridge_sw_bt" onClick={()=>{
                      // alert(2)
                      setSwapmodal(true)
                      }}></button> */}

                        {parseFloat(fromamount) > 0 ?
                        <div className='text-center mt-4'>
                            <button className="btn btn-theme"disabled={isPending} onClick={async()=>{setisPending(true);await Bridge();setisPending(false)}}>Bridge</button>
                        </div> :
                        <div className='text-center mt-4'>
                        <button className="btn btn-theme" disabled ={true}>Bridge</button>
                    </div>
}

                    </div>
                    <div className='col-12 col-md-5 col-lg-5'>

                    <div className='card card_cutout'>
                    <DataTable columns={columns} data={coindata} />
                    </div>
                    </div>
                    </div>
                    
                    </div>
                    <div className='row jc-center'>
                  <div className='col-xxl-9'>
                    <div className='mt-4'>

                    <div className='notecont py-0'>
                <p className='pos_ab_note'>Important</p>
                    <div className='row'>
                        <div className='col-12 col-md-12'>
                            <div className='py-4 text-center'>
                        <li className='mt-2'> <p className='text-center'>
                            <img src={check} />
                            <span>Important: Please allow a few minutes for your deposit to reflect on the platform as it undergoes blockchain verification</span></p></li>
                     
                                </div>
                        </div> 
                      

                    </div>
                   
                </div>

                    


                    </div>
                  </div>
                </div>
                    </div>
                    </div>
                    </div>
                    
                        </div>
                
  )
}
 
export default Bridge
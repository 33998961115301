import React,{useState,useEffect} from 'react';
import { Button,Dropdown, Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'
import { UsegetAllNotification } from '../Hooks/UseBackendAction/UseGetController';


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const Notification = () => {
  
  const columns = [
    {
      name: "Date",
      selector: (vals) => vals.updatedAt.substring(0,10),
      sortable: true
    },
    {
      name: "Description",
      selector: (vals) => vals.message,
      sortable: true
    },
  
   
      
   
]  
const vals = [
  {
    img:"Lorem ipsum dolor sit amet consectetur adipisicing elit ",
    time: "10:24:05 AM",
  
    
  }


]
const [Usernotification, setUserUsernotification] = useState([])
const [username,setusername] = useState(sessionStorage.getItem("username"))
useEffect(() => {
    
   getdata();
  }, []);


const getdata = async() =>{
 
  var usernotification = await UsegetAllNotification(username)
  
  setUserUsernotification(usernotification?.data ? usernotification?.data : []);
  
 
  
}

return(
<>
 <div className='history dashboard seenotify'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>

            <h4 class="headfont mt-4">Notification</h4>

            </div>
            <div className='row'>
                    <div className='col-md-10 m-auto'>
                                   <div className='tabtable mt-md-5 potfolio box br-10'>
                <div className='d-flex jc-between align-items-center'>
                <h4 class="headfont headfont_normal mb-0 ">Notification</h4>
                  
                </div>
                
                <div className='portscroll themescroll dashboard_tae_data'>
                  
              
                             <DataTable  columns={columns} data={Usernotification}/>

                </div>
                    </div>
                </div>
             </div>
             </div>
      </div>
   </div>
 </div>
</>
)
} 

export default Notification
import React, { useState,useEffect } from 'react';
import {Modal, DropdownButton, Dropdown, Tabs, Tab, Row, Col, Nav} from 'react-bootstrap'
import { UseBankActive, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import { searchedBankValue } from '../../Hooks/UseValidation';
import TransferedFunds from '../Modals/TransferedFunds';
import * as config from "../../config/env";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import exchange0 from '../../Assets/Images/hint/exchange0.png'
import exchange1 from '../../Assets/Images/hint/exchange1.png'
import exchange2 from '../../Assets/Images/hint/exchange2.png'



const options= {
  responsive:{
    0: {
        items: 1,
    },
    450: {
        items: 1,
    },
    600: {
        items: 1,
    },
    1000: {
        items: 1,
    },
},
}

const ExchangeHint = (props) =>{
   const[exchangehint, setExchangehint] = useState(true)
 
   
   const skip  = () =>{
    localStorage.setItem("exchangepage",1)
  }


  

   return(
    <>
    <Modal size="lg" show={exchangehint}  id="exchangehintopup" onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal modal_big_hint'>
    {/* {true && <TransferedFunds bankdata={SearchCoinData && SearchCoinData} refcode={props.refcode} onDismiss={() => setFunds(false)} />} */}
      <Modal.Header className='border-0 pb-0'>
     
           {/* <button type="button" className="btn close close_modal ms-auto text-white"><span aria-hidden="true">×</span><span class="sr-only"></span></button> */}
      </Modal.Header>
      <Modal.Body className='px-sm-5 p-3 themescroll'>
       
      <div className='mb-4 content viewdetails'>
      
       
        
        <h3 className='headfont text-center'>Exchange</h3>
        <div className='mt-5'>


<OwlCarousel className='owl-theme' loop margin={10} items={1} dots responsive={options.responsive}>
<div class='item'>
  <div className='text-center'>
    <img src={exchange0} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>Choose the desired network for your fiat or cryptocurrency exchange.</p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={exchange1} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>In the first tab, enter the amount of Fiat/cryptocurrency you have and want to exchange. In the second tab, select the Fiat/cryptocurrency you wish to receive. The conversion between the two </p>
    </div>
</div>
<div class='item'>
  <div className='text-center'>
    <img src={exchange2} className='img-fluid img_hint' />
    <p className='desc_hint mt-3'>pairs will be done automatically. You can also convert stablecoins to any cryptocurrency on the chosen network, and vice versa.</p>
    <p className='desc_hint mt-3'>To complete the exchange, click the "Exchange" button after selecting the desired amount, network, and conversion pairs. After a successful exchange, you can see your new balance next to the exchange tab</p>

    </div>
</div>


</OwlCarousel>
</div>
<div className='text-center mt-4'>
          <button className="btn btn-theme" onClick={() => {props.onDismiss();skip()}}>Skip Hints</button>
          </div>
        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default ExchangeHint
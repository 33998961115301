import React, {useState,useEffect} from 'react';
import {Table} from 'react-bootstrap'
import banner from '../Assets/Images/banner.png'
import zarc from '../Assets/Images/zarc.png'
import aim from '../Assets/Images/aim.png'
import db from '../Assets/Images/db.png'
import bullet from '../Assets/Images/bullet.png'
import zarctech from '../Assets/Images/zarctech.png'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Buffer } from 'buffer';
import LandHeader from './LandHeader';
import ContactUs from './Modals/ContactUsModal';
import NewPassword from './Modals/NewPassword';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast';

import { UserGetByName, UsergetInfobyname } from '../Hooks/UseBackendAction/UseGetController';

const Landing = () =>{
    const[contact, setContact] = useState(false);
    const[newpassword, setNewPassword] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const otp = queryParams.get('code');
    const [linkotp,setlinkOtp] = useState(0);
    const [checkotp,setcheckotp] = useState("");
    const[UserName , setUserName] = useState(localStorage.getItem("forgotEmail"));

    useEffect(()=> {
        getData()
       const decodeotp = Buffer.from(String(otp), 'base64').toString('ascii');
      
       setlinkOtp(decodeotp)
     
      
       if ((Number(decodeotp) > 0  && checkotp == decodeotp)) {
   
        //    console.log("condition",(Number(decodeotp) > 0  && checkotp == decodeotp),decodeotp,checkotp);
           setNewPassword(true)
       }else{
           setNewPassword(false)
        //    toast.success("Try Again", {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme,
        // })
       }
   },[checkotp])

   const getData = async () => {
       
    var UserInfo  =    await UserGetByName(UserName);
    setcheckotp(UserInfo?.data?.Otp)
   
  }
    return(
        <div className='landing'>
            <LandHeader />
            {newpassword && <NewPassword otp={linkotp} onDismiss={() => setNewPassword(false)}/>}
           
        </div>
    )
}

export default Landing
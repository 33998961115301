import React, { useState, useEffect } from 'react';
import { Modal, DropdownButton, Dropdown } from 'react-bootstrap'
import upload from '../../Assets/Images/upload_img_new.png'
import pdf from '../../Assets/Images/pdf.png'
import { UseDepositFiat } from '../../Hooks/UseBackendAction/UseCreateUser.js';
// import $ from 'jquery';

import check from '../../Assets/Images/check.png'
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../../Hooks/UseToast'
import { GetFiatToCrypto, UsergetInfobyname, getAllFeeInfo, getTokeninfo } from '../../Hooks/UseBackendAction/UseGetController';

const TransferedFunds = (props) => {
  const [funds, setFunds] = useState(true)
  const [imagepath, set_imagepath] = useState("")
  const [file, set_file] = useState({})
  const [amount, setamount] = useState(0)
  const [userId, setuserId] = useState(sessionStorage.getItem("userId"))
  const [bankdetails, setbankdetails] = useState({})
  const [transactionID, settransactionID] = useState("")
  const [Tokeninfo, setTokeninfo] = useState([])
  const [ImageURL, setImageURL] = useState("")
  const [Fiat, setFiat] = useState("")
  const [price, setPrice] = useState(0)
  const [depositFee,setdepositFee] = useState(0);
  const [UserName, setUserName] = useState(sessionStorage.getItem("username"))
  const [Depositaddress, setDepositaddress] = useState("")
  const [DepositSOladdress, setDepositSoladdress] = useState("")
  const [isSolana, setisSolana] = useState("false")
  const [userFinalAmount,setuserFinalAmount] = useState(0)
  const [adminFee,setadminFee] = useState("0")
  const [imageType,setimageType] = useState("")
  


  const ImageUpload = event => {
    var name = event.target.value;
    set_file(event.target.files[0]);
    
    set_imagepath(name);
    setimageType(event.target.files[0]?.type)
    var url = URL.createObjectURL(event.target.files[0])
    setImageURL(url)
  };

  useEffect(() => {
    getData()
    // console.log("props?.bankdata",props?.bankdata);
    // setbankdetails(props?.bankdata)

  }, [])

  const getData = async () => {

    const tokenInfo = await getTokeninfo();
    setTokeninfo(tokenInfo?.data);
    var feedata =  await getAllFeeInfo();
    setdepositFee(parseFloat(feedata?.data[0]?.depositFee) > 0 ? feedata?.data[0]?.depositFee : "0");
    var UserInfo = await UsergetInfobyname(UserName);
      
          setDepositaddress(UserInfo?.data?.walletaddress)
          setDepositSoladdress(UserInfo?.data?.solanawalletaddress)
  
         
  }


  const handleChange = async (value) => {
    
if(Fiat == ""){

  toast.error("Please select the currency", {
    position: position.position,
    style: style,
    iconTheme: iconTheme,
  })

}else{


  var tokenprice = await GetFiatToCrypto(Fiat, isSolana === "false" ?  "ZARC" : "Solana", value);
     var priceInPercentage = depositFee / 100 * parseFloat(tokenprice?.tokenOutPrice);
     setadminFee(parseFloat(depositFee) == 0 ? "0" : priceInPercentage)
     var userFinalAmount = parseFloat(tokenprice?.tokenOutPrice) -  priceInPercentage
     setuserFinalAmount(userFinalAmount)
    
     setPrice( parseFloat(userFinalAmount))
}

    

  }

  const onSubmit = async (e) => {

    e.preventDefault();
    const editprofiledata = {
      userId: userId,
      transactionID: transactionID,
      holdername: localStorage.getItem("holdername"),
      currency: Fiat,
      amount: amount,
      bankname: localStorage.getItem("bankname"),
      Accountnumber: localStorage.getItem("Accountnumber"),
      IFSCcode: localStorage.getItem("IFSCcode"),
      branch: localStorage.getItem("bankcity"),
      AccountType: localStorage.getItem("AccountType"),
      file: file != {} ? file : imagepath,
      ZarcAmount : userFinalAmount,
      walletaddress :  isSolana === "true" ? DepositSOladdress : Depositaddress,
      network : isSolana === "false" ? 
         "BSC": isSolana === "null" ? "ETH" : "Solana",
      adminFee : adminFee,
      depositdate: new Date(Date.now()).toDateString()
      



    };

    await UseDepositFiat(editprofiledata)
  }
  const closepopup = () => {
    // $('#depositpopup').hide()
    window.location.reload()
  }


  return (
    <>
      <Modal size="md" id="depositpopup" show={funds} onHide={() => props.onDismiss()} centered scrollable className='modal_style thememodal'>

        <Modal.Header className='border-0 pb-0'>

          <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => { closepopup() }}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
        <Modal.Body className='p-sm-3 ps-3 themescroll'>

          <div className='mb-4 content viewdetails'>



            <h3 className='headfont text-center'>Deposit funds to your Rand wallet</h3>


            <div className='notecont py-0 mt-5'>
              <p className='pos_ab_note'>Please Note:</p>
              <div className='row'>
                <div className='col-12 col-md-6 border_right_note'>
                  <div className='pt-4 pb-3'>
                    <li className='mt-2'> <p className=''>
                      <img src={check} />
                      <span>Important: Upload correct Receipt</span></p></li>

                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='pt-4 pb-3'>
                    <li className='mt-2'><p><img src={check} />Don't make mistake entering amount & Transaction ID</p></li>
                  </div>
                </div>

              </div>

            </div>

            <div className='mb-4 '>

              <h5 className='headfont text-center label_input_font mt-4'>Upload Image</h5>
              <form onSubmit={onSubmit} >
                <div>
                  <div className='my-4 selectbank upload'>
                    <div className='position-relative upload_img_div'>

                    {imageType === "application/pdf" ? <><img src={pdf} /></> : <>
                    {ImageURL.length !== 0 ?
                        <img src={ImageURL} /> : <img src={upload} />}
                    
                    </>}
                     
                      {/* <p className='headfont mb-0'>{ImageURL.length !== 0 ? " " : ""}</p> */}
                      <label className='f-12'>{imagepath == "" ? "Choose Documnent" : imagepath}</label>
                      <input type="file" accept=".png, .jpg, .jpeg,.pdf"
                      required onChange={ImageUpload} value={imagepath} />
                    </div>
                    <h3 class="mt-2 headfont mb-3  label_input_font">Deposit Curreny</h3>

                    <DropdownButton title={Fiat === "" ? "Deposit Currency" : Fiat } id="dropdown-basic-button" className='w-full'>
                    <Dropdown.Item onClick={(e)=>{setFiat("EUR")}}> EUR </Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{setFiat("USD")}}> USD </Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{setFiat("ZAR")}}> ZAR </Dropdown.Item>
                    <Dropdown.Item onClick={(e)=>{setFiat("GBP")}}> GBP</Dropdown.Item>

                  
                
                </DropdownButton>

                <h3 class="font_12_green mb-3 mt-3 lightgreen text-right">Deposit Fee  - {depositFee} % </h3>

                     

                    <div className='flex-1 align-items-center my-3 selectbank'>
                      <div>
                      <h3 class="headfont mb-3  label_input_font">Amount</h3>
                      </div>

                      <div className='input-group input_grp_with_btu btn_flex_depo_inot_mas'>

                      <input type="text" required 
                        value={amount}
                        pattern="^(0|[1-9][0-9]*)$"
                        title="numbers only support"
                        maxlength="10"
                        onChange={(e) => { {setamount(e.target.value)};handleChange(e.target.value) }} placeholder='Amount' />


                                              

                                            </div>



                     
                    </div>

                    <h3 class="font_12_green mb-3 mt-3 lightgreen text-right">you will receive {price.toFixed(8)} ZARC</h3>




                    <div className='col-12 col-lg-12 col-xl-12'>
                        <h3 class="headfont mb-3  mt-4 label_input_font">Deposit funds to your ZARC Wallet</h3>

                        <DropdownButton title={
                           isSolana === "false" ? "BSC":
                           isSolana === "null" ? "ETH" : "Solana"} id="dropdown-basic-button1" className='w-full'>
                  
                 
                    <Dropdown.Item  onClick={(e)=>{setisSolana("false")}}>to your ZARC wallet address (BEP 20)</Dropdown.Item>
                    <Dropdown.Item  onClick={(e)=>{setisSolana("null")}}>to your ZARC wallet address (ERC 20)</Dropdown.Item>

                    <Dropdown.Item  onClick={(e)=>{setisSolana("true")}}>to your ZARC wallet address(Solana)</Dropdown.Item>
                    </DropdownButton>
                    {/* <select className='form-control'
                        onChange={(e) => setisSolana(e.target.value)}>
                        <option value="false">to your ZARC wallet address (ERC 20)</option>
                        <option value="true">to your ZARC wallet address (Solana)</option>



                    </select> */}
                            </div>

                            <div className='flex-1 align-items-center my-3 selectbank'>
                              <div>
                      <h3 class="headfont mb-3  label_input_font">Deposit Wallet Address</h3>
                      </div>
                      <input type="text" required className='w-full'
                        value= {isSolana === "true" ? DepositSOladdress : Depositaddress}
                        
                         />

                    </div>
                           
                 

                  </div>

                  <div className='mt-4 text-center'>
                    <button className='btn btn-theme btn_big_theme_border'>Upload</button>
                  </div>
                </div>
              </form>




            </div>










          </div>




        </Modal.Body>

      </Modal>
    </>
  )
}

export default TransferedFunds